<template>
    <div>
      <div class="trial-class-hld">
        <div class="trial-box">
            <h2>Start your EduGPT Conversational Bot Trial</h2>
            <div class="search_box" ref="autocompleteContainer">
                    <!-- <textarea cols="12" rows="3" placeholder="Type and click enter to search...." v-model="searchPrompt"
                    @keyup.enter="searchOffers()"></textarea> -->
                    <v-textarea solo label="Select or enter your own text" auto-grow rows="1" row-height="2" v-model="searchPrompt"
                    @keyup.enter="searchOffers()" placeholder="Select or enter your own text"
                    @focus="showAutoComplete = true"></v-textarea>

                    <div class="autocomplete-wrap d-none">
                    <ul>
                        <li><span>What to Do After 12th?</span><span><img src="@/assets/Images/aipostgenerator/arrow-45.svg"/></span></li>
                        <li><span>Graphics Courses After 10th?</span><span><img src="@/assets/Images/aipostgenerator/arrow-45.svg"/></span></li>
                        <li><span>How Do I apply for Scholarship?</span><span><img src="@/assets/Images/aipostgenerator/arrow-45.svg"/></span></li>
                        <li><span>Need Career Counselling Guidelines?</span><span><img src="@/assets/Images/aipostgenerator/arrow-45.svg"/></span></li>
                        <li><span>Are Open University courses available in my country?</span><span><img src="@/assets/Images/aipostgenerator/arrow-45.svg"/></span></li>
                        <li><span>Can I study from home?</span><span><img src="@/assets/Images/aipostgenerator/arrow-45.svg"/></span></li>
                        <li><span>How do the online tutorials work?</span><span><img src="@/assets/Images/aipostgenerator/arrow-45.svg"/></span></li>
                    </ul>
                    </div>
                    <v-btn fab text small v-if="searchPrompt" @click="searchPrompt = ''; processedExternalTechOffers = []"><v-icon color="#6c6c6c"
                        size="26px">mdi-close</v-icon></v-btn>
                    <v-btn fab text small @click="searchOffers()" class="mr-2"><v-icon color="#6c6c6c"
                        size="26px">mdi-magnify</v-icon></v-btn>
            </div>
            <div class="trial-box-switches">
                <div class="attach" @click="dialogVisible = true">
                    <p><img src="@/assets/Images/aipostgenerator/attach-pin.png" style="width: 18px;"/> Attach</p>
                </div>
                <div class="switch_to_pro">
                    <div class="switch-btn">
                        <v-switch flat v-model="switch1" label="Pro"></v-switch>
                        <v-btn text fab x-small class="ml-3"><v-icon size="25px" color="#E2E0E0">mdi-arrow-right-circle</v-icon></v-btn>
                    </div>
                    <div class="switch-pro-tooltip">
                        <h4>Pro Search</h4>
                        <p>Use our Pro version for Enterprise AI Chat & Search</p>
                        <v-btn small dark color="primary" class="learn-tooltip">Learn More</v-btn>
                    </div>
                </div>
            </div>
        </div>
        <div class="custome-right-dialog" :style="{ bottom: dialogVisible ? '0' : '-572px' }">
            <v-btn fab text small class="close-pop" @click="closeDialog"><v-icon color="#1a1d1c" size="25px">mdi-close</v-icon></v-btn>
            <div class="zoe-logo-area">
                <img src="@/assets/Images/aipostgenerator/zoe-svg.svg"/>
            </div>
            <h4>Sign in or Create an Account</h4>
            <p>Sign in or Create an Account Use Pro version AI Chat & Search. Upload files and save your threads</p>
            <input type="email" value="" id="" placeholder="jackson@example.com"/>
            <input type="submit" value="Continue With Email"/>
            <div class="or-divid-hld">
                <p class="or-divid">Or</p>
            </div>
            <p class="terms-text">By continuing, you agree to the updated <u>Terms of Sale</u>, <u>Terms of Service</u> and <u>Privacy Policy</u></p>
            <button class="continue-account"><img src="@/assets/Images/aipostgenerator/google.png" class="mr-2"/> Continue with Google</button>
            <button class="continue-account"><img src="@/assets/Images/aipostgenerator/mcrs.svg" class="mr-2"/> Continue with Microsoft</button>
        </div>
      </div>
      
    </div>
</template>
  
<script>
// JavaScript logic for the component
export default {
data() {
    return {
        switch1: false,
        dialogVisible: false,
    };
},
methods: {
    closeDialog() {
      // Method to close the dialog
      this.dialogVisible = false;
    },
},
mounted() {
    // After 10 seconds, show the dialog
    setTimeout(() => {
      this.dialogVisible = true;
    }, 5000);
},
computed: {
    // Your computed properties go here
},
// Other options like props, lifecycle hooks, etc. can also be defined here
};
</script>

<style scoped>
/* CSS styles specific to this component */
</style>