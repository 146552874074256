<template>
  <v-app>
    <v-app-bar app color="#000" dark class="app-header" v-if="clientName && Islogged">
      <div class="header-new">
        <div class="brand-logo" @click="$router.push('/bots')">
          <!-- <img src="./assets/Images/aipostgenerator/zoe-white.svg" class="d-none d-md-block"/>
          <img src="./assets/Images/aipostgenerator/zoe-white.png" class="d-block d-md-none"/> -->
          <img src="./assets/Images/aipostgenerator/spiderxai-logo_white.png"/>
        </div>
        <!-- <div class="product-create-dropdown head-dropdown">
          <p>Select Bot</p>
          <v-select v-model="selectedBot" :items="myBotList" solo label="Select Bot" item-text="botName" item-value="botId" @change="changeSelectedBot(selectedBot)"
             color="#dde1e8"></v-select>
        </div> -->

        <div class="right-login-details">
          <div class="top-header-right-options">
            <v-btn large color="#34C84A" class="crown-pro mr-3 d-none" @click="openDialog"><img src="./assets/Images/aipostgenerator/Pro-Crown.svg" class="mr-3" height="22px" width="22px"/>Try Pro <img src="./assets/Images/aipostgenerator/white-chev-right.svg" class="ml-3"/></v-btn>
            <!-- <div class="word-loader">
              <p>Remaining words: <b>1840</b></p>
              <v-progress-linear value="50" rounded height="8px" color="#1eb375"></v-progress-linear>
            </div> -->
            <!-- <div class="upgrade-button d-none d-md-block mr-2 mr-md-4 ml-5">
              <v-btn rounded color="#ffedc2" elevation="0" class="upgrade-btn">Upgrade <img src="./assets/Images/aipostgenerator/crown.svg" class="ml-2"/></v-btn>
            </div> -->
            <!-- <div class="upgrade-button-mobile d-block d-md-none mr-2 mr-md-2 ml-5">
              <v-btn rounded fab color="#ffedc2" elevation="0" class="upgrade-btn" small><img src="./assets/Images/aipostgenerator/crown.svg"/></v-btn>
            </div>
            <div class="invitegecko-button mr-2 mr-md-2">
              <v-btn rounded color="#1eb375" elevation="0" class="invite-gecko-btn"><img src="./assets/Images/aipostgenerator/invite.svg" class="mr-2"/> Invite</v-btn>
            </div> -->
          </div>


          <v-menu offset-y z-index="99999">
            <template v-slot:activator="{ on, attrs }">

              <div class="d-inline-flex justify-content-end align-items-center user-corner" v-if="clientName && Islogged"
                style="cursor: pointer" v-bind="attrs" v-on="on">
                <div class="user-image" v-if="clientName && Islogged">
                  <img v-if="clientPhoto && clientPhoto != 'null'" :src="clientPhoto" />
                  <img v-else src="./assets/Images/aipostgenerator/avtar.png" />
                </div>
                <p>{{ clientName }}</p>
                <span><v-icon size="25px" color="#ffffff">mdi-chevron-down</v-icon></span>
              </div>
              <!-- <p v-else style="cursor: pointer" @click="redrictToLogin()">
                Login
              </p> -->
            </template>
            <v-list v-if="clientName && Islogged">
              <v-list-item v-for="(item, index) in items" :key="index" dense style="cursor: pointer">
                <v-icon color="#585963" size="20px" class="mr-2" v-if="item.title == 'Upload File'"
                  @click="redrictToFileUpload()">mdi-tray-arrow-up</v-icon>
                <v-icon color="#585963" size="20px" class="mr-2" v-else-if="item.title == 'View Assets'"
                  @click="redrictToSummary()">mdi-briefcase-eye-outline</v-icon>
                <v-icon color="#585963" size="20px" class="mr-2" v-else-if="item.title == 'View/Create Bot' && adminEmails.includes(clientEmail)"
                  @click="redrictToCreatBot()">mdi-robot-excited-outline </v-icon>
                  <v-icon color="#585963" size="20px" class="mr-2" v-else-if="item.title == 'View/Add Prompt' && adminEmails.includes(clientEmail)"
                  @click="redrictToPrompt()">mdi-eye-outline</v-icon>
                

                <v-icon v-else color="#585963" size="20px" class="mr-2" @click="logoutCall()">mdi-logout</v-icon>
                <v-list-item-title v-if="item.title == 'View Assets'" @click="redrictToSummary()">{{ item.title
                }}</v-list-item-title>
                <v-list-item-title v-if="item.title == 'View/Create Bot' && adminEmails.includes(clientEmail)" @click="redrictToCreatBot()">{{ item.title
                }}</v-list-item-title>
                <v-list-item-title v-else-if="item.title == 'Upload File'" @click="redrictToFileUpload()">{{ item.title
                }}</v-list-item-title>
                 <v-list-item-title v-else-if="item.title == 'View/Add Prompt' && adminEmails.includes(clientEmail)" @click="redrictToPrompt()">{{ item.title
                }}</v-list-item-title>
                <v-list-item-title v-else @click="logoutCall()">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
    <!-- <LoadingPage v-if="showLoadingPage"></LoadingPage> -->

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";
import { auth, signOut } from "@/firebase/firebaseConfig";
// import LoadingPage from "./views/LoadingPage.vue";
import VueBus from "vue-bus";

Vue.use(VueBus);
export default {
  components: {},
  name: "App",
  data() {
    return {
      selectedBot: null,
      myBotList: [],
      clientName: null,
      clientEmail: null,
      clientPhoto: null,
      adminEmails: [
        'sid@webspiders.com', 
        'dipesh.majumder@webspiders.com', 
        'debottam.bhatt@webspiders.com', 
        'suraj.agarwal@webspiders.com', 
        'kaushik.roy@webspiders.com', 
        'sayan2.roy@webspiders.com',
        'niladri.dasgupta@webspiders.com',
        'debayan.ghosh@webspiders.com',
        'sayeksultan.chowdhury@webspiders.com'
      ],
      Islogged: false,
      items: [],
      // productBot: ["Bot for product", " Bot For New Chat Creation", "Bot For Current Affaires", "Bot 4"],
    };
  },
  created() { },
  methods: {
    openDialog() {
      this.$bus.emit('openDialogEvent');
    },
    changeSelectedBot(botId) {
      localStorage.setItem("latestBotId", botId);
      this.getMyBots()
      this.getAssetList();
      this.clearMsg();
      this.getWelcomeMessage();
      alert("Call done");
    },
    async fetchAssetContent(assetId, index) {
      this.assetList[index].viewIconLoading = true;

      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(assetId),
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response===view====", response)
        if (response.data.status == 0) {
          if (screen.width >= 320 && screen.width <= 767) {
            this.viewMobile = false
          }
          this.assetList[index].viewIconLoading = false;

          this.viewAssetContent = response.data.data.asset.cleanText;
          this.fileViewAssetDialog = true;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    
    async getMyBots() {
      //this.myBotList = [];
      try {
        const apiUrl = APIs.getMyBotsAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const response = await axios.get(apiUrl, { headers });
        if (response.data.status == 0) {
          this.myBotList = response.data.bots;
          this.myBotList.sort((a, b) => {
            const dateA = new Date(a.createdOn);
            const dateB = new Date(b.createdOn);

            // Compare in descending order
            return dateB - dateA;
          });
           if(this.myBotList &&  this.myBotList.length == 1){
             localStorage.setItem("latestBotId", this.myBotList[0].botId);
             this.$router.push("/chatwithdata")
           }

        }
      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    getAssetList() {
      this.assetList = [];
      //+ '&size=100' + '&page=' + this.currentPage
      axios
        .get(APIs.getAssetAPI + '?botid=' + parseInt(localStorage.getItem("latestBotId")) + '&size=50' + '&page=' + this.currentPage, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          console.log("response==asset====", response.data);
          if (response.data && response.data.status == 0) {
            response.data.assets.map((element, index) => {
              element.viewIconLoading = false;
              element.editIconLoading = false;
              element.editPropertiesIconLoading = false;
              if (index == 0) {
                element.IsChecked = true;
              }
              this.assetList.push(element)
            });
            const lastItemIndex = this.assetList.length - 1;
            this.assetList.map((element, index) => {
              if (index === lastItemIndex) {
                element.IsChecked = true;
              } else {
                element.IsChecked = false; // Uncheck all other items
              }
            });

            this.assetList.sort((a, b) => a.assetId - b.assetId)
            this.totalPages = response.data.totalPages;
            this.totalCount = response.data.totalCount;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clearMsg() {
      this.messageData = [];
    },
    async getWelcomeMessage() {
      this.botTyping = true;
      try {
        const headers = {
          'accept': 'application/json',
        };
        const data = {
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };
        const response = await axios.post(APIs.getWelcomeMessageAPI, data, { headers });
        console.log("response=====", response);
        if (response.data.status == 0) {
          setTimeout(() => {
            this.botTyping = false;
            this.messageData.push({
              agent: "bot",
              type: "text",
              text: response.data.data.msg,
            });
          }, 1000);
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    getResponse(value) {
      var self = this;
      // Loading
      this.botTyping = true;
      var tempArr = [];
      tempArr.push(localStorage.getItem("latestAssetId"));
      console.log("check===", this.assetList);
      var selectedAssets = [];
      this.assetList.map((element) => {
        if (element.IsChecked) {
          console.log("check ed===", element.assetId);
          selectedAssets.push(parseInt(element.assetId))
        }
      });
      console.log("selectedAssets===", selectedAssets);

      var data = { assetIds: selectedAssets, userQuery: value.text, botId: parseInt(localStorage.getItem("latestBotId")) };

      // console.log(JSON.stringify(data))
      var config = {
        method: "post",
        url: APIs.zoeChat,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        },
        data: JSON.stringify(data),
      };
      axios(config).then(function (response) {

        console.log("chat ====", response.data)
        if (response.data.status == 0) {
          if (response.data.data.vectors) {
            // self.resourceList = response.data.data.vectors;
            self.getVectorChunck(response.data.data.vectors);
          }
          self.messageData.push({
            agent: "bot",
            type: "text",
            text: response.data.data.reply,
            disableInput: false,
          });
          self.botTyping = false;
          //self.showVideoMessage(response.data.data.reply)
        }
      });
    },
    async getVectorChunck(vectors) {
      console.log("vectors=====", vectors);
      if (vectors && vectors.length > 0) {

        const ids = vectors.map(item => item.id);
        const scores = vectors.map(item => item.score);
        console.log("scores=====", scores);
        let roundedNumbers;
        if (scores && scores.length > 0) {
          roundedNumbers = scores.map(number => number.toFixed(5));

        }

        console.log("roundedNumbers=====", roundedNumbers);


        try {
          const apiUrl = APIs.getVectorChunckAPI;
          const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          };
          const data = {
            "vectorIds": ids,
          };

          const response = await axios.post(apiUrl, data, { headers });

          console.log("vector response=======", response.data.data)

          if (response.data.status == 0) {
            this.resourcelist = response.data.data;
          }
          let keyValuePairs = roundedNumbers.map((score, index) => {
            return {
              score: score,
              title: this.resourcelist[index]
            };
          });
          this.updateResList = keyValuePairs;
          console.log("vector resourcelist=======", this.resourcelist)

        } catch (error) {
          console.error('Error fetching asset content:', error);
        }
      }

    },
    getAssetListByProduct(productId) {
      console.log("pl===", productId);
      const apiUrl = APIs.getAssetAPI;
      const email = localStorage.getItem("emailId");
      var productid = productId;

      axios
        .get(apiUrl, {
          params: {
            email: email,
            productid: productid,
          },
        })
        .then((response) => {
          console.log("asset list======", response.data);
          this.userAssetList = [];

          if (response.data.length > 0) {
            if (this.selectedIndex) {
              this.activeIndex = this.selectedIndex;
            } else {
              this.activeIndex = response.data.length - 2;
            }
            // this.activeIndex = response.data.length - 2;
            this.userAssetList = response.data;
            this.userAssetList.sort((a, b) => b.id - a.id);

            this.userAssetList.map((element) => {
              if (localStorage.getItem("multiAssetIds")) {
                if (
                  localStorage.getItem("multiAssetIds").includes(element.id)
                ) {
                  element.IsChecked = true;
                } else {
                  element.IsChecked = false;
                }
              } else {
                element.IsChecked = false;
              }
            });
            // this.activeIndex = 0;
            //this.generatedMessage = response.data[0].summarygenerated;
            this.fileName = response.data[0].filename;
            this.fileSize = response.data[0].fileSize;
            localStorage.setItem("selectedFileName", this.fileName);
            localStorage.setItem("selectedFileType", response.data[0].filetype);
          } else {
            // this.openAssetPopup();
            // this.generatedMessage = null;
          }
        })
        .catch((error) => {
          console.error(error);

          // Handle any errors that occurred during the API call
        });
    },
    async editAssetProperties(assetId, index) {

      this.assetList[index].editPropertiesIconLoading = true;
      try {
        const apiUrl = APIs.getAssetPropertiesAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(assetId),
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("asset edit response===view====", response)

        if (response.data.status == 0) {
          this.tempAssetEditProperty.assetId = assetId;
          this.tempAssetEditProperty.annotation = response.data.data.annotation;
          this.tempAssetEditProperty.name = response.data.data.name;
          this.tempAssetEditProperty.source = response.data.data.source;

        }
        this.assetList[index].editPropertiesIconLoading = false;
        this.filePropertyEditDialog = true;

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async editAssets(assetId, index) {
      this.editIconLoading = true;
      this.assetList[index].editIconLoading = true;

      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(assetId),
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response===view====", response)
        if (response.data.status == 0) {
          if (screen.width >= 320 && screen.width <= 767) {
            this.viewMobile = false
          }
          this.editIconLoading = false;
          this.assetList[index].editIconLoading = false;
          this.assetList[index].editPropertiesIconLoading = false;
          this.editedAssetId = assetId;
          this.editAssetContent = response.data.data.asset.cleanText;
          this.fileEditNameDialog = true;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    scrollEventBG(){
      let el = document.querySelector('.box-right .wrapper-post'); 
      if(el.querySelectorAll('.single-post.tobe-highlighted').length){
        console.log('leng')
        console.log( el.querySelector('.single-post.tobe-highlighted'))
        el.querySelector('.single-post.tobe-highlighted').classList.add('highlight')
      }
      el.addEventListener("scroll", (event) => {
        //console.log('scroll')      
        el.querySelectorAll('.single-post.tobe-highlighted').forEach(element=>{
          element.classList.remove('highlight')
          let curPos = element.offsetTop;
          let screenHeight = el.offsetHeight;
          if(curPos < screenHeight)
            element.classList.add('highlight')
          else if(element.offsetHeight > screenHeight)
            element.classList.add('highlight')        
        })
      })
    },





    redrictToCreatBot() {
      this.$router.push("/bots");
    },
    warmNode() {
      const header1 = {
        "Content-Type": "application/json",
      };

      axios
        .post(APIs.warmNodeAPI, { headers: header1 })
        .then((response1) => {
          console.log("response1=====", response1);

        });
    },
    redrictToFileUpload() {
      this.$router.push("/upload-file");
    },
    redrictToSummary() {
      if (localStorage.getItem("clientName") == "Gecko") {
        this.$router.push("/assets-v2");
      } else {
        this.$router.push("/assets");
      }
    },
    redrictToPrompt(){
      this.$router.push("/prompts");
    },
    redrictToLogin() {
      this.$router.push("/");
    },
    async logoutCall() {
      if (localStorage.getItem("clientName") == "Gecko") {
        this.clientName = null;
        this.Islogged = false;
        localStorage.clear();
        localStorage.removeItem("emailId");
        localStorage.removeItem("clientName");
        localStorage.setItem("isLoggedIn", false);
        this.$router.push("/v2");
      } else {

        await signOut(auth).then(() => {
          console.log('Auth Signed Out');
          this.clientName = null;
          this.Islogged = false;

          localStorage.removeItem("emailId");
          localStorage.removeItem("clientName");
          localStorage.setItem("isLoggedIn", false);

          localStorage.clear();
          this.$router.push("/");

        })

      }
    },
    // async getMyBots() {
    //   //this.myBotList = [];
    //   try {
    //     const apiUrl = APIs.getMyBotsAPI;
    //     let headers = {
    //       'Content-Type': 'application/json',
    //       "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
    //     };
    //     console.log("header=====", headers);

    //     const response = await axios.get(apiUrl, { headers: headers });



    //     if (response.data.status == 0) {
    //       //
    //     }
    //   } catch (error) {
    //     this.logoutCall();

    //     console.error('Error fetching asset content:', error);
    //   }
    // },
  },
  mounted() {
    if (localStorage.getItem("clientName")) {
      this.clientName = localStorage.getItem("clientName");
    }

    if (localStorage.getItem("emailId")) {
      this.clientEmail = localStorage.getItem("emailId");
    }

    if (localStorage.getItem("isLoggedIn")) {
      this.Islogged = localStorage.getItem("isLoggedIn");
    }

    if (localStorage.getItem("clientPhoto")) {
      this.clientPhoto = localStorage.getItem("clientPhoto");
    }
    if (localStorage.getItem("latestBotId")) {
      this.selectedBot = parseInt(localStorage.getItem("latestBotId"));
    }
    this.warmNode();
    this.getMyBots();
    if(this.adminEmails.includes(this.clientEmail)){
      this.items.push({ title: "Upload File" })
      this.items.push({ title: "View/Create Bot" })
      this.items.push({ title: "View/Add Prompt" })
    }
    this.items.push({ title: "Logout" })
  },
};
</script>
<style lang="scss">
@import "./assets/aipostgenerator.css";
</style>
