<template>
    <!-- HTML template for your component -->
    <div class="wrapper-tutorial-sec">
        <div class="tutorial-hld">
            <h3>How Zoe AI Chat & Enterprise search works</h3>
            <div class="zoe-video-wrap">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/R3GfuzLMPkA?si=Qsx9gDiyzSpX8PEc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div class="step-btns">
                <v-btn color="#000000" outlined>Skip</v-btn>
                <v-btn dark color="#4285F4">Next</v-btn>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
    methods: {
      
    },
    computed: {
      
    },
    watch: {
    
    },
    created() {
     
    },
    mounted() {
      
    },
    destroyed() {
     
    }
  };
  </script>
  
  <style scoped>
  /* CSS styles for your component */
  
  </style>