<template>
  <div class="qkb-board-content" ref="boardContent">
    <div class="qkb-board-content__bubbles" ref="boardBubbles">
      <message-bubble
        v-for="(item, index) in mainData"
        :key="index"
        :message="item"
      ></message-bubble>
      <div class="qkb-board-content__bot-typing" v-if="botTyping">
        <slot name="botTyping">
          <message-typing></message-typing>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBubble from '../MessageBubble/Main'
import MessageTyping from '../MessageBubble/Typing'

export default {
  components: {
    MessageBubble,
    MessageTyping
  },

  props: {
    mainData: {
      type: Array,
      required: true
    },

    botTyping: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    mainData: function (newVal, oldVal) { // Note: Vue 2 provides both newVal and oldVal
      this.$nextTick(() => {
        this.updateScroll()
      })
    }
  },

  methods: {
    updateScroll () {
      const contentElm = this.$refs.boardContent
      // In Vue 2, it's common to use `offsetHeight` directly from the ref.
      // However, if `this.$refs.boardBubbles` is not rendering or accessible for some reason, you might need to check its existence or ensure it's rendered.
      if (this.$refs.boardBubbles) {
        const offsetHeight = this.$refs.boardBubbles.offsetHeight
        contentElm.scrollTop = offsetHeight
      }
    }
  }
}
</script>
