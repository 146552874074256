<template>
  <div class="login-new-section">
    <div class="gecko-new-login-banner-left">
      <!-- <h2 style="margin-top: 50px;">
        Zoe AI: Enterprise Generative AI ChatBot & Search
      </h2> -->
      <!-- <h4>
        Forbes Reveals Individuals Encounter 10,000 Marketing Messages Daily.
      </h4> -->
      <!-- <div class="bullets-login-point">
        <ul>
          <li>Effortlessly Transform Any Document into a Chatbot within Minutes!</li>
          <li>Experience greater than 80% Decrease in Support Workload</li>
          <li>
            Witness Enhanced Sales Conversions
          </li>
          <li>Accelerate Customer Support Turnaround Time</li>
          <li>
            Amplify Engagement & Elevate NPS Scores
          </li>
        </ul>
      </div> -->
    </div>
    <div class="gecko-new-login-right">
     
      <v-alert
          v-if="alert"
          v-model="showAlert"
          dismissible 
          z-index="100"
          :type="alert.type"          
        >
         <div v-html="alert.message"></div>
        </v-alert>
        <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="#400000"
      ></v-progress-linear>

      <v-dialog
        v-model="dialog"
        width="500"   
        :eager="true"
        class="allset-modal"     
      >
        <v-card>          
          <canvas id="myCanvas"></canvas>
          <v-card-text>   
          <div class="text-center text-wrapper-area">
            <h3>ALL SET</h3>
            <h4>Signup successful.</h4>
            <p>A verification link has been sent to your Email address.</p>
            <p>Please verify to Login and Upload your first document to get started.</p>
          </div>
          </v-card-text>
          <v-card-actions>
          <div class="text-center" style="width: 100%;">
            <v-btn
              color="#7e0d10"
              class="proceed-login"            
              @click="dialog=false;showRegister=false;showDetailsSection1=false;showRegisterNextSection=false;showResetPassword=false;"            
            >
              Proceed
            </v-btn>
           </div>
        </v-card-actions>
        </v-card>
      </v-dialog>
      
      <div class="bottom-right-icon pt-2" style="width: 26%;">
        <!-- <img
          src="../assets/Images/aipostgenerator/zoe-black.svg"
          
        /> -->
        <img
          src="../assets/Images/aipostgenerator/spiderxai-logo.png" style="width:100%;"
          
        />
      </div>
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        v-if="!showRegister && !showDetailsSection1 && !showRegisterNextSection && !showResetPassword"
      >
        <!-- <h4>Try Zoebot AI for Free</h4> -->

        <p>
          Don't have an account?
          <span @click="showRegisterDiv1()">Sign Up Now</span>
        </p>
        <input
          type="text"  
          key="lemail"
          v-model="user.email"          
          placeholder="Email"
          name="lemail"
          v-validate="'required|email'"
          data-vv-as="Email"
        />
        <span class="text-danger text-sm" v-show="errors.has('lemail')">{{ errors.first('lemail') }}</span>
        <input 
        type="password" 
        key="lpassword"
        placeholder="Password" 
        name="lpassword"
        v-model="user.password" 
        v-validate="'required'" 
        data-vv-as="Password"
        />
        <span class="text-danger text-sm" v-show="errors.has('lpassword')">{{ errors.first('lpassword') }}</span>
        <input type="submit" :value="loginButton" class="active" @click="emailSignin()" />

        <a href="javascript:void(0);" class="forgot-pwd-page" @click="showRegister=false;showDetailsSection1=false;showRegisterNextSection=false;showResetPassword=true;"
          >Forgot Password</a
        >
        <div class="my-5 or-statement">
          <p>Or</p>
        </div>
        <div class="login-with-different-platform">
          <a href="javascript:void(0);" class="login-with-google" @click="firebaseGoogleSignin()"
            >Continue with Google <span class="login-arrow"><v-icon color="#fff" size="22px">mdi-arrow-right</v-icon></span></a
          >
          <a href="javascript:void(0);" class="login-with-apple" @click="firebaseMicrosoftSignin()"
            >Continue with Microsoft</a
          >
        </div>
      </div>

      <div
        class="d-flex flex-column justify-content-center align-items-center"
        v-if="showRegister && !showDetailsSection1 && !showRegisterNextSection"
      >
        <h4>Create an Account</h4>
        <p>
          Already have an account?
          <span @click="showRegister=false;showDetailsSection1=false;showRegisterNextSection=false;">Log in</span>
        </p>
        
        <input
          type="text"
          key="semail"
          v-model="user.email"
          placeholder="Enter Email Address"  
          name="semail"
          data-vv-as="Email"
          v-validate="'required|email'"
        />
        <span class="text-danger text-sm" v-show="errors.has('semail')">{{ errors.first('semail') }}</span>
        <input
          type="submit"
          :value="nextButton"
          key="1"          
          class="active"
          @click="userSignupStep1()"
        />

        <div class="slide-dots">
          <span class="active"></span>
          <span></span>
          <span></span>
        </div>
        <div class="my-5 or-statement">
          <p>Or</p>
        </div>
        <div class="login-with-different-platform">
          <a href="javascript:void(0);" class="login-with-google" @click="firebaseGoogleSignin()"
            >Log in with Google</a
          >
          <a href="javascript:void(0);" class="login-with-apple" @click="firebaseMicrosoftSignin()"
            >Log in with Microsoft</a
          >
        </div>
      </div>

      <div
        class="d-flex flex-column justify-content-center align-items-center"
        v-if="!showRegister && !showDetailsSection1 && showRegisterNextSection"
      >
        <h4>Create an Account</h4>
        <p>
          Already have an account?
          <span @click="showRegister=false;showDetailsSection1=false;showRegisterNextSection=false;">Log in</span>
        </p>        
        <input
          type="password"
          key="spassword"
          v-model="user.password"          
          placeholder="Password (at least 8 characters)"
          name="spassword"
          @input="checkPasswordStrength()"
          class="mt-5"
          v-validate="'required|min:8'"
          data-vv-as="Password"
          ref="password"
        />
        <span class="text-danger text-sm" v-show="errors.has('spassword')">{{ errors.first('spassword') }}</span>
        <input
          type="password"
          v-model="user.cpassword"
          name="cpassword"
          placeholder="Confirm Password"
          v-validate="'required|confirmed:password'"
          data-vv-as="Confirm Password"
        />
        <span class="text-danger text-sm" v-show="errors.has('cpassword')">{{ errors.first('cpassword') }}</span>
        <div class="password-strong-check">
          <div class="check" :class="{'green':passwordStrengthPoint >= 1, 'accent-1':passwordStrengthPoint >= 1}"></div>
          <div class="check" :class="{'green':passwordStrengthPoint >= 2, 'accent-2':passwordStrengthPoint >= 2}"></div>
          <div class="check" :class="{'green':passwordStrengthPoint >= 3, 'accent-3':passwordStrengthPoint >= 3}"></div>
          <div class="check" :class="{'green':passwordStrengthPoint >= 4, 'accent-4':passwordStrengthPoint >= 4}"></div>
        </div>
        <input
          type="submit"
          :value="nextButton"
          class="active"
          @click="userSignupStep2()"
        />
        <div class="slide-dots">
          <span></span>
          <span class="active"></span>
          <span></span>
        </div>
        <div class="my-5 my-md-3 or-statement">
          <p>Or</p>
        </div>
        <div class="login-with-different-platform">
          <a href="javascript:void(0);" class="login-with-google" @click="firebaseGoogleSignin()"
            >Log in with Google</a
          >
          <a href="javascript:void(0);" class="login-with-apple" @click="firebaseMicrosoftSignin()"
            >Log in with Microsoft</a
          >
        </div>
      </div>
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        v-if="!showRegister && showDetailsSection1 && !showRegisterNextSection"
      >
        <h4>Create an Account</h4>
        <p>Already have an account? <span @click="showRegister=false;showDetailsSection1=false;showRegisterNextSection=false;">Log in</span></p>        
        <input 
        type="text" 
        key="sfirst_name"
        name="first_name"  
        placeholder="First Name" 
        v-model="user.first_name" 
        v-validate="'required'"
        data-vv-as="First Name"
        />
        <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
        <input
          type="text"
          v-model="user.last_name"
          placeholder="Last Name"
          class="mt-4"
        />
        <input
          type="text"
          v-model="user.organization"          
          placeholder="Organization (Optional)"
          class="mt-4"
        />
        <input type="submit" :value="registerButton" @click="userSignupStep3()" class="active" />
        <div class="slide-dots">
          <span></span>
          <span></span>
          <span class="active"></span>
        </div>

        <div class="my-5 or-statement">
          <p>Or</p>
        </div>
        <div class="login-with-different-platform">
          <a href="javascript:void(0);" class="login-with-google" @click="firebaseGoogleSignin()"
            >Log in with Google</a
          >
          <a href="javascript:void(0);" class="login-with-apple" @click="firebaseMicrosoftSignin()"
            >Log in with Microsoft</a
          >
        </div>
      </div>

      <div
        class="d-flex flex-column justify-content-center align-items-center"
        v-if="showResetPassword"
      >
        <h4>Reset Password</h4>
        <p>
          Already have an account?
          <span @click="showRegister=false;showDetailsSection1=false;showRegisterNextSection=false;showResetPassword=false;">Log in</span>
        </p>
        <input
          type="text"
          key="femail"
          v-model="user.email"
          placeholder="Enter Email Address"
          name="femail"
          data-vv-as="Email"
          v-validate="'required|email'"
        />
        <span class="text-danger text-sm" v-show="errors.has('femail')">{{ errors.first('femail') }}</span>
        <input
          type="submit"
          value="Send Mail"
          key="1"          
          class="active"
          @click="resetPassword()"
        />
        <div class="my-5 or-statement">
          <p>Or</p>
        </div>
        <div class="login-with-different-platform">
          <a href="javascript:void(0);" class="login-with-google" @click="firebaseGoogleSignin()"
            >Log in with Google</a
          >
          <a href="javascript:void(0);" class="login-with-apple" @click="firebaseMicrosoftSignin()"
            >Log in with Microsoft</a
          >
        </div>
      </div>

    </div>
  </div>
</template>
<style>
  .v-alert{z-index:1}
  .v-alert ul li{text-align: left;}
  #myCanvas{width: 100%; position: absolute; height: 100%;}
/*.v-alert .v-alert__content{text-transform: capitalize;}*/
@media only screen and (min-width:1401px) and (max-width: 1500px){
  .bullets-login-point {
    margin-top: 20px;
}
}
</style>
<script>

import * as con from "@/firebase/firebaseConfig";
import APIs from "@/components/APIInit";
import axios from "axios";
//let con = {Environment: "UAT"}
export default {
  components: {
  },
  props: {},
  directives: {
  },
  data() {
    return {
      showRegister: false,
      showDetailsSection1: false,
      showRegisterNextSection: false,
      showResetPassword: false,
      //clientId: googleAuthClinetId,
      passwordStrengthPoint: 0,
      user: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        organization: null
      },
      nextButton: "Next",
      loginButton: "Log in",
      registerButton: "Create Account",
      //submitDisabled: true,
      alert: null,
      showAlert: false,
      loading: false,
      dialog: false,
      valid: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    };
  },
  watch:{
    "alert"(value){
      if(value) this.showAlert = true
      else this.showAlert = false
    },
    "showAlert"(value){
      if(value) setTimeout(()=>{this.showAlert=false},3000)
    },
    "user.password"(value){
      //console.log(value)
      //this.checkPasswordStrength(value)
    },
    /*"user.email"(email){
      let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if(regex.test(email)){
        this.submitDisabled = false
      }else{
        this.submitDisabled = true
      }
    },
    "user.first_name"(value){
        this.submitDisabled = !String(value).trim() ? true : false      
    }*/
  },
  methods: {
    showRegisterDiv1() {
      this.showRegister = true;
    },
    async resetPassword(){
      this.alert = null;
      if(this.errors.any()) return false; 
      if(!this.user.email){
        this.alert = {type:"error",message:"Please enter Email"}; return false;
      }
      else if(!this.checkValidEmail(this.user.email)){
        this.alert = {type:"error",message:"Please enter a valid Email"}; return false;
      }
      this.loading = true
      await con.sendPasswordResetEmail(con.auth, this.user.email)
      .then((response) => {
        //console.log(response)
        this.alert = {type:"success",message:"Please check your Email to reset Password."}
        this.showRegister=false;this.showDetailsSection1=false;this.showRegisterNextSection=false;this.showResetPassword=false;
      })
      .catch((error) => {
        //const errorCode = this.sanitizeError(error.code);
        //const errorMessage = error.message;
        this.sanitizeError(error.code);
      });
      this.loading = false
    },
    validateUserAuthToken(accessToken){
      return new Promise((resolve,reject)=>{
        let headers = {
          "content-type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        }
        axios.post(APIs.getAuthToken, {}, {headers: headers})
          .then((response) => {
            // Handle the response data
            resolve(response.data)
          }).catch((error) => {
            // Handle the error
            reject(error)
          });
      })
    },
    firebaseGoogleSignin(){
      this.alert = null;
      this.loading = true
      con.signInWithPopup(con.auth, con.googleAuthProvider)
      .then(async(result) => {
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        //let additionalInfo = await con.getAdditionalUserInfo(result);
        //console.log(user)
        if(user.emailVerified){ 
          await this.validateUserAuthToken(user.accessToken).then(response=>{
            if(response && response.status == 0 && response.zoe_token){
              this.alert = {type:"success",message:"You are successfully logged in"}
              localStorage.setItem("emailId", user.email);
              localStorage.setItem("clientId", user.uid);
              localStorage.setItem("clientName", user.displayName);
              localStorage.setItem("clientPhoto", user.photoURL);
              localStorage.setItem("accessToken", response.zoe_token);
              localStorage.setItem("isLoggedIn", true);
              setTimeout( () =>{                           
                this.$router.push({
                  path: "/bots",
                  query: { param1: "r" },
                });
              }, 800)
            }else{
              this.alert = {type:"error",message:"Something went wrong!"}
            }
          }).catch(error=>{
            console.error(error);
            this.alert = {type:"error",message:"Something went wrong!"}
          })
        }else{
          this.alert = {type:"error",message:"Verification is pending for this Email!"}
        }
        this.loading = false        
      }).catch((error) => {
        // Handle Errors here.
        this.loading = false
        //const errorCode = this.sanitizeError(error.code);
        //const errorMessage = error.message;        
        //console.log(error)   
        this.sanitizeError(error.code);   
      });
    },
    firebaseMicrosoftSignin(){
      this.alert = null;
      this.loading = true
      const url = window.URL || window.webkitURL;
      con.signInWithPopup(con.auth, con.microsoftAuthProvider)
      .then(async(result) => {
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        /*let additionalInfo = await con.getAdditionalUserInfo(result);
        console.log('additionalInfo',additionalInfo)*/

        const credential = con.OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        /*console.log('user',user)
        console.log('credential',credential)
        console.log('accessToken',accessToken)
        console.log('idToken',idToken)*/

        let headers = {
          "content-type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        }
        await axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {headers: headers})
          .then((response) => {
            //console.log('photo',response)
            var img = document.createElement('img');
            //img.src =  url.createObjectURL(response.data);

            /*var binaryData = [];
            binaryData.push(response.data); //My blob
            img.src = 'data:image/jpeg;base64,' + response.data;
            document.body.appendChild(img);*/
            //user.photoURL = blobUrl;
          }).catch(error=>{ console.log('photo',error) })

        if(user.emailVerified){
          await this.validateUserAuthToken(user.accessToken).then(response=>{
          if(response && response.status == 0 && response.zoe_token){
            this.alert = {type:"success",message:"You are successfully logged in"}
            setTimeout( () =>{ 
              localStorage.setItem("emailId", user.email);
              localStorage.setItem("clientId", user.uid);
              localStorage.setItem("clientName", user.displayName);
              localStorage.setItem("clientPhoto", user.photoURL);
              localStorage.setItem("accessToken", response.zoe_token);
              localStorage.setItem("isLoggedIn", true);            
              this.$router.push({
                path: "/bots",
                query: { param1: "r" },
              });
            }, 800)
          }else{
            this.alert = {type:"error",message:"Something went wrong!"}
          }
          }).catch(error=>{
            console.error(error);
            this.alert = {type:"error",message:"Something went wrong!"}
          })
        }else{
          this.alert = {type:"error",message:"Verification is pending for this Email!"}
        }
        this.loading = false        
      }).catch((error) => {
        // Handle Errors here.
        this.loading = false
        //const errorCode = this.sanitizeError(error.code);
        //const errorMessage = error.message;
        //console.log(errorMessage)   
        this.sanitizeError(error.code);    
      });
    },
    async userSignupStep1(){
      this.alert = null;
      this.loading = false
      if(this.errors.any()) return false; 
      if(!this.user.email){
        this.alert = {type:"error",message:"Please enter Email"}; return false;
      }
      else if(!this.checkValidEmail(this.user.email)){
        this.alert = {type:"error",message:"Please enter a valid Email"}; return false;
      }
      this.nextButton = "Loading..."
      this.loading = true
      await con.fetchSignInMethodsForEmail(con.auth, this.user.email).then(async(response)=>{
        //console.log(response)
        if(!response.length){
          this.showRegister = false;
          this.showRegisterNextSection = true;
          this.showDetailsSection1 = false          
        }else{
           this.alert = {type:"error",message:"An account is already registered with your email address. Please log in"}
        }
      }).catch((error)=>{
        //const errorCode = this.sanitizeError(error.code);
        //const errorMessage = error.message;       
        this.sanitizeError(error.code); 
      })
      this.nextButton = "Next"
      this.loading = false
    },
    async userSignupStep2(){
      this.alert = null;
      this.loading = false
      if(this.errors.any()) return false; 
      if(!this.user.password || !String(this.user.password).trim()){
        this.alert = {type:"error",message:"Please enter Password"}; return false;
      }
      else if(this.user.password.length < 8){
        this.alert = {type:"error",message:"Password must have at least 8 characters"}; return false;
      }
      else if(this.user.password != this.user.cpassword){
        this.alert = {type:"error",message:"New password and confirm password doesn't match! Retry"}; return false;
      }
      this.showRegisterNextSection = false;
      this.showDetailsSection1 = true;
      this.showRegister = false
    },
    async userSignupStep3(){
      this.alert = null;
      this.loading = false
      if(this.errors.any()) return false; 
      if(!this.user.first_name ){
        this.alert = {type:"error",message:"Please enter First Name"}; return false;
      }
      this.registerButton = "Loading..."
      this.loading = true
      await con.createUserWithEmailAndPassword(con.auth, this.user.email, this.user.password)
      .then(async(userCredential) => {
        this.registerButton = "Create Account"
        // Signed in 
        const user = userCredential.user;
        //console.log(user)
        await con.updateProfile(con.auth.currentUser, {
          displayName: this.user.first_name ? this.user.first_name + (this.user.last_name ? ' '+this.user.last_name : '') : this.user.email, 
          photoURL: null
        }).catch((error) => {
          //this.alert = {type:"error",message:""}
        });
        await con.sendEmailVerification(user)
        .then((response) => {
          //console.log(response)
          this.alert = {type:"success",message:"A verification link has been sent to your Email address. Please verify to login."}
          this.dialog = true;  
          let c = document.getElementById("myCanvas");
          let ctx = c.getContext("2d");                
          this.$confetti.start({
            canvasId: 'myCanvas',
            defaultDropRate:2,
            windSpeedMax:1,
            particlesPerFrame: 0.25,
            dropRate: 2,
            particles: [
              {
                type: 'heart',
              },
              {
                type: 'circle',
              },
              {
                type: 'rect',
              },
            ],
          });
          setTimeout( () =>{   
            this.$confetti.stop();
            this.dialog = false            
          }, 4000)
        }).catch(error=>{ 
          //console.log(error)
          this.alert = {type:"error",message:"Something went wrong!"} 
        })          
        this.showRegister=false;this.showDetailsSection1=false;this.showRegisterNextSection=false;this.showResetPassword=false;
        this.loading = false        
      })
      .catch((error) => {
        //const errorCode = this.sanitizeError(error.code);
        //const errorMessage = error.message;
        this.sanitizeError(error.code);
        this.registerButton = "Create Account"
        this.loading = false
      });
    },
    async emailSignin(){
      this.alert = null;
      this.loading = false  
      if(this.errors.any()) return false;     
      if(!this.user.email){
        this.alert = {type:"error",message:"Please enter Email"}; return false;
      }
      else if(!this.user.password){
        this.alert = {type:"error",message:"Please enter Password"}; return false;
      }
      else if(!this.checkValidEmail(this.user.email)){
        this.alert = {type:"error",message:"Please enter a valid Email"}; return false;
      }
      this.loginButton = "Loading..."
      this.loading = true
      await con.signInWithEmailAndPassword(con.auth, this.user.email, this.user.password)
      .then(async(userCredential) => {
        // Signed in 
        //this.loginButton = "Log in"
        const user = userCredential.user;
        if(user.emailVerified){  
          await this.validateUserAuthToken(user.accessToken).then(response=>{
            if(response && response.status == 0 && response.zoe_token){
              this.alert = {type:"success",message:"You are successfully logged in"}
              setTimeout( () =>{ 
                localStorage.setItem("emailId", user.email);
                localStorage.setItem("clientId", user.uid);
                localStorage.setItem("clientName", user.displayName);
                localStorage.setItem("clientPhoto", user.photoURL);
                localStorage.setItem("accessToken", response.zoe_token);
                localStorage.setItem("isLoggedIn", true);            
                this.$router.push({
                  path: "/bots",
                  query: { param1: "r" },
                });
              }, 800)
            }else{
              this.alert = {type:"error",message:"Something went wrong!"}
            }
          }).catch(error=>{
            //console.error(error);
            this.alert = {type:"error",message:"Something went wrong!"}
          })
        }else{
          this.alert = {type:"error",message:"Verification is pending for this Email!"}
        }               
      })
      .catch((error) => {
        //console.log(error.code)
        //console.log(error.message)
        //const errorCode = this.sanitizeError(error.code);
        //const errorMessage = error.message;
        this.sanitizeError(error.code);
      });
      this.loginButton = "Log in"
      this.loading = false 
    },
    sanitizeError(text){
      this.alert = null;
      //console.log(text)
      if(text && text.includes('popup-closed-by-user')){  
        this.alert = null;     
      }
      else if(text && text.includes('cancelled-popup-request')){       
        this.alert = null; 
      }
      else if(text && text.includes('user-not-found')){       
        this.alert = {type:"error",message:"This email is not recognized, please try with a different email or sign-up new"}
      }
      else if(text && text.includes('wrong-password')){       
        this.alert = {type:"error",message:"Wrong password! Please retry"}
      }
      else if(text && text.includes('wrong-password')){       
        this.alert = {type:"error",message:"Wrong password! Please retry"}
      }
      else if(text && text.includes('user-disabled')){
        this.alert = {type:"error",message:"This account is disabled!"}
      }
      else{
        let message = String(text).replace("auth/", "").replaceAll("-", " ");
        this.alert = {type:"error",message:message}
      }
    },
    checkValidEmail(email){
      let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return regex.test(email);
    },
    checkSpecialChars(text){
      let regex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      return regex.test(text);
    },
    checkPasswordStrength() {
      // Initialize variables
      let strength = 0;
      let initial = "Password should be";
      let tips = "<ul>";
      this.alert = null;
      this.passwordStrengthPoint = 0;
      let password = this.user.password;

      // Check password length
      if (password.length < 8) {
        tips += "<li>8+ characters.</li>";
      } else {
        strength += 1;
      }

      // Check for mixed case
      if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
        strength += 1; 
      } else {
        tips += "<li>Uppercase & Lowercase.</li>";
      }

      // Check for numbers
      if (password.match(/\d/)) {
        strength += 1;
      } else {
        tips += "<li>Numbers.</li>";
      }

      // Check for special characters
      if (String(password).trim().match(/[^a-zA-Z\d]/)) {
        strength += 1;
      } else {
        tips += "<li>Special characters.</li>";
      }

      this.passwordStrengthPoint = strength
      if(tips) this.alert = {type:"warning",message: initial+tips+'</ul>'};
      // Return results
      /*if (strength < 2) {
        return "Easy to guess. " + tips;
      } else if (strength === 2) {
        return "Medium difficulty. " + tips;
      } else if (strength === 3) {
        return "Difficult. " + tips;
      } else {
        return "Extremely difficult. " + tips;
      }*/
    }
  },
  created() {
  },
  mounted() {
  },
};
</script>