<template>
  <div class="qkb-board-header">
    <!-- Vue 2 does not use v-slot for slots in templates. Instead, for named slots, content is distributed based on the slot attribute -->
    <slot name="header">
      <div class="qkb-board-header__title">{{ botTitle }}</div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'QkbBoardHeader',
  props: {
    botTitle: {
      type: String,
      default: 'Chatbot'
    }
  }
}
</script>
