<template>
    <div>
        <v-container class="mt-5" >
            <v-card class="p-4 rounded-xl">
                <table class="prompt-table">

                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>Template Text</th>
                            <th>Category</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in promptItems" :key="item.PK_intPromptID">
                            <td><!--<input type="checkbox" >-->
                                <v-checkbox v-model="item.blnIsActive" color="primary" label="" class="check-box-row"></v-checkbox>
                            </td>
                            <td>
                                <span v-if="!item.editing">{{ item.strPromptTemplate }}</span>
                                <textarea v-model="item.strPromptTemplate" id="annotation" class="form-control"
                                    style="width:100%;height:250px" v-if="item.editing"></textarea>
                            </td>
                            <td>
                                <span v-if="!item.editing">{{ item.strPromptCategory }}</span>
                                <!-- <textarea v-model="item.strPromptCategory" id="annotation" class="form-control"
                                    style="width:300px;height:250px" ></textarea> -->
                                <div v-if="item.editing">

                                    <v-select :items="categories" label="Category" v-model="item.strPromptCategory"></v-select>
                                    <!-- <select name="cars" id="cars">
                                        <option v-for="item in categories" :key="item" :value="item.strPromptCategory">
                                            {{ item }}
                                        </option>
                                    </select> -->
                                </div>

                            </td>
                            <td class="text-center">
                                
                                <v-btn v-if="!item.editing" @click="toggleEdit(index)" fab x-small color="primary"><v-icon size="18px" color="#ffffff">mdi-square-edit-outline</v-icon></v-btn>
                                
                                <v-btn v-else @click="saveEdit(item, index)" fab x-small color="primary"><v-icon size="18px" color="#ffffff">mdi-content-save-outline</v-icon></v-btn>
                                <!-- <span  class="edit-button" >Save</span> -->
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- <button >Add Template</button> -->
                <div class="template-add-button text-center">
                    <v-btn @click="backToSearch()" class="mt-12 mr-3 back-button" color="#c6c6c6" x-large height="55px">
                        <v-icon class="mr-2" color="#000" size="22px">mdi-arrow-left</v-icon>Back
                    </v-btn>
                    <v-btn @click="addTemplate" class="mt-12" color="primary" x-large height="55px">
                        Add Template <v-icon class="ml-2" color="#ffffff" size="22px">mdi-plus</v-icon>
                    </v-btn>
                </div>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";
export default {
    data() {
        return {
            selectedItem: null,
            promptValue: '',
            isAdding: false,
            isEditing: false,
            promptItems: [],
            categories: ['Enterprise Search',
                'Enterprise GenAI',
                'Google References',
                'Patent Database',
                'Scientific Journals',
                'Industry Publications',
                'Overall Search',
                'Internal',
                'External',
                'Master'
                ]
        };
    },
    mounted() {
        this.getPrompts();

    },
    methods: {
        backToSearch(){
            this.$router.push("/searchview");
        },
        async getPrompts() {
            try {
                const apiUrl = APIs.getPrompts;
                const headers = {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                };
                const response = await axios.get(apiUrl, { headers });
                console.log("datta====", response.data);
                if (response.data && response.data.length > 0) {
                    this.promptItems = [];
                    //this.categories = []
                    response.data.map((prompt) => {
                        // Pushing prompt items as before
                        this.promptItems.push({
                            PK_intPromptID: prompt.PK_intPromptID,
                            blnIsActive: prompt.blnIsActive,
                            strPromptTemplate: prompt.strPromptTemplate,
                            strPromptCategory: prompt.strPromptCategory,
                            editing: false
                        });

                        // Check if the category does not exist in the categories array before pushing
                        // if (!this.categories.includes(prompt.strPromptCategory)) {
                        //     this.categories.push(prompt.strPromptCategory);
                        // }
                    });

                    // this.myBotList = response.data.bots;
                    // this.myBotList.sort((a, b) => {
                    //     const dateA = new Date(a.createdOn);
                    //     const dateB = new Date(b.createdOn);

                    //     // Compare in descending order
                    //     return dateB - dateA;
                    // });
                }
            } catch (error) {
                console.error('Error fetching asset content:', error);
            }
        },
        toggleEdit(index) {
            this.promptItems[index].editing = true;
        },
        async saveEdit(item, index) {
            try {
                const headers = {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                };
                var data = {
                    prompt_template: item.strPromptTemplate,
                    is_active: item.blnIsActive,
                    prompt_id: item.PK_intPromptID ? item.PK_intPromptID : '',
                    prompt_category: item.strPromptCategory
                }

                const response = await axios.post(APIs.updatePrompts, data, { headers });
                console.log("response= offers====", response);

                if (response.data.status == 0) {
                    this.promptItems[index].editing = false;
                }

            } catch (error) {
                console.error('Error fetching asset content:', error);
            }
        },
        addTemplate() {
            this.promptItems.push({ strPromptTemplate: '', blnIsActive: false, editing: false });
        },
        handleChange(item) {
            if (this.selectedItem === item) return;
            this.selectedItem = item;
        },
        showAddPrompt() {
            this.promptValue = '';
            this.isAdding = true;
            this.isEditing = false;
            this.selectedItem = null;
            const newValue = prompt('Enter new item:');
            if (newValue !== null) {
                this.items.push(newValue);
            }
        },
        showEditPrompt() {
            if (!this.selectedItem) {
                alert('Please select an item to edit.');
                return;
            }
            this.isAdding = false;
            this.isEditing = true;
            const newValue = prompt('Enter new value for ' + this.selectedItem + ':', this.selectedItem);
            if (newValue !== null) {
                const index = this.items.indexOf(this.selectedItem);
                if (index !== -1) {
                    this.items[index] = newValue;
                    this.selectedItem = newValue;
                }
            }
        },
        saveChanges() {
            alert('Changes saved.');
        }
    }
};
</script>
<style>
table.prompt-table {
    width: 100%;
    border-collapse: collapse;
}

.prompt-table th, .prompt-table td {
    border: 1px solid #000;
    padding: 15px;
    text-align: left;
    vertical-align: middle;
}
.prompt-table td:first-child{text-align: center;}
.prompt-table th {
    background-color: #000;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}
.prompt-table td{
    color: #333;
    font-family: "Poppins", sans-serif;
    font-weight: 400;  
}
.prompt-table tr:hover {
    background-color: #f2f2f2;
}

/* .edit-input {
      display: none;
    } */


.edit-button {
    cursor: pointer;
}
.check-box-row .v-input__slot {
    margin: 0 !important;
}
.v-input.check-box-row {
    margin: 0 0 0 10px !important;
    padding: 0 !important;
}
.check-box-row .v-input--selection-controls__input {
    margin-right: 0 !important;
}
.check-box-row .v-messages{display: none;}
.template-add-button button span.v-btn__content {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.1rem;
    text-transform: capitalize;
}
.template-add-button button {
    border-radius: 8px;
}
</style>