<template>
  <div class="container">
    <div class="scan_upload upload-doc-area" v-if="!randomText">
      <!-- <div class="create-bot-pre-step mb-12">
        <transition name="fade">
          <div class="create-bot-drop text-right mb-5">
            <v-btn @click="createBoxFire()" color="#400000" class="common-button">Create Bot <v-icon color="#fff" size="22px" class="ml-2">mdi-chevron-down</v-icon></v-btn>
            <div v-if="createBotBox" class="create-bot-input-area">
              <input type="text" value="" id="" placeholder="Write your text here..."/>
              <v-btn color="#400000" class="common-button">Create</v-btn>
            </div>
          </div>
        </transition>
        <v-row>
          <v-col v-for="i in 8" :key="i" lg="4" md="4" cols="12">
            <v-card>
              <div class="zoe-bot-logo-middle">
                <img src="../assets/Images/aipostgenerator/zoe-z-logo.png"/>
              </div>
              <h5>{{ 'Zoe Bot Name ' + i }}</h5>
              <div class="create-btn-area">
                <v-btn dark color="#400000" class="common-button">Create</v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div> -->
      <v-tabs v-model="tabs" centered next-icon="mdi-arrow-right" prev-icon="mdi-arrow-left" show-arrows>
        <!-- <v-tab class="mr-2"> <v-icon color="#333" size="22px" class="mr-2">mdi-link</v-icon> Enter URL </v-tab> -->
        <!-- <v-tab class="mr-2" disabled> <v-icon color="#333" size="22px" class="mr-2">mdi-youtube</v-icon> Youtube Link
        </v-tab> -->
        <v-tab class="mr-2"> <v-icon color="#333" size="22px" class="mr-2">mdi-file-document-outline</v-icon> Upload
          Document </v-tab>
        <v-tab> <v-icon color="#333" size="22px" class="mr-2">mdi-file-document-multiple-outline</v-icon> Paste Your
          Content</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <!-- <v-tab-item>
          <v-card flat>
            <div class="scan_url-hld">

              <label>Ingest Content from Website URL <span v-if="showErrorMsg && !inputText" style="color:red">*(Please
                  enter a Website URL)</span></label>
              <input type="url" name="" id="inputValue" value="" placeholder="Enter URL Here" v-model="inputText"
                @keypress.enter="dataUrlGenerate()" v-bind:class="{ 'invalid-url': showErrorMsg && !inputText }" />
              <div class="butn-tooltip-group d-flex justify-center align-items-center">
                <v-btn color="#1eb375" class="common-submit-blue-btn" @click="dataUrlGenerate()">Proceed</v-btn>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="d-flex justify-content-start align-items-center"
                      style="visibility: hidden"><v-icon size="25px" color="#333">mdi-refresh</v-icon></span>
                  </template>
                  <span>Look's like our AI is busy<br />with other request,
                    please<br />click here to retry</span>
                </v-tooltip>
              </div>
            </div>
          </v-card>
        </v-tab-item> -->
        <!-- <v-tab-item>
          <v-card flat>
            <div class="scan_url-hld">
              <label>Ingest Youtube Video Transcript</label>
              <input type="url" name="" id="" value="" placeholder="Enter URL Here" class="youtube-placeholder" />
              <div class="butn-tooltip-group d-flex justify-center align-items-center">
                <v-btn disabled color="#1eb375" class="common-submit-blue-btn"
                  @click="sendForSummaryGeneration('url')">Proceed</v-btn>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="d-flex justify-content-start align-items-center"
                      style="visibility: hidden"><v-icon size="25px" color="#333">mdi-refresh</v-icon></span>
                  </template>
                  <span>Look's like our AI is busy<br />with other request,
                    please<br />click here to retry</span>
                </v-tooltip>
              </div>
            </div>
          </v-card>
        </v-tab-item> -->
        <v-tab-item>
          <v-card flat>
            <div class="create-new-project">
              <div class="new-project-card-hld file-card-hld">
                <h3>Upload Document to Ingest</h3>

                <div class="open-project-tabs" v-if="!toggleDesignUploads">
                  <div class="open-project-single-tabs" @click="
                    toggleDesignUploads = true;
                  tabs = 1;
                  ">
                    <img src="../assets/Images/aipostgenerator/upload_a_doc.png" />
                    <p>Upload a document<br /></p>
                  </div>
                </div>
                <div class="scan_upload pt-0">
                  <div class="upload_document_area">
                    <div class="upload_document_space" @dragover="handleDragOver" @dragleave="handleDragLeave"
                      @drop="handleDrop" @click="openFileExplorer">
                      <img src="../assets/Images/aipostgenerator/upload_document.png" />

                      <h3 v-if="!isDragging">
                        <a href="javascript:void(0)">Browse</a> file to upload
                      </h3>
                      <p v-else>Drop files here</p>

                      <h4>
                        Accepted file format(s): .txt,.pdf,.doc,.docx <br />
                        Max file size: 20 MB <br />
                      </h4>
                      <input ref="fileInput" type="file" style="display: none" @change="handleFileSelection"
                        id="fileInput1" accept=".txt,.pdf,.doc,.docx" />
                    </div>

                    <P v-if="tempfiles && tempfiles.length > 0">
                      <span class="d-inline-block">{{ tempfiles[0].name }}</span>
                    </P>
                    <div class="butn-tooltip-group d-flex justify-center align-items-center">
                      <v-btn v-if="tempfiles && tempfiles.length > 0"
                        :disabled="tempfiles && tempfiles.length > 0 ? false : true" color="#7e0d10"
                        class="common-submit-blue-btn" @click="uploadFileAsset('file')">UPLOAD</v-btn>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="d-flex justify-content-start align-items-center"
                            style="visibility: hidden"><v-icon size="25px" color="#333">mdi-refresh</v-icon></span>
                        </template>
                        <span>Look's like our AI is busy<br />with other request, please<br />click
                          here to retry</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            
            <div class="upload_document_area">
              <div class="post-content-txt-area">
                
                

                <label>Paste Text Content (up to 5000 words)</label>
                <textarea v-bind:class="{ 'invalid-url': wordCount > 5000 }" cols="12" rows="12"
                  placeholder="Paste your content here..." v-model="contentPasted" @input="updateWordCount"></textarea>
                <p>Word Count: {{ wordCount }} / {{ maxWords }}</p>
              </div>
              <div class="butn-tooltip-group d-flex justify-center align-items-center">
                <v-btn v-if="contentPasted" :disabled="contentPasted && wordCount <= 5000 ? false : true" color="#7e0d10"
                  class="common-submit-blue-btn" @click="createAssetByPastedContent()">UPLOAD</v-btn>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="d-flex justify-content-start align-items-center"
                      style="visibility: hidden"><v-icon size="25px" color="#333">mdi-refresh</v-icon></span>
                  </template>
                  <span>Look's like our AI system is busy<br />with other
                    request, please<br />click here to retry</span>
                </v-tooltip>
              </div>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div :class="randomText ? 'blog-blank-screen' : ''" v-if="randomText">
      <img v-if="randomText" src="../assets/Images/aipostgenerator/creating-post-loader.gif" />
      <h3 class="mt-3">
        <span>{{ randomText }}</span>
      </h3>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
// import {eventBus} from '@/main'
Vue.use(VueBus);
Vue.use(VueToast);
var vm = this;
export default {
  components: {},
  props: {
    msg: String,
    // msg:{
    //     required:true,
    //     type: "string"
    // }
  },
  data() {
    return {
      maxWords: 5000,
      wordCount: 0,
      showErrorMsg: false,
      selectedAssetIds: [],
      assetErrorFlg: false,
      tempFileName: null,
      fileEditNameDialog: false,
      tempAssetEditId: null,
      selectedTab: "Transcript",
      searchTerm: "",
      tempFileContent: null,
      toggleDesignUploads: true,
      tabs: 0,
      userAssetList: [],
      generatedMessage: null,
      isBlogLoading: false,
      isLinkedInLoading: false,
      fileName: null,
      fileSize: null,
      generateBtnClicked: false,
      companyName: null,
      isTwitterLoading: false,
      EmailId: null,
      activeIndex: 0,
      OpenProductcreateDialog: false,
      productMood: "Formal",
      productDescription: "",
      productName: "",
      selectedFile: null,
      productList: [],
      createProductLoader: false,
      productNameValidation: false,
      productDescriptionValidation: false,
      productFileValidation: false,
      showOverAllLoader: false,
      newAssetDialog: false,
      isDragging: false,
      tempfiles: [],
      contentPasted: null,
      makeEdit: true,
      PostOptionOpen: false,
      fileContent: null,
      inputText: "",
      isValidURL: true,
      loading: false,
      randomText: "",
      createBotBox: false,
    };
  },
  watch: {
  },
  computed: {
  },
  methods: {
    createBoxFire(){
      this.createBotBox = !this.createBotBox;
    },
    preventClose(event) {
      event.stopPropagation();
    },
    closeOnClickOutside(event) {
      if (this.createBotBox && !this.$el.contains(event.target)) {
        this.createBotBox= false;
      }
    },
    updateWordCount() {
      const words = this.contentPasted.trim().split(/\s+/);
      this.wordCount = words.length;

      // Truncate text to 5000 words
      // if (this.wordCount > this.maxWords) {
      //   const truncatedWords = words.slice(0, this.maxWords);
      //   this.contentPasted = truncatedWords.join(' ');
      // }
    },
    makeProperUrl(inputUrl) {
      // Add "http://" if not already present
      if (!/^https?:\/\//i.test(inputUrl)) {
        inputUrl = "http://" + inputUrl;
      }

      // Check if domain name is missing, and add ".com" by default
      const urlParts = inputUrl.split('/');
      const domain = urlParts[2];
      if (!domain) {
        inputUrl += "e2m.live.com";
      }

      // Replace spaces with "%20"
      inputUrl = inputUrl.replace(/\s/g, '%20');

      return inputUrl;
    },
    createAssetByPastedContent() {
      this.generateRandomText();

      setInterval(this.generateRandomText, 6000);
      this.loading = true;
      axios
        .post(
          APIs.getAssetIdByPastedContent,
          {
            context: this.contentPasted,
            "botId": localStorage.getItem("latestBotId")
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.loading = false;
          if (response.data.status == 0) {
            this.loading = false;
            localStorage.setItem("latestAssetName", response.data.data.assetName);
            localStorage.setItem("latestAssetId", response.data.data.assetId);
            this.$router.push("/chatwithdata")
          }
        })
        .catch((error) => {
          // Handle the error
          this.loading = false;
          console.error(error);
        });
    },
    generateRandomText() {
      const texts = [
        "Fueling up the AI engines",
        "Brewing intelligence from data",
        "Processing and analyzing data",
        "Processing knowledge nuggets",
        "Infusing data with chatter prowess",
      ];

      const randomIndex = Math.floor(Math.random() * texts.length);
      this.randomText = texts[randomIndex];
    },
    uploadFileAsset(type) {
      if (type == "file") {
        console.log("axios====")
        this.generateRandomText();

        setInterval(this.generateRandomText, 6000);
        this.loading = true;
        const fileInput = document.getElementById("fileInput1");
        const file = fileInput.files[0];
      
        


        const formData = new FormData();
        formData.append("asset", file);
        formData.append("botId", localStorage.getItem("latestBotId"));

        const accessToken = localStorage.getItem("accessToken"); // Replace with your actual Bearer token

        // Set up the headers with the Bearer token
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          // Add any other headers you may need here
        };

        // Make the API request using axios or any other HTTP library
        // Example using axios:
        

        axios
          .post(APIs.getAssetIdByFileUpload, formData, { headers })
          .then((response) => {
            console.log("ndg sir api response====", response.data);
            this.loading = false;
            if (response.data.status == 0) {
              localStorage.setItem("latestAssetName", response.data.data.assetName);
              localStorage.setItem("latestAssetId", response.data.data.assetId);
              this.$router.push("/chatwithdata")
            }
          })
          .catch((error) => {
            this.loading = false;
            console.error(error);
            // Handle any errors
          });
      }
    },
    handleFileSelection(e) {
      const files = e.target.files;
      var maxFileSize = 20 * 1024 * 1024;
      if (files.length > 0) {
        var fileSize = files[0].size;
        if (fileSize > maxFileSize) {
          alert("File size exceeds the maximum limit of 20 MB.");
          event.preventDefault(); // Prevent form submission
        } else {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.tempfiles.push(file);
          }
        }
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy";
      this.isDragging = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const allowedExtensions = ["txt", "pdf", "doc", "docx"];
      const fileList = event.dataTransfer.files;
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        localStorage.setItem("uploadedFileName", file.name);

        localStorage.setItem("uploadedFileSize", (file.size / 1024).toFixed(2));
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          const file = fileList[i];
          this.tempfiles.push(file);
        }
      }
      console.log("files to upload====", this.tempfiles);
    },
    openFileExplorer() {
      this.$refs.fileInput.click();
    },
  },
  created() {
    vm = this;
  },
  mounted() {
    document.addEventListener("click", this.closeOnClickOutside);
    if (this.$route.query.param1 == "r") {
      this.$router.push({
        path: "/upload-file",
        query: {},
      });
      location.reload();
    }
    document.addEventListener("DOMContentLoaded", function () {
      var dropdownItems = document.getElementsByClassName("dropdown-item");

      for (var i = 0; i < dropdownItems.length; i++) {
        dropdownItems[i].addEventListener("click", function (e) {
          e.preventDefault();
          e.stopPropagation();
        });
      }
    });
    console.log("refresh====", this.$route.query.param1);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeOnClickOutside);
  },
};
</script>
<style>
.product-image-class {
  width: 100%;
  height: 125px;
  border: 2px dashed #e3e3e3;
  border-radius: 6px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.product-upload {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 99;
}

p.flie-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>