<template>
  <div :class="uiClasses" class="bot-change">
    <transition name="qkb-fadeUp">
      <div class="qkb-board" v-if="botActive">
        <board-header :bot-title="optionsMain.botTitle" @close-bot="botToggle"></board-header>
        <board-content :bot-typing="botTyping" :main-data="messages"></board-content>
        <board-action :input-disable="inputDisable" :input-placeholder="optionsMain.inputPlaceholder" :input-disable-placeholder="optionsMain.inputDisablePlaceholder" @msg-send="sendMessage"></board-action>
      </div>
    </transition>
    <div class="qkb-bot-bubble">
      <button class="qkb-bubble-btn" @click="botToggle">
        <slot name="bubbleButton">
          <transition name="qkb-scaleUp">
            <bubble-icon class="qkb-bubble-btn-icon" v-if="!botActive" key="1"></bubble-icon>
            <close-icon class="qkb-bubble-btn-icon qkb-bubble-btn-icon--close" v-else key="2"></close-icon>
          </transition>
        </slot>
      </button>
    </div>
    <app-style :options="optionsMain"></app-style>
    <div class="qkb-preload-image">
      <div class="qkb-msg-avatar__img" v-if="optionsMain.botAvatarImg"></div>
    </div>
    <div class="query-counter-box">
      <!-- <div class="query-input-box">
        <input type="text" value="" name="" placeholder="Ask follow-up"/>
      </div>
      <div class="related-followup">
        <h4>Related Follow-up Questions</h4>
        <ul>
          <li><span class="related-sentence">Benefits of pursuing graphic design courses after 10th</span> <span><img src="@/assets/Images/aipostgenerator/arrow-tilt.svg"/></span> </li>
          <li><span class="related-sentence">Top institutes for graphic design courses after 10th</span> <span><img src="@/assets/Images/aipostgenerator/arrow-tilt.svg"/></span> </li>
          <li><span class="related-sentence">Different types of graphic design courses available</span> <span><img src="@/assets/Images/aipostgenerator/arrow-tilt.svg"/></span> </li>
        </ul>
      </div> -->
    </div>
  </div>
  
</template>

<script>
import EventBus from '../helpers/event-bus'
import Config from '../config'
import BoardHeader from './Board/Header'
import BoardContent from './Board/Content'
import BoardAction from './Board/Action'
import AppStyle from './AppStyle'
import BubbleIcon from '../assets/icons/bubble.svg'
import CloseIcon from '../assets/icons/close.svg'
import { bus } from '../main'

export default {
  name: 'VueBotUI',
  components: {
    BoardHeader,
    BoardContent,
    BoardAction,
    BubbleIcon,
    CloseIcon,
    AppStyle,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    messages: {
      type: Array,
    },
    botTyping: {
      type: Boolean,
      default: false,
    },
    inputDisable: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    openDelay: {
      type: Number,
    },
  },
  data() {
    return {
      botActive: false,
      defaultOptions: {
        botTitle: 'Chatbot',
        colorScheme: '#7e0d10',
        textColor: '#fff',
        bubbleBtnSize: 56,
        animation: true,
        boardContentBg: '#fff',
        botAvatarSize: 32,
        botAvatarImg: 'http://placehold.it/200x200',
        msgBubbleBgBot: '#f0f0f0',
        msgBubbleColorBot: '#000',
        msgBubbleBgUser: '#081b2a',
        msgBubbleColorUser: '#fff',
        inputPlaceholder: 'Message',
        inputDisableBg: '#fff',
        inputDisablePlaceholder: null,
      },
    };
  },
  computed: {
    optionsMain() {
      return { ...this.defaultOptions, ...this.options };
    },
    uiClasses() {
      let classes = [];
      if (this.optionsMain.animation) {
        classes.push('qkb-bot-ui--animate');
      }
      return classes;
    },
  },
  created() {
    if (this.isOpen) {
      if (this.openDelay) {
        setTimeout(this.botOpen, this.openDelay);
      } else {
        this.botToggle();
      }
    }
    bus.$on('sendPrompt', (data) => {
      this.sendMessage(data);
    });
  },
  mounted() {
    document.addEventListener(Config.EVENT_OPEN, () => {
      this.botOpen();
    });
    document.addEventListener(Config.EVENT_CLOSE, () => {
      this.botClose();
    });
    document.addEventListener(Config.EVENT_TOGGLE, () => {
      this.botToggle();
    });
  },
  beforeDestroy() {
    EventBus.$off('select-button-option');
  },
  methods: {
    botOpen() {
      if (!this.botActive) {
        this.botToggle();
      }
    },
    botClose() {
      if (this.botActive) {
        this.botToggle();
      }
    },
    botToggle() {
      this.botActive = !this.botActive;
      if (this.botActive) {
        EventBus.$on('select-button-option', this.selectOption);
        this.$emit('init');
      } else {
        EventBus.$off('select-button-option');
        this.$emit('destroy');
      }
    },
    sendMessage(value) {
      this.$emit('msg-send', value);
    },
    selectOption(value) {
      this.sendMessage(value);
    },
  },
};
</script>

<style src="../assets/scss/_app.scss" lang="scss"></style>
