import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import ChatWithData from '../views/ChatWithData.vue'
import UploadFile from '../views/UploadFile.vue'
import ViewCreateBot from '../views/ViewCreateBot.vue'
import SearchView from '../views/SearchView.vue'
import SearchViewv2 from '../views/SearchView_v2.vue'
import SearchViewv3 from '../views/SearchView_v3.vue'
import SearchViewv4 from '../views/SearchView_v4.vue'
import Prompts from '../views/Prompts.vue'
import Tutorial from '../views/Tutorial.vue'
import IngestData from '../views/IngestData.vue'
import Trial from '../views/Trial.vue'
import ImageEditor from '../views/ImageEditor.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: "/upload-file",
    name: "UploadFile",
    component: UploadFile,
    meta: { requiresAuth: true }
  },
  {
    path: '/chatwithdata',
    name: 'Chatwithdata',
    component: ChatWithData,
    meta: { requiresAuth: true }
  },
  {
    path: "/bots",
    name: "ViewCreateBot",
    component: ViewCreateBot,
    meta: { requiresAuth: true }
  },
  {
    path: "/searchView",
    name: "SearchView",
    component: SearchView,
    meta: { requiresAuth: true }
  },
  {
    path: "/searchView_v2",
    name: "SearchView_v2",
    component: SearchViewv2,
    meta: { requiresAuth: true }
  },
  {
    path: "/prompts",
    name: "Prompts",
    component: Prompts,
    meta: { requiresAuth: true }
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: Tutorial,
    meta: { requiresAuth: true }
  },
  {
    path: "/ingestData",
    name: "IngestData",
    component: IngestData,
    meta: { requiresAuth: true }
  },
  {
    path: "/trial",
    name: "Trial",
    component: Trial,
    meta: { requiresAuth: true }
  },
  {
    path: "/searchView_v3",
    name: "SearchView_v3",
    component: SearchViewv3,
    meta: { requiresAuth: true }
  },
  {
    path: "/searchView_v4",
    name: "SearchView_v4",
    component: SearchViewv4,
    meta: { requiresAuth: true }
  },
  {
    path: "/image-editor",
    name: "ImageEditor",
    component: ImageEditor,
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn"); // Implement your own login check logic
  console.log("router======", isLoggedIn);

  //Check if the route requires authentication and the user is not logged in

  if (to.name == 'Login' && isLoggedIn) {
    if (localStorage.getItem("emailId") == 'hello@webspiders.com') {
      next("/searchView_v2");
    }
    else if (localStorage.getItem("validURL") || localStorage.getItem("latestBotId")) {
      next("/chatwithdata");
    } else {
      next("/upload-file");
    }
  }
  else if (to.meta.requiresAuth && (!isLoggedIn || isLoggedIn == "false")) {
    console.log("1======");
    next('/'); // Redirect the user to the login page

  } else {
    console.log("2======");

    next(); // Proceed with the navigation
  }
});



export default router
