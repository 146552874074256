<template>
  <div class="qkb-msg-bubble-component qkb-msg-bubble-component--single-text">
    <div class="qkb-msg-bubble-component__text">
      <vue-markdown>{{ formattedContent }}</vue-markdown>
      <!-- <p v-if="formattedContent.includes('<br/>')" v-html="formattedContent"></p>
      <div v-else v-html="formattedContent"></div> -->

    </div>

    <v-dialog v-model="showVideoDialog" width="auto" scrollable>
      <template>
        <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
          <v-card-title>
            <div class="view-asset-head">
              <v-btn fab text x-small class="close-btn" @click="showVideoDialog = false;video=''"><v-icon color="#808080"
                  size="20px">mdi-close</v-icon></v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <div><iframe width="560" height="315" :src="video" frameborder="0"
                allowfullscreen></iframe></div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown-v2'
export default {
  props: {
    mainData: {
      type: Object
    }
  },
  data() {
    return {
      showVideoDialog: false,
      video: ""
    }
  },
  components: {
    VueMarkdown
  },
  computed: {
    formattedContent() {
      return this.mainData.text.replace(/\n/g, '<br/>');
    },
  },
  mounted() {
    // Find the anchor element by its ID
    const anchorElement = document.getElementById("video1");
    const anchorElement2 = document.getElementById("video2");
    const anchorElement3 = document.getElementById("video3");

    // Check if the anchor element is found
    if (anchorElement) {
      // Attach a click event listener to the anchor element
      anchorElement.addEventListener("click", () => {
      
        this.video = "https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/ai_video_folder%2Findian%20accent%20vikram%20solar%20-%20human.mp4?alt=media";
        // Show an alert when the anchor is clicked
        this.showVideoDialog = true;
      });
    }
    if (anchorElement2) {
      // Attach a click event listener to the anchor element
      anchorElement2.addEventListener("click", () => {
      
        this.video = "https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/ai_video_folder%2Findian%20accent%20vikram%20solar.mp4?alt=media";
        // Show an alert when the anchor is clicked
        this.showVideoDialog = true;
      });
    }
    if (anchorElement3) {
      // Attach a click event listener to the anchor element
      anchorElement3.addEventListener("click", () => {
      
        this.video = "https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/ai_video_folder%2Famerican%20accent%20solar%20video.mp4?alt=media";
        // Show an alert when the anchor is clicked
        this.showVideoDialog = true;
      });
    }
  },
}
</script>
