//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//Zoe API
//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export default {
    APIs: {
        zoeChat: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/get-answer",
        faqAPI: "https://geckoaipy-dev-dot-geckoai2023.ue.r.appspot.com/get-faq-from-assets",
        getAuthToken: "https://zoeainode-dev-dot-geckoai2023.ue.r.appspot.com/get-auth-token",
        getAssetIdByFileUpload: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/create-file-asset",
        getAssetIdByPastedContent: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/create-text-asset",
        getAssetAPI: "https://zoeainode-dev-dot-geckoai2023.ue.r.appspot.com/get-assets",
        viewAssetAPI: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/get-asset-content",
        warmNodeAPI: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/hello",
        createBotAPI: "https://zoeainode-dev-dot-geckoai2023.ue.r.appspot.com/create-bot",
        getMyBotsAPI: "https://zoeainode-dev-dot-geckoai2023.ue.r.appspot.com/my-bots",
        editAssetAPI: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/update-asset-text",
        clearContextAPI: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/clear-context",
        getWelcomeMessageAPI: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/get-bot-welcome-msg",
        getAssetPropertiesAPI: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/get-asset-properties",
        updateAssetPropertiesAPI: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/update-asset-properties",
        getVectorChunckAPI: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/get-vector-chunks",
        searchTechOffers: "https://zoeainode-dev-dot-geckoai2023.ue.r.appspot.com/tech-offers",
        externalTechOffers: "https://zoeainode-dev-dot-geckoai2023.ue.r.appspot.com/external-tech-offers",
        zoeTechOffers: "https://zoeainode-dev-dot-geckoai2023.ue.r.appspot.com/zoe-tech-offers",
        techNeeds: "https://zoeainode-dev-dot-geckoai2023.ue.r.appspot.com/tech-needs",
        getPrompts:"https://zoeainode-dev-dot-geckoai2023.ue.r.appspot.com/get-prompts",
        updatePrompts:"https://zoeainode-dev-dot-geckoai2023.ue.r.appspot.com/save-prompt",
        getSummaryTechOffer: "https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/get-search-result-summary"
    }
};