<template>
    <div>
        <div style="height: 600px">
            <PinturaEditor v-bind="editorDefaults" :src="src" :imageCropAspectRatio="imageCropAspectRatio">
            </PinturaEditor>
        </div>
    </div>
</template>
<script>
import '@pqina/pintura/pintura.css'
// Import the editor default configuration
import { getEditorDefaults } from '@pqina/pintura';

// Import the component from `vue-pintura`
// When using Vue 2 import from @pqina/vue-pintura/vue-2 instead
import { PinturaEditor } from '@pqina/vue-pintura/vue-2';

export default {
    components: {
        PinturaEditor,
    },
    data() {
        return {
            // Pass the editor default configuration options
            editorDefaults: getEditorDefaults(),

            // The source image to load
            src: 'https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/PHOTO-2024-05-30-08-15-56.jpg?alt=media&token=14280c2f-6b95-4c50-9dfc-c632bd64a01f',

            // This will set a square crop aspect ratio
            imageCropAspectRatio: 1,
        };
    },
};
</script>