<template>
  <div class="container">
    <div class="scan_upload upload-doc-area">
      <div class="create-bot-pre-step mb-12">
        <!-- <transition name="fade">
          <div class="create-bot-drop text-right mb-5">
            <v-btn @click="createBoxFire()" color="#400000" class="common-button">Create Bot <v-icon color="#fff" size="22px" class="ml-2">mdi-chevron-down</v-icon></v-btn>
            <div v-if="createBotBox" class="create-bot-input-area">
              <input type="text" value="" id="" placeholder="Write your text here..."/>
              <v-btn color="#400000" class="common-button">Create</v-btn>
            </div>
          </div>
        </transition> -->
        <v-row>
          <v-col v-if="clientEmail != 'hello@webspiders.com'">
            <v-card>
              <div class="zoe-bot-logo-middle" @click="toggleAddNewBot()">
                <v-icon size="80px" color="#400000">mdi-plus</v-icon>
              </div>
              <h5></h5>

              <div class="create-btn-area" v-if="!createBotFlg">
                <v-btn dark color="#400000" class="common-button" @click="toggleAddNewBot()">Add new Bot</v-btn>
              </div>
              <div class="create-btn-area" v-else>
                <input type="text" v-model="myBotName" id="" placeholder="Enter your bot name..." />
                <v-btn dark color="#400000" class="common-button" @click="createBot()">Create</v-btn>
              </div>
            </v-card>
          </v-col>
          <v-col v-for="(bot) in myBotList" :key="bot.botId" lg="4" md="4" sm="6" cols="12">
            <v-card  class="enterin_bot">
              <div class="zoe-bot-logo-middle">
                <img src="../assets/Images/aipostgenerator/zoe-z-logo.png" />
              </div>
              <h5>{{ bot.botName }}</h5>
              <div class="create-btn-area enter-button-area">
                <v-btn dark color="#400000" class="common-button" @click="storeBotInformation(bot)">Enter</v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
// import {eventBus} from '@/main'
Vue.use(VueBus);
Vue.use(VueToast);
var vm = this;
export default {
  components: {},
  props: {
    msg: String,
    // msg:{
    //     required:true,
    //     type: "string"
    // }
  },
  data() {
    return {
      myBotList: [],
      createBotFlg: false,
      myBotName: "",
      clientEmail: null
    };
  },
  watch: {
  },
  computed: {
  },
  methods: {
    getAssetList(botId) {
      console.log("getAssetList=====");
      axios
        .get(APIs.getAssetAPI + '?botid='+botId, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          console.log("get===================")
          console.log("response==asset====", response.data);
          console.log("botId====", botId);

          if (response.data && response.data.status == 0) {
            console.log("botId==2==", botId);
            if(botId == 71 || botId == 70){
              console.log("botId==222==", botId);
                this.$router.push("/searchView_v4");
              }else{
                if(response.data.assets && response.data.assets.length > 0) {
              if(botId == '66'){
                this.$router.push("/searchView_v2");
              }else if(botId == '71' || botId == '70'){
                this.$router.push("/searchView_v4");
              }else{
                this.$router.push("/chatwithdata");
                
              }
              
            }else{
              this.$router.push("/upload-file")
            }
              }
            
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    storeBotInformation(details){
      localStorage.setItem("latestBotId", details.botId);
      this.getAssetList(details.botId)
    },
    toggleAddNewBot() {
      this.createBotFlg = true;
    },
    async getMyBots() {
      //this.myBotList = [];
      try {
        const apiUrl = APIs.getMyBotsAPI;
        let headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        console.log("header=====",headers);

        const response = await axios.get(apiUrl, {headers: headers});

       

        if (response.data.status == 0) {
          this.myBotList = response.data.bots;
          if(this.clientEmail == 'hello@webspiders.com'){
            this.myBotList = response.data.bots.filter(item=> item.botId == 66)
            localStorage.setItem("latestBotId", 66);
            this.$router.push("/searchView_v2")
          }else if(response.data.bots.filter(item=> item.botId == 71)){
            console.log("inside======");

          }else if(response.data.bots.filter(item=> item.botId == 70)){
            console.log("inside======");

          }
          else if(this.myBotList &&  this.myBotList.length == 1){
            localStorage.setItem("latestBotId", this.myBotList[0].botId);
            this.$router.push("/chatwithdata")
          }
          else if(this.myBotList &&  this.myBotList.length > 0) {
            this.myBotList.sort((a, b) => {
            const dateA = new Date(a.createdOn);
            const dateB = new Date(b.createdOn);

            // Compare in descending order
            return dateB - dateA;
          });
          }
        }
      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },

    async createBot() {
      try {
        const apiUrl = APIs.createBotAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const response = await axios.post(apiUrl, {
          "key": {
          },
          "data": {
            "botName": this.myBotName
          }
        }, { headers });
        if (response.data.status == 0) {
          this.createBotFlg = false;
          this.getMyBots();
        }
      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
  },
  created() {
    vm = this;
  },
  mounted() {
    if (this.$route.query.param1 == "r") {
      this.$router.push({
        path: "/bots",
        query: {},
      });
      location.reload();
    }
    // this.createBot();
    if (localStorage.getItem("emailId")) {
      this.clientEmail = localStorage.getItem("emailId");
    }
    this.getMyBots();
  },
  beforeDestroy() {

  },
};
</script>
<style></style>