<template>
  <div class="qkb-msg-bubble-component qkb-msg-bubble-component--button-options">
    <div class="qkb-msg-bubble-component__text">{{ mainData.text }}</div>
    <div class="qkb-msg-bubble-component__options-wrapper">
      <div
        v-for="(item, index) in mainData.options"
        :key="index"
        :class="{ active: selectedItem === item.value }"
      >
        <button
          class="qkb-mb-button-options__item"
          v-if="item.action === 'postback'"
          @click="selectOption(item)"
        >
          <span>{{ item.text }}</span>
        </button>
        <a
          class="qkb-mb-button-options__item qkb-mb-button-options__url"
          target="_blank"
          v-else
          :href="item.value"
        >
        <span>{{ item.text }}</span>
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import EventBus from '../../helpers/event-bus'

export default {
  props: {
    mainData: {
      type: Object
    }
  },

  setup(props) {
    const selectedItem = ref(null)

    const selectOption = (value) => {
      selectedItem.value = value
      EventBus.$emit('select-button-option', value)
    }

    return {
      selectedItem,
      selectOption
    }
  }
}
</script>
