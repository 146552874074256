<template>
  <div class="post-generate-wrap">

    <div v-if="viewMobile" class="post-generate-channels left-panel-tab-hld">
      <v-btn @click="viewMobile = false" fab text class="close-left-buttn"><v-icon size="25px"
          color="#333">mdi-close</v-icon></v-btn>
      <h3 class="mt-0 mt-md-4 mb-0">Asset List</h3>

      <div class="mt-5 mb-3 product-create">
        <div class="product-create-dropdown">
          <v-select v-model="selectedBot" :items="myBotList" label="Select" outlined item-text="botName"
            item-value="botId" color="#dde1e8" @change="changeSelectedBot(selectedBot)" disabled></v-select>
        </div>
      </div>
      <ul class="add-assets-button">
        <li @click="$router.push('/upload-file')">
          Add New Asset <v-icon size="20" color="#fff" class="ml-3">mdi-plus</v-icon>
        </li>
      </ul>
      <div class="search-assets">
        <input type="text" name="" id="" placeholder="Search Assets..." v-model="searchTerm" />
      </div>

      <ul class="asset-list">
        <div class="asset-headers-hld">
          <div class="d-flex justify-content-start align-items-center select-all">
            <v-checkbox class="select-asset mt-0" v-model="selectAllCheckBox" @click="selectAllAsset()"></v-checkbox>
            <label class="m-0">Select All</label>
          </div>
          <span v-if="totalPages" class="total-asset mr-3">Total Assets: {{ totalCount }}</span>
        </div>

        <li v-for="(asset, index) in filteredAssetList" :key="index"
          :class="{ 'active-tab': activeIndex == asset.assetId }"
          class="d-flex justify-content-between align-items-center">
          <!-- <v-tooltip right> -->
          <!-- <template v-slot:activator="{ on, attrs }"> -->
          <span>
            <v-checkbox class="select-asset mt-0" v-model="asset.IsChecked"></v-checkbox>
          </span>
          <!-- </template> -->
          <!-- <span>To edit this selection, click on the top right Back button</span> -->
          <!-- </v-tooltip> -->

          <div class="left-asset-name">
            <v-icon size="22px" color="#b1bac8" class="mr-1">mdi-file-document-outline</v-icon>

            <input type="text" v-model="asset.assetName" :disabled="makeEdit" v-if="asset.assetName"
              :id="'MakeFocus' + index" />
          </div>
          <v-btn title="View Asset" :loading="asset.viewIconLoading" fab x-small elevation="0" class="mr-2"
            @click="fetchAssetContent(asset.assetId, index)"><v-icon size="22px"
              color="#333">mdi-eye-outline</v-icon></v-btn>
          <v-btn title="Edit Asset" :loading="asset.editIconLoading" fab x-small
            @click="editAssets(asset.assetId, index);" elevation="0" class="mr-2"><v-icon size="22px"
              color="#333">mdi-pencil</v-icon></v-btn>

          <v-btn title="Edit Asset Properties" :loading="asset.editPropertiesIconLoading" fab x-small
            @click="editAssetProperties(asset.assetId, index);" elevation="0"><v-icon size="22px"
              color="#333">mdi-view-dashboard-edit-outline</v-icon></v-btn>

        </li>
      </ul>

      <div class="pagination2">
        <ul>
          <li v-for="pageNumber in totalPages" :key="pageNumber">
            <button @click="goToPage(pageNumber)" :class="{ active: currentPage === pageNumber }">{{ pageNumber
              }}</button>
          </li>
        </ul>
      </div>

      <v-btn @click="viewMobile = false" width="100%" color="#400000" class="apply-asset-btn">Apply</v-btn>


    </div>
    <div class="post-generate-inner position-relative regular">
      <particles />
      <div class="search-new-area">
        <v-card class="search-bar-card new-search-bar-carlsberg">

          <div class="search-panel-techneeds" v-if="!showSuccess">
            <!-- <div class="searh-head">
              <h3>Enter your design brief in a few words</h3>
            </div> -->
            <div class="new-search-head">
              <!-- <h3>GenGraphix</h3> -->
              <p>Image generation studio</p>
            </div>
            <div class="search-panel-new" v-if="showSearchPrompt">
              <v-textarea outlined solo name="input-7-4" label="Type your design brief in a few words" elevation="0"
                value="" v-model="searchPrompt" height="70px" @keyup.enter="showChatBox()">
              </v-textarea>

              <v-btn rounded color="#535de1" class="ml-3 submit-search" width="180px" height="50px"
                @click="showChatBox()">
                Generate
              </v-btn>
            </div>
            <div class="chattobot_panel" v-if="showChatPrompt" id="chattobot_panel">
              <div class="bot-upper">
                <div class="bot-upper-inner" ref="chatContainer">
                  <div v-for="chat, index in chatResponses" :key="index" class="full-chat-hld">
                    <div class="single-chat-row-right" v-if="chat.type == 'human'">
                      <div class="bot-right-text-message">
                        <p>{{ chat.text }}</p>
                      </div>
                    </div>
                    <div class="single-chat-row-left" v-if="chat.type == 'bot'">
                      <div class="bot-left-text-message">
                        <div class="bot-face">
                          <img src="@/assets/Images/aipostgenerator/spiderx-fav-1.png" />
                        </div>
                        <p>{{ chat.text }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-chat-row-right d-none">
                  <div class="image-paste-area">
                    <ul>
                      <li><img src="@/assets/Images/aipostgenerator/carlsberg1.jpg" /></li>
                      <li><img src="@/assets/Images/aipostgenerator/carlsberg2.jpg" /></li>
                      <li><img src="@/assets/Images/aipostgenerator/carlsberg3.png" /></li>
                      <li><img src="@/assets/Images/aipostgenerator/carlsberg4.jpg" /></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="bot-message-panel">
                <div class="image-panel-float d-none">
                  <div class="single-float-image"><img src="@/assets/Images/aipostgenerator/carlsberg1.jpg" /><span
                      class="image-close">x</span></div>
                  <div class="single-float-image"><img src="@/assets/Images/aipostgenerator/carlsberg2.jpg" /><span
                      class="image-close">x</span></div>
                  <div class="single-float-image"><img src="@/assets/Images/aipostgenerator/carlsberg3.png" /><span
                      class="image-close">x</span></div>
                  <div class="single-float-image"><img src="@/assets/Images/aipostgenerator/carlsberg4.jpg" /><span
                      class="image-close">x</span></div>
                </div>
                <input type="text" v-model="userTypedMsg" placeholder="Type Your Message"
                  @keyup.enter="sendChatMsg()" />
                <v-btn fab color="#1867c0" small elevation="0" @click="sendChatMsg()"><img
                    src="@/assets/Images/aipostgenerator/send-message.svg" /></v-btn>
              </div>
            </div>
          </div>
          <div class="loading-panel-techneeds d-none">
            <div class="loader-section">
              <img src="@/assets/Images/aipostgenerator/IPI-animation-loader.gif" />
            </div>
            <div class="loader-text">
              <h3>Your design brief is being submitted, please wait for 5-60 minutes....</h3>
            </div>
          </div>
          <div class="loading-panel-techneeds" v-if="showSuccess">
            <div class="submitted-wrap-icon">
              <img src="@/assets/Images/aipostgenerator/submitted.png" />
            </div>
            <div class="loader-text">
              <h3 class="success-message">Thank you for submitting your design brief to SpiderX AI.<br />You will
                receive
                your custom design
                shortly.</h3>
            </div>
          </div>
        </v-card>
        <div v-if="prompts && prompts.length == 0" class="post_placeholder">
          <img src="@/assets/Images/aipostgenerator/zoe_bot_blank_img.png" />
          <h3>Type your design brief in a few words to generate Images</h3>
        </div>
        <v-card class="mt-4 search-result-table">

          <v-data-table id="queryDataTable" :height="tableHeight" :headers="headers" :items="prompts"
            :items-per-page="50" :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
            v-if="prompts && prompts.length > 0" fixed-header class="hide-footer">

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ formatDate(item.updatedOn) }}</td>
                <td>
                  <div class="query-text-hld">
                    {{ item.queries }} <v-btn fab text x-small @click="copyToText(item.queries)" class="ml-3"
                      :title="copyButtonTitle">
                      <v-icon size="18px" color="#505050">mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </td>
                <td class="text-center">
                  <div :class="getStatusClass(item.status)" class="box" v-if="item.status != 'Finished'">
                    <v-icon :color="getStatusColor(item.status)" size="18px" class="my-0 mr-2">
                      {{ getStatusIcon(item.status) }}
                    </v-icon>
                    {{ item.status }}
                    <div class="top"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                    <div class="right"></div>
                  </div>
                  <div v-else :class="getStatusClass(item.status)">
                    <v-icon :color="getStatusColor(item.status)" size="18px" class="my-0 mr-2">
                      {{ getStatusIcon(item.status) }}
                    </v-icon>
                    {{ item.status }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="star-rating">
                    <input type="radio" id="5-stars" name="rating" value="5" />
                    <label for="5-stars" class="star">&#9733;</label>
                    <input type="radio" id="4-stars" name="rating" value="4" />
                    <label for="4-stars" class="star">&#9733;</label>
                    <input type="radio" id="3-stars" name="rating" value="3" />
                    <label for="3-stars" class="star">&#9733;</label>
                    <input type="radio" id="2-stars" name="rating" value="2" />
                    <label for="2-stars" class="star">&#9733;</label>
                    <input type="radio" id="1-star" name="rating" value="1" />
                    <label for="1-star" class="star">&#9733;</label>
                  </div>

                </td>
                <td class="text-center">

                  <v-btn :disabled="item.status != 'Finished'"
                    @click="item.status == 'Finished' ? openViewIdeas(item) : null" outlined class="matches-btn"
                    color="blue">
                    View Content
                  </v-btn>

                  <!-- <v-btn outlined class="matches-btn" :color="item.status == 'Finished' ? '#1867c0' : '#b9d1ec'"
                      :disabled="item.status != 'Finished'"
                      @click="item.status == 'Finished' ? viewMatches(item) : null">
                      View Matches
                    </v-btn> -->
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </div>



    </div>

    <!-- <div class="email-gen-channels">
  
        <h3>Matching Resources</h3>
        <div class="blank-email-section" v-if="updateResList && updateResList.length == 0">
          <h4>Start a conversation to view resources.</h4>
        </div>
        <div class="email-list-wrap" v-else>
          <div class="single-email-box" v-for="resource in updateResList" :key="resource"
            @click="viewDetailedResource(resource.title)">
            <div class="email-line-1">
              
            </div>
  
            <div class="email-line-2">
              <h5 class="email-subject">{{ resource.title }}</h5>
            </div>
            <div class="email-line-3">
              
              <div class="tag">Score:<span>{{ resource.score }}</span></div>
  
  
            </div>
          </div>
        </div>
      </div> -->
    <v-dialog v-model="fileViewAssetDialog" width="auto" scrollable>
      <template>
        <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
          <v-card-title>
            <div class="view-asset-head">
              <h4>View Asset</h4>
              <div>
                <v-btn fab text x-small class="close-btn d-none" @click="fileViewAssetDialog = false"><v-icon
                    color="#000000" size="20px">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <div v-html="formattedViewContent"></div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="fileEditNameDialog" width="auto">
      <template>
        <v-card
          class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column edit-aset-view">
          <div class="" style="width: 100%">
            <div class="view-asset-head">
              <h4>Edit Asset</h4>
              <v-btn fab text x-small class="close-btn" @click="fileEditNameDialog = false"><v-icon color="#000000"
                  size="20px">mdi-close</v-icon></v-btn>
            </div>
          </div>
          <div class="text-area-edit"><textarea v-model="editAssetContent" style="width:100%;height:100%"></textarea>

          </div>
          <v-btn dark color="#400000" class="common-button mt-2" @click="updateAsset()"
            :loading="updateAssetFlg">Update</v-btn>

        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="viewResourceDialog" width="auto" scrollable>
      <template>
        <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
          <v-card-title>
            <div class="view-asset-head">
              <h4>View Resource</h4>
              <v-btn fab text x-small class="close-btn" @click="viewResourceDialog = false"><v-icon color="#000000"
                  size="20px">mdi-close</v-icon></v-btn>
            </div>
          </v-card-title>
          <!-- <div class="" style="width: 100%"> -->
          <v-card-text>
            <div v-html="formattedViewResourceContent"></div>
          </v-card-text>


          <!-- <v-btn @click="fileViewAssetDialog = false" fab small elevation="0"><v-icon size="24px"
                  color="#5a646e">mdi-close</v-icon></v-btn> -->
          <!-- </div> -->

        </v-card>
      </template>
    </v-dialog>



    <v-dialog v-model="tectNeedsResult" :key="dialogKey" width="1300px">

      <v-card class="result-card">
        <v-card-title class="p-0">
          <div class="heading-area-result position-relative text-center">
            <h4 :title="tempViewedMatch">Search Query: {{ tempViewedMatch }}</h4>
            <v-btn @click="tectNeedsResultClose()" fab text x-small elevation="0"><v-icon color="#fff"
                size="25px">mdi-close-circle-outline</v-icon></v-btn>
          </div>
        </v-card-title>

        <v-card-text>
          <div class="result-area ">
            <v-tabs v-model="resultTab" centered slider-color="yellow" class="tab-area-head position-relative">
              <div class="d-flex justify-content-center w-100">
                <v-tab class="tab-b">
                  Outside IPI
                </v-tab>
                <v-tab class="tab-a">
                  Within IPI
                </v-tab>
              </div>
              <div class="zoe-ai-logo">
                <p><small>Powered by<img src="@/assets/Images/aipostgenerator/zoe-ai-logo.svg" /></small></p>
              </div>
            </v-tabs>

            <v-tabs-items v-model="resultTab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text v-html="techNeedAnswer.external_tech_offers"></v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text v-html="techNeedAnswer.internal_tech_offers"></v-card-text>
                </v-card>
              </v-tab-item>

            </v-tabs-items>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="filePropertyEditDialog" width="500" height="500px">

      <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column edit-aset-view">
        <div style="width: 100%">
          <div class="view-asset-head">
            <h4>Edit Asset Property</h4>
            <v-btn fab text x-small class="close-btn" @click="filePropertyEditDialog = false">
              <v-icon color="#000000" size="20px">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="text-area-edit-property mt-4 mb-3">
          <div class="form-group">
            <label for="name" class="mr-2" width="20px">Name</label>
            <input v-model="tempAssetEditProperty.name" id="name" class="form-control" />
          </div>
          <div class="form-group">
            <label for="name" class="mr-2" width="20px">Source</label>
            <input v-model="tempAssetEditProperty.source" id="name" class="form-control" />
          </div>
          <div class="form-group">
            <label for="annotation" class="mr-2" width="20px">Annotation</label>
            <textarea v-model="tempAssetEditProperty.annotation" id="annotation" class="form-control"
              style="width:300px;height:250px"></textarea>
          </div>
        </div>
        <v-btn dark color="#400000" class="common-button mt-2" @click="updateAssetProperty()"
          :loading="updateAssetPropertLoader">
          Update
        </v-btn>
      </v-card>
    </v-dialog>



    <v-dialog v-model="imageGalleryResults" fullscreen max-width="1200px" scrollable content-class="image-gallery rounded-xl">
      <v-card>
        <v-card-title>
          <div class="view-ideas-hld">
            <h3>Explore your ideas here</h3>
            <v-btn x-small fab text class="close-result" @click="imageGalleryResults = false"><v-icon color="#ffffff"
                size="30px">mdi-close</v-icon></v-btn>
          </div>
          <div class="spiders-branding">
            <p>{{ tempGalleryImages.length }} images generated</p>
            <div class="powered">
              <p>Powered by</p>
              <img src="@/assets/Images/aipostgenerator/login-logo.png" />
            </div>
          </div>
        </v-card-title>
        <v-card-text>

          <div class="gallery-result">
            <div class="gallery custom-row">
              <div class="big-image-holder">
                <img :src="selectedImageUrl" />
              </div>
              <div class="gallery-image-listing">
                <div v-for="(image, index) in tempGalleryImages" :key="index" class="single-result-image"
                  :class="{ selected: image === selectedImageUrl }" @click="selectImage(image)">
                  <img :src="image" :alt="image" loading="eager" />
                </div>
              </div>
            </div>
          </div>


        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="singleImageResult" width="800px">
      <v-card>
        <v-card-title>
          <div class="view-ideas-hld">
            <h3>View your idea here</h3>
            <v-btn x-small fab text class="close-result" @click="singleImageResult = false"><v-icon color="#000000"
                size="25px">mdi-close</v-icon></v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="gallery-result">
            <v-carousel>
              <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" reverse-transition="fade-transition"
                transition="fade-transition"></v-carousel-item>
            </v-carousel>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";
// import BotIcon from "../assets/icons/bot.png";
// import { VueBotUI } from "../vue-bot-ui";
import particles from '../components/particles'
import moment from 'moment';
import { query } from "firebase/database";


Vue.use(VueBus);
export default {
  props: {},
  components: {
    // BotIcon,
    // VueBotUI,
    particles,
  },
  data() {
    return {
      selectedImageUrl: '',
      tempGalleryImages: [],
      finalPrompt: '',
      userTypedMsg: '',
      chatResponses: [],
      showSearchPrompt: true,
      showChatPrompt: false,
      copyButtonTitle: 'Copy the query',
      imageGalleryResults: false,
      singleImageResult: false,
      copiedText: null,
      tempViewedMatch: "",
      //tableHeight: 400,
      model: null,
      tectNeedsResult: false,
      promptTemplate: {
        Bollinger: `Context: {{context}}

Background color: {{bgBackground}}
Color: Deep brown
Texture: Rough canvas

Print Design Menu Material:
Material: Weathered parchment
Level of Wear: Slightly worn

Composition:
Aspect Ratio: Portrait (2:3)
Layout: {{layout}}

Decorative Element: {{decorativeElements}}
Type: Premium Wine
Color Palette: Muted greens and purples

Font Style:
Script for Titles: Elegant cursive
Font for Descriptions: Classic serif

Generate Text on the artwork: NO

Additional Instructions:
Number of Sections: "Boissons" (Drinks), "Vins" (Wines), and "Cocktails"
Overall Mood: Vintage and classy
Color Scheme: Harmonious, with design elements complementing the background color.
Error Handling:
If none of the background color options appeal, you can self-pick a preferred color scheme.
Specify a number between 2 and 4 for the number of drink sections. Values outside this range will be ignored.
If the generated image doesn't meet your expectations, refine the prompt with more details and try again. 
Output as below:
{{userPrompt}}
`,
        Carlsberg: `Context: {{context}}

Background color: {{bgBackground}}
Color: Soft pastels (e.g., peach, light pink, beige)
Texture: Smooth and clean
Print Design Menu Material:
Material: Matte cardstock
Level of Wear: New and pristine
Composition:
Aspect Ratio: Various (portrait and vertical layouts)
Layout: {{layout}}
Decorative Element: {{decorativeElements}}
Type: Retro illustrations and geometric patterns
Color Palette: Soft pastels with pops of vibrant colors
Font Style:
Script for Titles: Bold sans-serif
Font for Descriptions: Simple and clean sans-serif
Additional Instructions:
Number of Sections: Multiple drink entries, each with an image and description
Overall Mood: Fun and nostalgic
Color Scheme: Harmonious and playful, with design elements complementing the pastel background
Error Handling:
If none of the background color options appeal, you can self-pick a preferred color scheme.
Specify a number between 2 and 4 for the number of drink sections. Values outside this range will be ignored.
If the generated image doesn't meet your expectations, refine the prompt with more details and try again. 
Note: Include placeholder text ("Lorem ipsum") and drink images. Ensure the design is suitable for both printed menus and various display formats.
Output as below:
{{userPrompt}}
`,
        'Johnnie Walker': `Context: {{context}}

Background color: {{bgBackground}}
Color: Light, abstract with mixed colors
Texture: Smooth and clean

Print Design Menu Material:
Material: Glossy cardstock
Level of Wear: New and pristine

Composition:
Aspect Ratio: Various (Portrait for the main menu, vertical layout for the smartphone version)
Layout: {{layout}}

Decorative Element: {{decorativeElements}}
Type: Modern abstract art
Color Palette: Bright and varied, with an emphasis on contrasting colors

Font Style:
Script for Titles: Modern sans-serif
Font for Descriptions: Clean and readable sans-serif

Generate Text on the artwork: NO

Additional Instructions:
Number of Sections: Multiple drink entries, each with an image and description
Overall Mood: Trendy and eye-catching
Color Scheme: Vibrant and dynamic, with design elements complementing the artwork
Note: Include placeholder text ("Lorem ipsum") and drink images. Ensure the design is suitable for both printed menus and digital displays (e.g., smartphone screens).
Error Handling:
If none of the background color options appeal, you can self-pick a preferred color scheme.
Specify a number between 2 and 4 for the number of drink sections. Values outside this range will be ignored.
If the generated image doesn't meet your expectations, refine the prompt with more details and try again. 
Output as below:
{{userPrompt}}`,
        'Default': `Context {{context}}
Background color: {{bgBackground}}
Color: Soft pastels (e.g., peach, light pink, beige)
Texture: Smooth and clean
Print Design Menu Material:
Material: Matte cardstock
Level of Wear: New and pristine
Composition:
Aspect Ratio: portrait and vertical layout
Layout: {{layout}}
Decorative Element: {{decorativeElements}}
Type: Contemporary illustrations
Color Palette: Soft pastels with pops of vibrant colors
Font Style:
Script for Titles: Bold sans-serif
Font for Descriptions: Simple and clean sans-serif
Additional Instructions:
Number of Sections: Multiple drink entries, each with an image and description
Overall Mood: Fun and nostalgic
Color Scheme: Harmonious and playful, with design elements complementing the pastel background
Error Handling:
If none of the background color options appeal, you can self-pick a preferred color scheme.
Specify a number between 2 and 4 for the number of drink sections. Values outside this range will be ignored.
If the generated image doesn't meet your expectations, refine the prompt with more details and try again. 
Note: Include placeholder text ("Lorem ipsum") and drink images. Ensure the design is suitable for both printed menus and various display formats.
Output as below:
{{userPrompt}}
`
      },
      promptInjectObject: {},
      techOfferAssetIds: [],
      options: [
        "Ultrafast Conversion of Food Waste to Biofertilizers",
        "Development of Postbiotic Feed for Hatchery Operations",
        "Seeking Expertise to Evaluate Wavelength Conversion Film for Plant Growth Enhancement",
        "End To End AI Platform for Building Custom Computer Vision Capabilities",
        "Application of Ozonated Water Generator for Enhanced Sanitization in Food Production Line"
      ],
      headers: [
        { text: 'Requested On', align: 'start', value: 'updatedOn' },
        { text: 'Design Brief', align: 'start', value: 'queries' },
        { text: 'Status', align: 'center', value: 'status' },
        { text: 'Rate The Match', align: 'center', value: 'rate' },
        { text: 'Action', align: 'center', value: 'action', sortable: false },
      ],

      //Index of the active image on the images array
      activeImage: 0,
      prompts: [],
      concatOffers: [],
      clicked: false,
      showBoxLoader: false,
      processedIpiTechOffers: [],
      processedExternalTechOffers: [],
      overallTechOffers: [],
      searchPrompt: "",
      currentPage: 1,
      itemsPerPage: 100,
      totalPages: 0,
      totalCount: 0,
      viewResourceDialog: false,
      updateResList: [],
      resourceList: [],
      updateAssetPropertLoader: false,
      filePropertyEditDialog: false,
      tempAssetEditProperty: {},
      checkbox: false,
      selectAllCheckBox: true,
      editIconLoading: false,
      viewIconLoading: false,
      updateAssetFlg: false,
      fileViewAssetDialog: false,
      fileEditAssetDialog: false,
      viewAssetContent: '',
      editAssetContent: '',
      myBotList: [],
      viewMobile: true,
      inputDisable: false,
      botTyping: false,
      botOptions: {
        botAvatarImg:
          "https://zoebot.ai/sites/all/themes/custom/zoeweb/images/zoe-logo.svg",
        boardContentBg: "#f4f4f4",
        msgBubbleBgBot: "#fff",
        inputPlaceholder: "Type your message here",
        inputDisableBg: "#fff",
        inputDisablePlaceholder: "Hit the buttons above to respond",
        botTitle: "Powered by Zoe 2.0",
      },
      messageData: [],
      selectedIndex: null,
      assetErrorFlg: false,
      tempFileName: null,
      fileEditNameDialog: false,
      tempAssetEditId: null,
      randomText: "",
      makeEdit: true,
      selectedItem: "Chat with Data",
      radios: null,
      items: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
        },
      ],
      channelList: [
        {
          text: "Blog",
          icon: "mdi-post-outline",
          iconColor: "#1eb375",
          isOpen: true,
        },
        {
          text: "LinkedIn",
          icon: "mdi-linkedin",
          iconColor: "#007ebd",
          isOpen: true,
        },
        {
          text: "Twitter",
          icon: "mdi-twitter",
          iconColor: "#00a9ef",
          isOpen: true,
        },
        {
          text: "Chat with Data",
          icon: "mdi-message-outline",
          iconColor: "#00a9ef",
          isOpen: true,
        },
      ],
      searchTerm: "",
      productList1: [{ id: 1, name: "Gecko" }],
      linkedInPost: [],
      linkedInMessage: null,
      linkedInimage: null,
      EditPostBox: false,
      LinkedinPostBox: true,
      fullLinkedinImage: false,
      isLinkedInLoading: false,
      tempLinkedInMsg: null,
      tempLinkedInimage: null,
      tempImgChangeLoader: false,
      companyName: null,
      tempImg: null,
      showMorePostLoader: true,
      contentType: null,
      contentName: null,
      selectedProduct: null,
      selectedProduct1: 1,
      productName: null,
      icon: "mdi-bookmark-outline", // Initial icon
      iconColor: "#333",
      EmailId: null,
      userAssetList: [],
      openLeftPanel: false,
      faqList: [],
      assetList: [],
      selectedBot: null,
      activeIndex: 0,
      editedAssetId: 0,
      sessionID: null,
      viwedResource: null,
      selectedType: "Overall Summary",
      showAutoComplete: false,
      summaryText: "",
      categories: [
        'Enterprise Search',
        'Enterprise GenAI',
        'Google References',
        'Patent Database',
        'Scientific Journals',
        'Industry Publications'
      ],
      //summaryListingSheet: false,
      waitTimeMessages: [
        "Analyzing data to identify the best solution for your needs.",
        "Our AI is caffeinated and working overtime to find your perfect match.",
        "Shhh... the AI is concentrating. Don't want to break its focus!",
        "Need a tech break? Here's a fun fact: The first computer mouse was made of wood!",
        "We're not making you wait, we're building suspense!"
      ],

      waitTimeMessage: null,
      waitingTimeLoader: false,
      waitCountMessage: "Expected wait time is 2 mins.",
      adminEmails: [
        'sid@webspiders.com',
        'dipesh.majumder@webspiders.com',
        'debottam.bhatt@webspiders.com',
        'suraj.agarwal@webspiders.com',
        'kaushik.roy@webspiders.com',
        'sayan2.roy@webspiders.com',
        'niladri.dasgupta@webspiders.com',
        'debayan.ghosh@webspiders.com',
        'sayeksultan.chowdhury@webspiders.com'
      ],
      clientEmail: null,
      showSuccess: false,
      resultTab: 0,
      dialogKey: 0,
      techNeedAnswer: {
        internal_tech_offers: null,
        external_tech_offers: null
      }
    };
  },
  computed: {

    tableHeight() {

      const windowHeight = window.innerHeight;
      const containerHeight = 357;
      const newTableHeight = (windowHeight - containerHeight);

      return newTableHeight;

      // let successHeight = 0;
      // if (this.showSuccess) {
      //   successHeight = 50;
      // }
      // if (window.innerWidth > 1401 && window.innerWidth < 1499) {
      //   return (window.screen.height / 2) - 20;
      // } else if (window.innerWidth > 1301 && window.innerWidth < 1400) {
      //   return (window.screen.height / 2) - 70;
      // }else if (window.innerWidth > 1201 && window.innerWidth < 1300) {
      //   return (window.screen.height / 2) - 40;
      // } else {
      //   return (window.screen.height / 1.6);
      // }
    },
    filteredOptions() {
      return this.options.filter(option =>
        option.toLowerCase().includes(this.searchPrompt.toLowerCase())
      );
    },
    filteredResources() {
      console.log("res======", this.resourceList);

      return this.resourceList;
    },
    formattedViewContent() {
      return this.viewAssetContent.replace(/\n/g, '<br/>');
    },
    formattedViewResourceContent() {
      return this.viwedResource ? this.viwedResource.replace(/\n/g, '<br/>') : '';
    },
    filteredAssetList() {
      var flg = false;
      this.assetList.map((element, index) => {
        if (element.IsChecked) {
          console.log("element====");
          flg = true;
        } else {
          console.log("element==2==");
          flg = false;

        }
      });
      // if (!flg) {
      //     console.log("flg====");
      //     if(this.assetList && this.assetList.length > 0){
      //       this.assetList[0].IsChecked = true;
      //     }
      //   }

      var searchTxt = this.searchTerm ? this.searchTerm.toLowerCase() : ''
      return this.assetList.filter((asset) =>
        asset.assetName.toLowerCase().includes(searchTxt)
      );
    },
  },
  created() {
    localStorage.removeItem("linkedInPostList");
    this.$bus.on("blogData", (data) => {
      console.log("blogData==in bus==", data);
      this.linkedInMessage = data.message;
      this.linkedInimage = data.image;
    });
    this.$bus.on("linkedinData", (data) => {
      console.log("linkedinData==in bus==", data);
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  mounted() {
    if (screen.width >= 320 && screen.width <= 767) {
      this.viewMobile = false;
    } else if (screen.width >= 768 && screen.width <= 1200) {
      this.viewMobile = true;
    }
    if (this.$route.query.param1 == "r") {
      this.$router.push({
        path: "/chatwithdata",
        query: {},
      });
      location.reload();
    }
    if (localStorage.getItem("latestAssetId")) {
      this.activeIndex = localStorage.getItem("latestAssetId");
    }
    if (localStorage.getItem("latestBotId")) {
      this.selectedBot = parseInt(localStorage.getItem("latestBotId"));
    }
    if (localStorage.getItem("emailId")) {
      this.clientEmail = localStorage.getItem("emailId");
    }
    // this.sessionID = this.getSessionID()

    // // If no session ID is found, generate a new one and store it in a cookie
    // if (!this.sessionID) {
    //   this.sessionID = this.generateSessionID()
    //   this.setSessionCookie(this.sessionID)
    // }
    //this.getFAQ();

    this.getAssetList();
    this.getMyBots();
    /*document.addEventListener('click', this.handleClickOutside);
 
    let el = document.querySelector('.box-right .wrapper-post');
    el.addEventListener("scroll", (event) => {
      //console.log('scroll')
      el.closest('.single-post').classList.add('highlight')
    })*/

    this.getAllPromptData()
    window.techNeedsInterval = setInterval(() => {
      this.getAllPromptData()
    }, 10000)
  },
  updated() {
    //this.scrollToBottom();
  },
  methods: {
    promptInjection(injectObj) {
      console.log("***********injectObj", injectObj)
      let { name, ...data } = injectObj
      let injectedPrompt = this.promptTemplate[name];

      for (let property in data) {
        injectedPrompt = injectedPrompt.replace(`{{${property}}}`, data[property])
      }
      console.log("***********injectedPrompt", injectedPrompt)
      this.finalPrompt = injectedPrompt
      this.setTextToImg()
      // return injectedPrompt
    },
    async fetchPrompts(assetId) {
      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(assetId),
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response===view====", response)
        if (response.data.status == 0) {
          const selectedBotContext = response.data.data.asset.cleanText
          // console.log("selectedBotContext*******************", selectedBotContext)
          this.promptInjectObject['context'] = selectedBotContext
          // this.promptInjectObject['name'] = ''
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    selectImage(imageUrl) {
      console.log("selected image url")
      this.selectedImageUrl = imageUrl;
    },
    makePrompt() {
      console.log("prmopt====", this.chatResponses[0].text);
      if (this.chatResponses[0].text.trim() == 'Create a French bar menu artwork in a realistic or painterly style.') {
        this.finalPrompt = `Context: 

Brand Identity:
Luxury and Sophistication: Bollinger is positioned as a high-end Champagne brand associated with elegance and prestige.
Heritage and Tradition: Established in 1829, Bollinger emphasizes its long history and commitment to quality.
Boldness and Complexity: Bollinger Champagnes are known for their full-bodied style and richness, often described as powerful and complex.
Dedication to Pinot Noir: Bollinger uses a high proportion of Pinot Noir grapes in their blends, contributing to their distinctive character.
Visual Communication:
Color Palette: Black, gold, and white are prominent colors used in their branding, reflecting the luxury and sophistication.
Logo: The Bollinger logo features a bold eagle with a shield, symbolizing strength and heritage.
Imagery: Visuals often showcase the Champagne-making process, vineyards, and celebratory moments associated with enjoying Bollinger.
Minimalism and Elegance: Bollinger's visual communication tends to be elegant and minimalist, focusing on clean lines and high-quality imagery.
Brand Voice:
Confident and Aspirational: The brand voice conveys confidence and exclusivity, appealing to those who appreciate the finer things in life.
Timeless and Enduring: Messaging emphasizes the enduring quality and heritage of Bollinger Champagne.
Focus on Experience: The focus is on creating a luxurious and celebratory drinking experience.
Here are some additional insights you might find helpful:
Sustainability: Bollinger has recently achieved B Corp certification, highlighting their commitment to sustainable practices.
Partnerships: The brand is associated with prestigious events and collaborations that reinforce its image of luxury and excellence


Background color:
Color: Deep brown
Texture: Rough canvas

Print Design Menu Material:
Material: Weathered parchment
Level of Wear: Slightly worn

Composition:
Aspect Ratio: Portrait (2:3)
Layout: Classic bi-fold

Decorative Element:
Type: Premium Wine
Color Palette: Muted greens and purples

Font Style:
Script for Titles: Elegant cursive
Font for Descriptions: Classic serif

Generate Text on the artwork: NO

Additional Instructions:
Number of Sections: "Boissons" (Drinks), "Vins" (Wines), and "Cocktails"
Overall Mood: Vintage and classy
Color Scheme: Harmonious, with design elements complementing the background color.
Error Handling:
If none of the background color options appeal, you can self-pick a preferred color scheme.
Specify a number between 2 and 4 for the number of drink sections. Values outside this range will be ignored.
If the generated image doesn't meet your expectations, refine the prompt with more details and try again. 
Output as below:
Create a French bar menu artwork in a realistic or painterly style.
`;

        this.setTextToImg();
      } else if (this.chatResponses[0].text.trim() == 'Create a modern cocktail bar menu artwork with a contemporary, artistic flair.') {
        this.finalPrompt = `Context: <........>

Background color:
Color: Light, abstract with mixed colors
Texture: Smooth and clean

Print Design Menu Material:
Material: Glossy cardstock
Level of Wear: New and pristine

Composition:
Aspect Ratio: Various (Portrait for the main menu, vertical layout for the smartphone version)
Layout: Single-page for the main menu, single drink per page for the smartphone version

Decorative Element:
Type: Modern abstract art
Color Palette: Bright and varied, with an emphasis on contrasting colors

Font Style:
Script for Titles: Modern sans-serif
Font for Descriptions: Clean and readable sans-serif

Generate Text on the artwork: NO

Additional Instructions:
Number of Sections: Multiple drink entries, each with an image and description
Overall Mood: Trendy and eye-catching
Color Scheme: Vibrant and dynamic, with design elements complementing the artwork
Note: Include placeholder text ("Lorem ipsum") and drink images. Ensure the design is suitable for both printed menus and digital displays (e.g., smartphone screens).
Error Handling:
If none of the background color options appeal, you can self-pick a preferred color scheme.
Specify a number between 2 and 4 for the number of drink sections. Values outside this range will be ignored.
If the generated image doesn't meet your expectations, refine the prompt with more details and try again. 
Output as below:
Create a modern cocktail bar menu artwork with a contemporary, artistic flair.

`;

        this.setTextToImg();
      } else if (this.chatResponses[0].text.trim() == 'Create a sophisticated cocktail bar menu artwork with a modern and elegant design.') {
        this.finalPrompt = `Context: 
	Brand Identity
Lively and Vibrant: Bacardi embodies a sense of fun, energy, and celebrating life's moments.
Caribbean Heritage: The brand proudly references its Cuban roots and Caribbean heritage.
Premium Quality: Despite the lively image, Bacardi emphasizes the quality of its rum and spirits.
Diversity and Inclusion: Bacardi promotes inclusivity and enjoying life with people from all walks of life.
Visual Communication
Color Palette: Red and white are the primary colors, representing boldness, passion, and purity. Additional colors like black and gold might be used in specific contexts.
Logo: The iconic Bacardi logo features a stylized bat device, a symbol of good luck in Cuban culture.
Imagery: Visuals often showcase tropical settings, cocktails, parties, and diverse groups of people enjoying Bacardi products.
Bold and Energetic Style: The visual communication tends to be bold, colorful, and energetic, reflecting the brand's personality.
Brand Voice
Uplifting and Inviting: The brand voice is energetic and inviting, encouraging people to embrace life's moments.
Authentic and Real: Bacardi strives for an authentic and relatable tone in its communication.
Focus on Celebration: The focus is on creating a sense of celebration and enjoyment associated with Bacardi drinks.


Background color:
Color: Light neutral tones (e.g., beige, cream)
Texture: Smooth and clean

Print Design Menu Material:
Material: High-quality cardstock
Level of Wear: New and pristine

Composition:
Aspect Ratio: Various (portrait and vertical layouts)
Layout: Single-page and tri-fold designs

Decorative Element:
Type: Minimalist icons and geometric patterns
Color Palette: Earthy greens, soft oranges, and subtle golds

Font Style:
Script for Titles: Modern sans-serif
Font for Descriptions: Clean and readable serif

Generate Text on the artwork: NO

Additional Instructions:
Number of Sections: Multiple drink entries, each with an image and description
Overall Mood: Chic and refined
Color Scheme: Harmonious and elegant, with design elements complementing the light background
Note: Include placeholder text ("Lorem ipsum") and drink images. Ensure the design is suitable for both printed menus and various display formats.
Error Handling:
If none of the background color options appeal, you can self-pick a preferred color scheme.
Specify a number between 2 and 4 for the number of drink sections. Values outside this range will be ignored.
If the generated image doesn't meet your expectations, refine the prompt with more details and try again. 
Output as below:
Create a sophisticated cocktail bar menu artwork with a modern and elegant design.


`;

        this.setTextToImg();
      } else if (this.chatResponses[0].text.trim() == 'Vibrant cocktail bar menu artwork with a playful design.') {
        this.finalPrompt = `Context: 
	Brand Identity: Carlsberg positions itself as a global brand offering a refreshing and enjoyable beer experience. It emphasizes quality, tradition, and a sense of community.
Visual Communication:
Color Palette: Red and white are the primary colors, representing boldness, passion, and purity. Secondary colors like gold and black can be used for accents.
Logo: The Carlsberg logo features a stylized hop cone, a symbol of brewing, and the Carlsberg name in a bold, clean typeface.
Imagery: Visuals often showcase the brewing process, Carlsberg's global reach, and moments of shared enjoyment with friends.
Typography: The brand uses a specific font family called "Carlsberg Sans" for a consistent and modern look.
Brand Voice:
Friendly and Approachable: The brand voice aims to be friendly, inviting, and inclusive, positioning Carlsberg as a beer for all occasions.
Global Appeal: Messaging often emphasizes the brand's international presence and caters to a diverse audience.
Focus on Quality and Enjoyment: The focus is on promoting the quality of the beer and the positive experience of enjoying it with others.

Background color:
Color: Soft pastels (e.g., peach, light pink, beige)
Texture: Smooth and clean
Print Design Menu Material:
Material: Matte cardstock
Level of Wear: New and pristine
Composition:
Aspect Ratio: Various (portrait and vertical layouts)
Layout: Single-page and tri-fold design bar menu
Decorative Element:
Type: Retro illustrations and geometric patterns
Color Palette: Soft pastels with pops of vibrant colors
Font Style:
Script for Titles: Bold sans-serif
Font for Descriptions: Simple and clean sans-serif
Additional Instructions:
Number of Sections: Multiple drink entries, each with an image and description
Overall Mood: Fun and nostalgic
Color Scheme: Harmonious and playful, with design elements complementing the pastel background
Error Handling:
If none of the background color options appeal, you can self-pick a preferred color scheme.
Specify a number between 2 and 4 for the number of drink sections. Values outside this range will be ignored.
If the generated image doesn't meet your expectations, refine the prompt with more details and try again. 
Note: Include placeholder text ("Lorem ipsum") and drink images. Ensure the design is suitable for both printed menus and various display formats.
Output as below:
Vibrant cocktail bar menu artwork with a playful design


`;

        this.setTextToImg();
      } else {
        this.finalPrompt = `Context: 
	Brand Identity: Carlsberg positions itself as a global brand offering a refreshing and enjoyable beer experience. It emphasizes quality, tradition, and a sense of community.
Visual Communication:
Color Palette: Red and white are the primary colors, representing boldness, passion, and purity. Secondary colors like gold and black can be used for accents.
Logo: The Carlsberg logo features a stylized hop cone, a symbol of brewing, and the Carlsberg name in a bold, clean typeface.
Imagery: Visuals often showcase the brewing process, Carlsberg's global reach, and moments of shared enjoyment with friends.
Typography: The brand uses a specific font family called "Carlsberg Sans" for a consistent and modern look.
Brand Voice:
Friendly and Approachable: The brand voice aims to be friendly, inviting, and inclusive, positioning Carlsberg as a beer for all occasions.
Global Appeal: Messaging often emphasizes the brand's international presence and caters to a diverse audience.
Focus on Quality and Enjoyment: The focus is on promoting the quality of the beer and the positive experience of enjoying it with others.

Background color:
Color: Soft pastels (e.g., peach, light pink, beige)
Texture: Smooth and clean
Print Design Menu Material:
Material: Matte cardstock
Level of Wear: New and pristine
Composition:
Aspect Ratio: Various (portrait and vertical layouts)
Layout: Single-page and tri-fold design bar menu
Decorative Element:
Type: Retro illustrations and geometric patterns
Color Palette: Soft pastels with pops of vibrant colors
Font Style:
Script for Titles: Bold sans-serif
Font for Descriptions: Simple and clean sans-serif
Additional Instructions:
Number of Sections: Multiple drink entries, each with an image and description
Overall Mood: Fun and nostalgic
Color Scheme: Harmonious and playful, with design elements complementing the pastel background
Error Handling:
If none of the background color options appeal, you can self-pick a preferred color scheme.
Specify a number between 2 and 4 for the number of drink sections. Values outside this range will be ignored.
If the generated image doesn't meet your expectations, refine the prompt with more details and try again. 
Note: Include placeholder text ("Lorem ipsum") and drink images. Ensure the design is suitable for both printed menus and various display formats.
Output as below:
Vibrant cocktail bar menu artwork with a playful design


`;

        this.setTextToImg();
      }
    },

    scrollToBottom() {
      const container = this.$refs.chatContainer
      // console.log("container===", container);
      if (container) {
        container.scrollIntoView({ behavior: "smooth", block: "end" })
      }
    },
    sendChatMsg() {
      if (this.chatResponses.length <= 4) {
        if (this.chatResponses.length == 2) {
          this.promptInjectObject['userPrompt'] = this.searchPrompt
          this.promptInjectObject['bgBackground'] = this.userTypedMsg
          console.log("..............promptInjectObject", this.promptInjectObject)
          this.chatResponses.push({
            type: 'human',
            text: this.userTypedMsg
          });
          // this.scrollToBottom();
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
        if (this.chatResponses.length == 3) {
          console.log("enter===")
          this.chatResponses.push({
            type: 'bot',
            text: 'Will there be any Decorative Element?',
            query: 'texture'
          });
          // this.scrollToBottom();
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
        else if (this.chatResponses.length == 4) {
          this.promptInjectObject['decorativeElements'] = this.userTypedMsg
          console.log("..............promptInjectObject", this.promptInjectObject)
          this.chatResponses.push({
            type: 'human',
            text: this.userTypedMsg
          });
          // this.makePrompt();
          this.promptInjection(this.promptInjectObject)
          // this.scrollToBottom();
          this.$nextTick(() => {
            this.scrollToBottom();
          });
          setTimeout(() => {
            this.showChatPrompt = false;
            this.showSuccess = true;
            this.chatResponses.length = 0
          }, 2000);
        }
      } else {
        this.showChatPrompt = false;
      }
      this.userTypedMsg = ''
    },
    showChatBox() {
      this.chatResponses.push({
        type: 'human',
        text: this.searchPrompt
      });
      this.showSearchPrompt = false;
      this.showChatPrompt = true;
      // this.filteredAssetList.

      this.filteredAssetList.map(async (element, index) => {
        if (element.IsChecked) {
          console.log("element====", element.assetId);
          // flg = true;
          await this.fetchPrompts(element.assetId)
          this.promptInjectObject['name'] = element.assetName
        }
      });
      setTimeout(() => {
        this.chatResponses.push({
          type: 'bot',
          text: 'What will be the Background color?',
          query: 'backgroundColor'
        });

        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }, 1000)
    },
    async setTextToImg() {
      //this.imageToViews = [];
      this.getAllPromptData();
      const headers = {
        'Content-Type': 'application/json',
        "zoepy-api-key": `D}ecZ&LQge1L3-vk>eT&.H)rU+T=+l)o`,
      };

      await axios.post("https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/get-image-from-text", { userInput: this.searchPrompt, promptText: this.finalPrompt, botId: parseInt(localStorage.getItem("latestBotId")), imageCount: 3, }, { headers }).then(response => {
        console.log("response==ggg===", response)
        if (response.data.data && response.data.data.status == 0) {

          //this.imageToViews = response.data.data;
        }

        this.finalPrompt = "";
        // this.getTechNeeds()
        // this.showSuccess = true
      }).catch(err => { });

    },


    openViewIdeas(value) {
      console.log("value===", value.urls);
      this.tempGalleryImages = value.urls
      this.selectImage(this.tempGalleryImages[0]);
      this.imageGalleryResults = true;
    },
    openSingleImage() {
      this.singleImageResult = true;
    },
    copyToText(textToCopy) {
      navigator.clipboard
        .writeText(textToCopy.trim())
        .then(() => {
          console.log("Text copied to clipboard");
          this.copiedText = textToCopy;
          this.copyButtonTitle = 'Copied';
          setTimeout(() => {
            this.copyButtonTitle = 'Copy the query';
          }, 2000); // Reset the title after 2 seconds
        })
        .catch((error) => {
          console.error("Unable to copy text: ", error);
        });
    },
    tectNeedsResultClose() {
      this.tectNeedsResult = false;
      // Increment dialog key to force Vue to re-render dialog component
      this.dialogKey++;

    },
    formatDate(date) {
      return moment(date).fromNow();
    },
    // summaryDialogOpen(){
    //   this.summaryListingSheet = true;
    // },
    getStatusIcon(status) {
      switch (status) {
        case 'Finished':
          return 'mdi-check-circle-outline';
        case 'Initializing':
          return 'mdi-timer-sand';
        case 'Vectorizing Data':
          return 'mdi-timer-sand';
        case 'Analyzing Query':
          return 'mdi-timer-sand';
        case 'Enriching context':
          return 'mdi-timer-sand';
        case 'Vector matching':
          return 'mdi-timer-sand';
        case 'Searching for Matches':
          return 'mdi-timer-sand';
        case 'Enhancing with LLM':
          return 'mdi-timer-sand';
        case 'Finalizing':
          return 'mdi-timer-sand';
        case 'Awating queue':
          return 'mdi-alert-circle-outline';
        default:
          return 'mdi-help-circle';
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'Finished':
          return 'green';
        case 'Initializing':
          return 'orange';
        case 'Vectorizing Data':
          return 'orange';
        case 'Analyzing Query':
          return 'orange';
        case 'Enriching context':
          return 'orange';
        case 'Vector matching':
          return 'orange';
        case 'Searching for Matches':
          return 'orange';
        case 'Enhancing with LLM':
          return 'orange';
        case 'Finalizing':
          return 'orange';
        case 'Awating queue':
          return 'red';
        default:
          return 'black';
      }
    },
    getStatusClass(status) {
      switch (status) {
        case 'Awating queue':
          return 'queue';
        case 'Initializing':
          return 'initializing';
        case 'Vectorizing Data':
          return 'vectorizing';
        case 'Analyzing Query':
          return 'analyzing';
        case 'Enriching context':
          return 'enriching';
        case 'Vector matching':
          return 'vecMaching';
        case 'Searching for Matches':
          return 'searching';
        case 'Enhancing with LLM':
          return 'enhancing';
        case 'Finalizing':
          return 'finalizing';
        case 'Finished':
          return 'finish';
        default:
          return '';
      }
    },
    handleClickOutside(event) {
      if (!this.$refs.autocompleteContainer.contains(event.target)) {
        this.showAutoComplete = false;
      }
    },
    setCategory(type) {
      if (this.showBoxLoader || this.waitingTimeLoader) return false
      this.selectedType = type;
      this.searchOffers();

    },
    overallCategory(type) {
      this.selectedType = type;
      //this.overallOffers();
      this.overAllOffersSummary()
    },

    async overallOffers() {
      this.summaryText = '';
      this.techOfferAssetIds = [];
      this.showBoxLoader = true;
      this.overallTechOffers = [];
      var data = {};
      try {
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        // if (this.selectedType == 'Enterprise Search') {
        data = {
          "tech_need": this.searchPrompt
        };
        // } 

        // else {
        //   data = {
        //     "tech_need": this.searchPrompt,
        //     "prompt_category": this.selectedType,
        //     "context": this.concatOffers
        //   };
        // }

        const response = await axios.post(APIs.externalTechOffers, data, { headers });

        console.log("ai output=====overall", response);

        if (response.data.status == 0) {



          for (let i = 0; i < this.categories.length; i++) {
            //await this.searchOffersByCategory(this.categories[i]);
            console.log('print', this.categories[i]);


            try {
              const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
              };

              data = {
                "tech_need": this.searchPrompt,
                "prompt_category": this.categories[i],
                "context": this.concatOffers
              };

              const response = await axios.post(APIs.searchTechOffers, data, { headers });
              console.log("ai output=====loop", response);

              // if (response.data.status == 0) {
              //   if (this.selectedType == 'Enterprise Search') {
              //     this.concatOffers = '';
              //     this.processedExternalTechOffers = [];
              //     if (response.data.data[0] && response.data.data[0].value && response.data.data[0].value.ipi_tech_offers) {
              //       this.techOfferAssetIds = response.data.data[0].value.ipi_tech_offers.assetIds;

              //       this.summaryText = '';

              //       this.getSummaryTechOffer();

              //       response.data.data[0].value.ipi_tech_offers.docs.map((offer) => (this.processedExternalTechOffers.push({
              //         assetId: offer.assetId,
              //         assetName: offer.assetName,
              //         source: offer.source,
              //         description: offer.textChunks[0].text,
              //       }), this.concatOffers = this.concatOffers + offer.textChunks[0].text))
              //     }
              //   } else if (this.selectedType == 'Industry Publications') {
              //     this.processedExternalTechOffers = [];
              //     if (response.data && response.data.external_tech_offers) {
              //       if (Array.isArray(response.data.external_tech_offers)) {
              //         response.data.external_tech_offers.map((offer) => (this.processedExternalTechOffers.push({
              //           assetId: offer.priority_score,
              //           assetName: offer.Industry_Publications_name + ' By ' + offer.Industry_Publications_holder_name,
              //           source: offer.Industry_Publications_website_url,
              //           description: offer.description,
              //           title: offer.title
              //         })))
              //       } else {
              //         this.processedExternalTechOffers.push({
              //           assetId: response.data.external_tech_offers.priority_score,
              //           assetName: response.data.external_tech_offers.Industry_Publications_name + ' By ' + response.data.external_tech_offers.Industry_Publications_holder_name,
              //           source: response.data.external_tech_offers.Industry_Publications_website_url,
              //           description: response.data.external_tech_offers.description,
              //           title: response.data.external_tech_offers.title
              //         })
              //       }

              //     }
              //   } else {
              //     if (response.data && response.data.external_tech_offers) {
              //       this.processedExternalTechOffers = [];
              //       if (Array.isArray(response.data.external_tech_offers)) {
              //         response.data.external_tech_offers.map((offer, index) => (this.processedExternalTechOffers.push({
              //           assetId: index,
              //           assetName: offer.title ? offer.title : '',
              //           source: offer.source ? offer.source : '',
              //           description: offer.summary ? offer.summary : '',
              //           link: offer.link ? offer.link : '',
              //           title: offer.title
              //         })))
              //       } else {
              //         this.processedExternalTechOffers.push({
              //           assetId: 0,
              //           assetName: response.data.external_tech_offers.title ? response.data.external_tech_offers.title : '',
              //           source: response.data.external_tech_offers.source ? response.data.external_tech_offers.source : '',
              //           description: response.data.external_tech_offers.summary ? response.data.external_tech_offers.summary : '',
              //           link: response.data.external_tech_offers.link ? response.data.external_tech_offers.link : '',
              //           title: response.data.external_tech_offers.title
              //         })
              //       }

              //     }
              //   }
              //   this.showBoxLoader = false;
              // }
              // else {
              //   this.showBoxLoader = false;
              //   response.data.external_tech_offers.map((offer) => (this.processedExternalTechOffers.push({
              //     assetId: 0,
              //     assetName: '',
              //     source: '',
              //     description: '',
              //     title: offer.title
              //   })))
              // }

            } catch (error) {
              console.error('Error fetching asset content:', error);
            }
          }



          if (this.selectedType == 'Enterprise Search') {
            this.concatOffers = '';
            this.overallTechOffers = [];
            if (response.data.data[0] && response.data.data[0].value && response.data.data[0].value.ipi_tech_offers) {
              this.techOfferAssetIds = response.data.data[0].value.ipi_tech_offers.assetIds;

              this.summaryText = '';

              this.getSummaryTechOffer();

              response.data.data[0].value.ipi_tech_offers.docs.map((offer) => (this.overallTechOffers.push({
                assetId: offer.assetId,
                assetName: offer.assetName,
                source: offer.source,
                description: offer.textChunks[0].text,
              }), this.concatOffers = this.concatOffers + offer.textChunks[0].text))
            }
          } else if (this.selectedType == 'Industry Publications') {
            this.overallTechOffers = [];
            if (response.data && response.data.external_tech_offers) {
              if (Array.isArray(response.data.external_tech_offers)) {
                response.data.external_tech_offers.map((offer) => (this.overallTechOffers.push({
                  assetId: offer.priority_score,
                  assetName: offer.Industry_Publications_name + ' By ' + offer.Industry_Publications_holder_name,
                  source: offer.Industry_Publications_website_url,
                  description: offer.description,
                  title: offer.title
                })))
              } else {
                this.overallTechOffers.push({
                  assetId: response.data.external_tech_offers.priority_score,
                  assetName: response.data.external_tech_offers.Industry_Publications_name + ' By ' + response.data.external_tech_offers.Industry_Publications_holder_name,
                  source: response.data.external_tech_offers.Industry_Publications_website_url,
                  description: response.data.external_tech_offers.description,
                  title: response.data.external_tech_offers.title
                })
              }

            }
          } else {
            if (response.data && response.data.external_tech_offers) {
              this.overallTechOffers = [];
              if (Array.isArray(response.data.external_tech_offers)) {
                response.data.external_tech_offers.map((offer, index) => (this.overallTechOffers.push({
                  assetId: index,
                  assetName: offer.title ? offer.title : '',
                  source: offer.source ? offer.source : '',
                  description: offer.summary ? offer.summary : '',
                  link: offer.link ? offer.link : '',
                  title: offer.title
                })))
              } else {
                this.overallTechOffers.push({
                  assetId: 0,
                  assetName: response.data.external_tech_offers.title ? response.data.external_tech_offers.title : '',
                  source: response.data.external_tech_offers.source ? response.data.external_tech_offers.source : '',
                  description: response.data.external_tech_offers.summary ? response.data.external_tech_offers.summary : '',
                  link: response.data.external_tech_offers.link ? response.data.external_tech_offers.link : '',
                  title: response.data.external_tech_offers.title
                })
              }

            }
          }
          this.showBoxLoader = false;
        } else {
          this.showBoxLoader = false;
          response.data.external_tech_offers.map((offer) => (this.overallTechOffers.push({
            assetId: 0,
            assetName: '',
            source: '',
            description: '',
            title: offer.title
          })))
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },

    async overAllOffersSummary() {
      if (!this.startWaitingLoader(10, 20, "Expected wait time is 2 mins {second} sec.")) return false;
      this.processedExternalTechOffers = [];
      const headers = {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };
      /*let allContext = [];
      for (let i = 0; i < this.categories.length; i++) {           
            let data = {
              "tech_need": this.searchPrompt,
              "prompt_category": this.categories[i],
              "context": ""
            };
 
            const res = await axios.post(APIs.searchTechOffers, data, { headers });
            //console.log(res.data.data[0])
            if(res.data.status == 0 && res.data.data[0].status == 'fulfilled'){
              for(let item of res.data.data[0].value.ipi_tech_offers.docs){
                for(let text of item.textChunks){
                  allContext.push(text.text)
                }
              }
            }
      }*/
      let fdata = {
        //context: allContext.toString().replace(/[^a-zA-Z ]/g, ""),
        tech_need: this.searchPrompt,
        prompt_category: "Overall Search",
        //llm: "anthropic",
        //format: "json"
      };


      const response = await axios.post(APIs.zoeTechOffers, fdata, { headers });
      //console.log(response.data)
      if (response.data.status == 0) {
        //response.data.external_tech_offers.forEach((offer, index)=>{
        this.processedExternalTechOffers.push({
          assetId: 1,
          assetName: 'Search Result',
          source: '',
          description: response.data.external_tech_offers.replace("`", "").replace("html", ""),
          type: 'html',
          link: '',
          title: ''
        })
        //})
      }
      this.clearWaitingLoader()
    },
    viewMatches(item) {
      this.tempViewedMatch = item.queries;
      const headers = {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };

      axios.get(`${APIs.techNeeds}?id=${item.id}`, { headers }).then(response => {
        console.log("response====", response);

        this.techNeedAnswer = {
          internal_tech_offers: response.data.data[0].internal_tech_offers,
          external_tech_offers: response.data.data[0].external_tech_offers
        }
        this.tectNeedsResult = true;
      }).catch(err => { });

    },
    async setTechNeeds() {
      if (!this.searchPrompt) return false;
      const dataTableElement = document.querySelector('.search-result-table thead');
      if (dataTableElement) {
        dataTableElement.scrollIntoView();
      }
      const headers = {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      };

      await axios.post(APIs.techNeeds, { tech_need: this.searchPrompt }, { headers }).then(response => {
        this.searchPrompt = "";
        this.getAllPromptData()
        this.showSuccess = true
        this.chatResponses.length = 0
      }).catch(err => { });
    },
    getAllPromptData() {
      // Temporary array to hold the new data
      let updatedPrompts = [];

      const headers = {
        'Content-Type': 'application/json',
        'zoepy-api-key': 'D}ecZ&LQge1L3-vk>eT&.H)rU+T=+l)o',
      };
      axios.post("https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/get-all-generated-images-old", { botId: parseInt(localStorage.getItem("latestBotId")) }, { headers }).then(response => {
        console.log("response of get====", response);
        for (let newItem of response.data.data) {
          const existingItemIndex = this.prompts.findIndex(item => item.id === newItem.id);
          console.log("existingItemIndex====", existingItemIndex);
          if (existingItemIndex !== -1) {
            const existingItem = this.prompts[existingItemIndex];
            if (existingItem.status !== 'Finished') {
              this.prompts[existingItemIndex] = {
                ...existingItem,
                queries: newItem.prompt,
                status: newItem.urls && newItem.urls.length > 0 ? 'Finished' : 'Awating queue',
                id: newItem.id,
                updatedOn: newItem.date_created,
                urls: newItem.urls
              };
            }
            updatedPrompts.push(this.prompts[existingItemIndex]);
          } else {
            console.log("else====");
            updatedPrompts.push({
              queries: newItem.prompt,
              status: newItem.urls && newItem.urls.length > 0 ? 'Finished' : 'Awating queue',
              id: newItem.id,
              updatedOn: newItem.date_created,
              urls: newItem.urls
            });
          }
        }
        if (updatedPrompts.length > 0) {
          this.prompts = updatedPrompts;
        }
      }).catch(err => { });
    },

    onClick() {
      this.clicked = !this.clicked;
    },
    isLink(url) {
      return url.startsWith("https://");
    },
    async getSummaryTechOffer() {
      this.summaryText = '';
      try {
        const response = await axios.post(
          'https://zoepy-dev-dot-geckoai2023.ue.r.appspot.com/get-search-result-summary',
          {
            botId: 66,
            assetIds: this.techOfferAssetIds,
            searchText: this.searchPrompt
          },
          {
            headers: {
              'zoepy-api-key': 'D}ecZ&LQge1L3-vk>eT&.H)rU+T=+l)o',
              'Content-Type': 'application/json'
            }
          }
        );

        console.log("summary====", response.data);
        if (response.data.status == 0) {
          this.summaryText = response.data.data.summary;
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    },
    startLoading() {
      this.waitingTimeLoader = true
      window.waitTimeIntervalShow = setInterval(async () => {
        this.waitTimeMessage = null
        let timeoutID = await new Promise(resolve => setTimeout(resolve, 100));
        clearTimeout(timeoutID)
        let randomIndex = Math.floor(Math.random() * this.waitTimeMessages.length);
        this.waitTimeMessage = this.waitTimeMessages[randomIndex]
      }, 11000)
    },
    startCountLoading(second, message) {
      window.countTimeInterval = setInterval(() => {
        second = second - 1
        if (second > 0)
          this.waitCountMessage = message.replace('{second}', second)
        else {
          this.waitCountMessage = message.includes('min') ? "Expected wait time is 2 mins." : null
          if (window.countTimeInterval)
            clearInterval(window.countTimeInterval)
        }
      }, 2000, message, second)
    },
    startWaitingLoader(min, max, message) {
      if (this.searchPrompt == "" || !this.searchPrompt) return false;
      if (this.showBoxLoader || this.waitingTimeLoader) return false
      //this.showBoxLoader = false;
      this.waitTimeMessage = this.waitTimeMessages[0]
      let second = Math.floor(Math.random() * (min - max + 1) + max);
      this.waitCountMessage = message.replace('{second}', second)
      this.startCountLoading(second, message)
      this.startLoading()
      return true
    },
    clearWaitingLoader() {
      if (window.waitTimeIntervalShow)
        clearInterval(window.waitTimeIntervalShow)
      if (window.countTimeInterval)
        clearInterval(window.countTimeInterval)
      this.waitTimeMessage = null
      this.waitCountMessage = null
      this.waitingTimeLoader = false
    },

    async searchOffers() {
      this.summaryText = '';
      this.techOfferAssetIds = [];
      if (!this.startWaitingLoader(30, 40, "Expected wait time is {second} sec.")) return false;
      this.processedExternalTechOffers = [];
      var data = {};
      try {
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        if (this.selectedType == 'Enterprise Search') {
          data = {
            "tech_need": this.searchPrompt
          };
        } else {
          data = {
            "tech_need": this.searchPrompt,
            "prompt_category": this.selectedType,
            //"format": "json",
            //"context": this.concatOffers
          };
        }

        const response = await axios.post(this.selectedType == 'Enterprise Search' ? APIs.searchTechOffers : APIs.externalTechOffers, data, { headers });
        console.log("ai output=====", response);

        if (response.data.status == 0) {
          if (this.selectedType == 'Enterprise Search') {
            this.concatOffers = '';
            this.processedExternalTechOffers = [];
            if (response.data.data[0] && response.data.data[0].value && response.data.data[0].value.ipi_tech_offers) {
              this.techOfferAssetIds = response.data.data[0].value.ipi_tech_offers.assetIds;

              this.summaryText = '';

              this.getSummaryTechOffer();

              response.data.data[0].value.ipi_tech_offers.docs.map((offer) => (this.processedExternalTechOffers.push({
                assetId: offer.assetId,
                assetName: offer.assetName,
                source: offer.source,
                description: offer.textChunks[0].text,
                type: 'html',
              }), this.concatOffers = this.concatOffers + offer.textChunks[0].text))
            }
          } else if (this.selectedType == 'Industry Publications' && 1 > 1) {
            this.processedExternalTechOffers = [];
            if (response.data && response.data.external_tech_offers) {
              if (Array.isArray(response.data.external_tech_offers)) {
                response.data.external_tech_offers.map((offer) => (this.processedExternalTechOffers.push({
                  assetId: offer.priority_score,
                  assetName: offer.Industry_Publications_name + ' By ' + offer.Industry_Publications_holder_name,
                  source: offer.Industry_Publications_website_url,
                  description: offer.description,
                  title: offer.title,
                  type: 'html',
                })))
              } else {
                this.processedExternalTechOffers.push({
                  assetId: response.data.external_tech_offers.priority_score,
                  assetName: response.data.external_tech_offers.Industry_Publications_name + ' By ' + response.data.external_tech_offers.Industry_Publications_holder_name,
                  source: response.data.external_tech_offers.Industry_Publications_website_url,
                  description: response.data.external_tech_offers.description,
                  title: response.data.external_tech_offers.title,
                  type: 'html',
                })
              }

            }
          } else {
            if (response.data.status == 0 && response.data.external_tech_offers) {
              this.processedExternalTechOffers = [];
              if (Array.isArray(response.data.external_tech_offers)) {
                response.data.external_tech_offers.map((offer, index) => (this.processedExternalTechOffers.push({
                  assetId: index,
                  assetName: offer.title ? offer.title : '',
                  source: offer.source ? offer.source : '',
                  description: offer.summary ? offer.summary : '',
                  link: offer.link ? offer.link : '',
                  title: offer.title,
                  type: 'html',
                })))
              } else {
                this.processedExternalTechOffers.push({
                  assetId: 0,
                  assetName: 'Search Result',
                  source: '',
                  description: response.data.external_tech_offers ? response.data.external_tech_offers.replace("`", "").replace("html", "") : '',
                  link: '',
                  title: '',
                  type: 'html',
                })
              }

            }
          }
          this.showBoxLoader = false;
        } else {
          this.showBoxLoader = false;
          response.data.external_tech_offers.map((offer) => (this.processedExternalTechOffers.push({
            assetId: 0,
            assetName: '',
            source: '',
            description: '',
            title: offer.title
          })))
        }

        this.clearWaitingLoader()

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    goToPage(pageNumber) {
      console.log("page=====", pageNumber);

      this.currentPage = pageNumber;
      this.getAssetList();
    },
    viewDetailedResource(resource) {
      console.log("view clieked=====");

      this.viwedResource = resource;
      this.viewResourceDialog = true;

    },
    async updateAssetProperty() {
      this.updateAssetPropertLoader = true;
      try {
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          "assetId": this.tempAssetEditProperty.assetId,
          "name": this.tempAssetEditProperty.name,
          "annotation": this.tempAssetEditProperty.annotation,
          "source": this.tempAssetEditProperty.source
        };
        const response = await axios.post(APIs.updateAssetPropertiesAPI, data, { headers });
        console.log("response= update====", response);

        if (response.data.status == 0) {
          this.updateAssetPropertLoader = false;
          this.filePropertyEditDialog = false;
          this.tempAssetEditProperty = {};
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async getWelcomeMessage() {
      this.botTyping = true;
      try {
        const headers = {
          'accept': 'application/json',
        };
        const data = {
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };
        const response = await axios.post(APIs.getWelcomeMessageAPI, data, { headers });
        console.log("response=====", response);
        if (response.data.status == 0) {
          setTimeout(() => {
            this.botTyping = false;
            this.messageData.push({
              agent: "bot",
              type: "text",
              text: response.data.data.msg,
            });
          }, 1000);
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async getSpecificClipById(id) {
      try {
        const apiUrl = 'https://api.d-id.com/talks/' + id;
        const headers = {
          'accept': 'application/json',
          'authorization': 'Basic YzJGNVlXNHlMbkp2ZVVCM1pXSnpjR2xrWlhKekxtTnZiUTp5cFgwZmlIRVdDbm9Hd0pxbmZCcUc='
        };
        const response = await axios.get(apiUrl, { headers });
        console.log("response=====", response);

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async showVideoMessage(value) {
      var tempData = JSON.stringify({ "script": { "type": "text", "subtitles": false, "provider": { "type": "microsoft", "voice_id": "en-US-JennyNeural" }, "ssml": "false", "input": value }, "config": { "fluent": "false", "pad_audio": "0.0" }, "source_url": "https://i.pinimg.com/474x/e4/c5/9f/e4c59fdbb41ccd0f87dc0be871d91d98.jpg" });
      try {
        const apiUrl = 'https://api.d-id.com/talks';
        const headers = {
          'accept': 'application/json',
          'authorization': 'Basic YzJGNVlXNHlMbkp2ZVVCM1pXSnpjR2xrWlhKekxtTnZiUTp5cFgwZmlIRVdDbm9Hd0pxbmZCcUc=',
          'content-type': 'application/json'
        };

        const response = await axios.post(apiUrl, tempData, { headers });
        console.log("response======", response);
        if (response.status == 201) {
          this.getSpecificClipById(response.data.id);
        }


      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    generateSessionID() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const sessionIdLength = 20 // Adjust the length as needed
      let sessionId = ''

      for (let i = 0; i < sessionIdLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        sessionId += characters.charAt(randomIndex)
      }

      return sessionId
    },
    setSessionCookie(sessionId) {
      document.cookie = `sessionID=${sessionId}; path=/`
    },
    getSessionID() {
      const cookies = document.cookie.split('; ')
      for (const cookie of cookies) {
        const [name, value] = cookie.split('=')
        if (name === 'sessionID') {
          return value
        }
      }
      return null
    },
    async resetCurrentChat() {
      try {
        const apiUrl = APIs.clearContextAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };
        const response = await axios.post(apiUrl, data, { headers });
        console.log("response====", response)

        if (response.data.status == 0) {
          this.clearMsg();
          this.getWelcomeMessage();
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    selectAllAsset() {
      if (this.selectAllCheckBox) {
        this.assetList.map((element, index) => {
          element.IsChecked = true;
        });
      } else {
        const lastItemIndex = this.assetList.length - 1;
        this.assetList.map((element, index) => {
          if (index === lastItemIndex) {
            element.IsChecked = true;
          } else {
            element.IsChecked = false; // Uncheck all other items
          }
        });

        this.assetList.sort((a, b) => a.assetId - b.assetId)
      }

    },
    checkIfCheckBoxExist() {

      var flg = false;
      this.assetList.map((element, index) => {
        if (element.IsChecked) {
          console.log("element====");
          flg = true;
        } else {
          flg = false;

        }
        if (flg) {
          this.assetList[index].viewIconLoading = true;
        }

      });
    },
    async updateAsset() {
      this.updateAssetFlg = true;


      try {
        const apiUrl = APIs.editAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(this.editedAssetId),
          "textContent": this.editAssetContent,
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response=======", response)
        if (response.data.status == 0) {
          this.updateAssetFlg = false;
          this.fileEditNameDialog = false;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    changeSelectedBot(botId) {
      localStorage.setItem("latestBotId", botId);
      this.getMyBots()
      this.getAssetList();
      this.clearMsg();
      this.getWelcomeMessage();
    },
    async getMyBots() {
      //this.myBotList = [];
      try {
        const apiUrl = APIs.getMyBotsAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const response = await axios.get(apiUrl, { headers });
        if (response.data.status == 0) {
          this.myBotList = response.data.bots;
          this.myBotList.sort((a, b) => {
            const dateA = new Date(a.createdOn);
            const dateB = new Date(b.createdOn);

            // Compare in descending order
            return dateB - dateA;
          });
          // if(this.myBotList &&  this.myBotList.length == 1){
          //   localStorage.setItem("latestBotId", this.myBotList[0].botId);
          //   this.$router.push("/chatwithdata")
          // }

        }
      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async fetchAssetContent(assetId, index) {
      this.assetList[index].viewIconLoading = true;

      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(assetId),
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response===view====", response)
        if (response.data.status == 0) {
          if (screen.width >= 320 && screen.width <= 767) {
            this.viewMobile = false
          }
          this.assetList[index].viewIconLoading = false;

          this.viewAssetContent = response.data.data.asset.cleanText;
          this.fileViewAssetDialog = true;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    getAssetList() {
      this.assetList = [];
      //+ '&size=100' + '&page=' + this.currentPage
      axios
        .get(APIs.getAssetAPI + '?botid=' + parseInt(localStorage.getItem("latestBotId")) + '&size=50' + '&page=' + this.currentPage, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          console.log("response==asset====", response.data);
          if (response.data && response.data.status == 0) {
            response.data.assets.map((element, index) => {
              element.viewIconLoading = false;
              element.editIconLoading = false;
              element.editPropertiesIconLoading = false;
              if (index == 0) {
                element.IsChecked = true;
              }
              this.assetList.push(element)
            });
            const lastItemIndex = this.assetList.length - 1;
            this.assetList.map((element, index) => {
              if (index === lastItemIndex) {
                element.IsChecked = true;
              } else {
                element.IsChecked = true; // Uncheck all other items
              }
            });

            this.assetList.sort((a, b) => a.assetId - b.assetId)
            this.totalPages = response.data.totalPages;
            this.totalCount = response.data.totalCount;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    OpenLeft() {
      this.viewMobile = true;
    },
    getFAQ() {
      var self = this;
      var tempArr = [];
      tempArr.push(localStorage.getItem("latestAssetId"));

      var data = { assetIds: tempArr };

      // console.log(JSON.stringify(data))
      var config = {
        method: "post",
        url: APIs.faqAPI,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        },
        data: JSON.stringify(data),
      };
      axios(config).then(function (response) {
        console.log("faq====", response);

        if (response.data.status == 0) {
          self.faqList = response.data.data.FAQ;
        }
      });
    },

    msgSend(value) {
      // Push the user's message to board
      this.messageData.push({
        agent: "user",
        type: "text",
        text: value.text,
      });
      if (value.text.includes("Can you tell me about the factories of Vikram Solar") || value.text.includes("can you tell me about the factories of Vikram Solar") || value.text.includes("factories of Vikram Solar")) {
        this.botTyping = true;
        setTimeout(() => {
          this.botTyping = false;
          this.messageData.push({
            agent: "bot",
            type: "text",
            text: "<p>Vikram Solar has two factories located in India. One is located at Falta SEZ in Kolkata, West Bengal, and the other is located at Oragadam in Chennai, Tamil Nadu. Both of these factories are strategically located with access to ports, rail, and roads, facilitating both domestic and international operations.</p><a id='video1'><img alt='play video' src='https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/play_icon.svg?alt=media'/></a>",
            disableInput: false,
          });
        }, 1000);
      } else if (value.text.includes("Where are factories of VS") || value.text.includes("where are factories of VS") || value.text.includes("factories of VS")) {
        this.botTyping = true;
        setTimeout(() => {
          this.botTyping = false;
          this.messageData.push({
            agent: "bot",
            type: "text",
            text: "<p>Vikram Solar has two factories located in India. One is located at Falta SEZ in Kolkata, West Bengal, and the other is located at Oragadam in Chennai, Tamil Nadu. Both of these factories are strategically located with access to ports, rail, and roads, facilitating both domestic and international operations.</p><a id='video2'><img alt='play video' src='https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/play_icon.svg?alt=media'/></a>",
            disableInput: false,
          });
        }, 1000);
      }
      else if (value.text.includes("Where are your factories located") || value.text.includes("where are your factories located") || value.text.includes("factories located")) {
        this.botTyping = true;
        setTimeout(() => {
          this.botTyping = false;
          this.messageData.push({
            agent: "bot",
            type: "text",
            text: "<p>Vikram Solar has two factories located in India. One is located at Falta SEZ in Kolkata, West Bengal, and the other is located at Oragadam in Chennai, Tamil Nadu. Both of these factories are strategically located with access to ports, rail, and roads, facilitating both domestic and international operations.</p><a id='video3'><img alt='play video' src='https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/play_icon.svg?alt=media'/></a>",
            disableInput: false,
          });
        }, 1000);
      }
      else {
        this.getResponse(value);
      }



    },
    clearMsg() {
      this.messageData = [];
    },
    getResponse(value) {
      var self = this;
      // Loading
      this.botTyping = true;
      var tempArr = [];
      tempArr.push(localStorage.getItem("latestAssetId"));
      console.log("check===", this.assetList);
      var selectedAssets = [];
      this.assetList.map((element) => {
        if (element.IsChecked) {
          console.log("check ed===", element.assetId);
          selectedAssets.push(parseInt(element.assetId))
        }
      });
      console.log("selectedAssets===", selectedAssets);

      var data = { assetIds: selectedAssets, userQuery: value.text, botId: parseInt(localStorage.getItem("latestBotId")) };

      // console.log(JSON.stringify(data))
      var config = {
        method: "post",
        url: APIs.zoeChat,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        },
        data: JSON.stringify(data),
      };
      axios(config).then(function (response) {

        console.log("chat ====", response.data)
        if (response.data.status == 0) {
          if (response.data.data.vectors) {
            // self.resourceList = response.data.data.vectors;
            self.getVectorChunck(response.data.data.vectors);
          }
          self.messageData.push({
            agent: "bot",
            type: "text",
            text: response.data.data.reply,
            disableInput: false,
          });
          self.botTyping = false;
          //self.showVideoMessage(response.data.data.reply)
        }
      });
    },
    async getVectorChunck(vectors) {
      console.log("vectors=====", vectors);
      if (vectors && vectors.length > 0) {

        const ids = vectors.map(item => item.id);
        const scores = vectors.map(item => item.score);
        console.log("scores=====", scores);
        let roundedNumbers;
        if (scores && scores.length > 0) {
          roundedNumbers = scores.map(number => number.toFixed(5));

        }

        console.log("roundedNumbers=====", roundedNumbers);


        try {
          const apiUrl = APIs.getVectorChunckAPI;
          const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          };
          const data = {
            "vectorIds": ids,
          };

          const response = await axios.post(apiUrl, data, { headers });

          console.log("vector response=======", response.data.data)

          if (response.data.status == 0) {
            this.resourcelist = response.data.data;
          }
          let keyValuePairs = roundedNumbers.map((score, index) => {
            return {
              score: score,
              title: this.resourcelist[index]
            };
          });
          this.updateResList = keyValuePairs;
          console.log("vector resourcelist=======", this.resourcelist)

        } catch (error) {
          console.error('Error fetching asset content:', error);
        }
      }

    },
    getAssetListByProduct(productId) {
      console.log("pl===", productId);
      const apiUrl = APIs.getAssetAPI;
      const email = localStorage.getItem("emailId");
      var productid = productId;

      axios
        .get(apiUrl, {
          params: {
            email: email,
            productid: productid,
          },
        })
        .then((response) => {
          console.log("asset list======", response.data);
          this.userAssetList = [];

          if (response.data.length > 0) {
            if (this.selectedIndex) {
              this.activeIndex = this.selectedIndex;
            } else {
              this.activeIndex = response.data.length - 2;
            }
            // this.activeIndex = response.data.length - 2;
            this.userAssetList = response.data;
            this.userAssetList.sort((a, b) => b.id - a.id);

            this.userAssetList.map((element) => {
              if (localStorage.getItem("multiAssetIds")) {
                if (
                  localStorage.getItem("multiAssetIds").includes(element.id)
                ) {
                  element.IsChecked = true;
                } else {
                  element.IsChecked = false;
                }
              } else {
                element.IsChecked = false;
              }
            });
            // this.activeIndex = 0;
            //this.generatedMessage = response.data[0].summarygenerated;
            this.fileName = response.data[0].filename;
            this.fileSize = response.data[0].fileSize;
            localStorage.setItem("selectedFileName", this.fileName);
            localStorage.setItem("selectedFileType", response.data[0].filetype);
          } else {
            // this.openAssetPopup();
            // this.generatedMessage = null;
          }
        })
        .catch((error) => {
          console.error(error);

          // Handle any errors that occurred during the API call
        });
    },
    async editAssetProperties(assetId, index) {

      this.assetList[index].editPropertiesIconLoading = true;
      try {
        const apiUrl = APIs.getAssetPropertiesAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(assetId),
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("asset edit response===view====", response)

        if (response.data.status == 0) {
          this.tempAssetEditProperty.assetId = assetId;
          this.tempAssetEditProperty.annotation = response.data.data.annotation;
          this.tempAssetEditProperty.name = response.data.data.name;
          this.tempAssetEditProperty.source = response.data.data.source;

        }
        this.assetList[index].editPropertiesIconLoading = false;
        this.filePropertyEditDialog = true;

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async editAssets(assetId, index) {
      this.editIconLoading = true;
      this.assetList[index].editIconLoading = true;

      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(assetId),
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response===view====", response)
        if (response.data.status == 0) {
          if (screen.width >= 320 && screen.width <= 767) {
            this.viewMobile = false
          }
          this.editIconLoading = false;
          this.assetList[index].editIconLoading = false;
          this.assetList[index].editPropertiesIconLoading = false;
          this.editedAssetId = assetId;
          this.editAssetContent = response.data.data.asset.cleanText;
          this.fileEditNameDialog = true;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    scrollEventBG() {
      let el = document.querySelector('.box-right .wrapper-post');
      if (el.querySelectorAll('.single-post.tobe-highlighted').length) {
        console.log('leng')
        console.log(el.querySelector('.single-post.tobe-highlighted'))
        el.querySelector('.single-post.tobe-highlighted').classList.add('highlight')
      }
      el.addEventListener("scroll", (event) => {
        //console.log('scroll')      
        el.querySelectorAll('.single-post.tobe-highlighted').forEach(element => {
          element.classList.remove('highlight')
          let curPos = element.offsetTop;
          let screenHeight = el.offsetHeight;
          if (curPos < screenHeight)
            element.classList.add('highlight')
          else if (element.offsetHeight > screenHeight)
            element.classList.add('highlight')
        })
      })
    },
  },
  watch: {
    processedExternalTechOffers() {
      //this.scrollEventBG()
    },
    overallTechOffers() {
      //this.scrollEventBG()
    },
    showSuccess(val) {
      if (val) {
        setTimeout(() => {
          this.showSuccess = false;
          this.searchPrompt = '';
          this.showSearchPrompt = true;
        }, 5000);
      }
    }
  },
};
</script>

<style>
/* ------------------------------------------------------------------------------------- */



.gallery-result .v-image {
  border-radius: 16px;
}

/* ------------------------------------------------------------------------------------- */









/*Start Rating*/
.star-rating {
  display: flex;
  flex-direction: row-reverse;
  font-size: 1.5em;
  justify-content: space-around;
  padding: 0 .2em;
  text-align: center;
  width: 5em;
  margin-right: 15px;
}

.star-rating input {
  display: none;
}

.star-rating label {
  color: #ccc;
  cursor: pointer;
  margin-bottom: 0 !important;
}

.star-rating :checked~label {
  color: #f90;
}

.star-rating label:hover,
.star-rating label:hover~label {
  color: #fc0;
}

/*Start Rating*/
.single-post.active {
  opacity: 0.5;
}

.single-post.blue-shadow {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 255, 0.5);
}

.single-post.summary-tech-offer {
  padding: 20px;
  background: #fff6d8;
  border: 1px solid #ffd33e;
}

.single-post.summary-tech-offer h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #000;
}

.single-post.summary-tech-offer p {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 25px;
}

.post_placeholder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.post_placeholder h3 {
  width: 70%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  color: #d3d3d3;
  margin: 0;
}

.search_box .v-input__slot {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.autocomplete-wrap {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  left: 0;
  margin-top: 1px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 15px;
  z-index: 2;
}

.autocomplete-wrap ul {
  margin: 0;
  padding: 0;
}

.autocomplete-wrap ul li {
  display: block;
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #333;
  font-size: 1.06rem;
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.autocomplete-wrap ul li:hover {
  background: #f9f9f9;
}

button.gen-post.change,
button.summarize-trig.change {
  background: #000 !important;
}

.gen-post.change span.v-btn__content,
button.summarize-trig.change span.v-btn__content {
  color: #fff;
}

button.gen-post.change .v-btn__content span {
  background: #fff;
  color: #000;
}

.search-header {
  position: absolute;
  left: 0;
  top: 20px;
  z-index: 8;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-search-head {
  padding-top: 13px;
  margin-right: 15px;
}

.text-search-head h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #000;
  margin: 0;
}

.text-search-head p {
  margin: 0;
  text-align: right;
  font-size: 12px;
  font-family: "poppins", sans-serif;
  font-weight: 400;
  color: #333;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.text-search-head p span img {
  height: 16px;
  width: 78px;
  object-fit: contain;
}

button.gen-post {
  width: 100% !important;
  border-radius: 6px;
}

button.gen-post span.v-btn__content {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
  font-size: 1.1rem;
}

button.gen-post .v-btn__content span {
  display: inline-block;
  margin-left: 5px;
  height: 20px;
  width: 20px;
  background: #000;
  color: #fff;
  border-radius: 50px;
  line-height: 19px;
}

button.gen-post:before {
  display: none !important;
}

.search_box .v-text-field__details {
  display: none;
}

.search_box .v-input__slot {
  margin-bottom: 0;
}

.search_box .v-input__slot:before,
.search_box .v-input__slot:after {
  display: none;
}

.search_box .v-text-field__slot label {
  margin: 2px 0 0 !important;
  padding-left: 50px;
}

.search_box .v-input {
  margin: 0 !important;
  padding: 0 !important;
}

/* span.total-asset {
    position: absolute;
    right: 15px;
    top: 32px;
  } */
.asset-headers-hld {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination2 li {
  padding: 0 !important;
}

.wrapper-post {
  display: block;
  /* gap: 10px 10px; */
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 20px);
  padding: 15px;
}

.source-wrap {
  width: 100%;
  padding: 6px 15px;
  background: #fff;
}

.source-wrap p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.single-post {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  margin-bottom: 15px;
}

.post-image {
  width: 100%;
  height: 130px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0 0;
}

.single-post img {
  object-fit: cover;
}

.post-desc {
  padding: 10px;
}

.post-desc h4 {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  color: #333333;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
}

.post-desc p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #6c6c6c;
  margin: 0;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
}

span.tag-box {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  color: #333;
  padding: 6px;
  display: inline-block;
  background: #dfdfdf;
  border-radius: 6px;
  line-height: 13px;
  margin-bottom: 10px;
  /* margin-right: 10px */
}

/* --------------------------------- Searchview ---------------------------------- */
.post-generate-inner.regular {
  width: 75%;
  flex: 0 0 75%;
}

.search-view-hld {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}


.search-header {
  position: absolute;
  left: 0;
  top: 20px;
  z-index: 8;
}

.search_box {
  width: 60%;
  border: 1px solid #cbcbcb;
  background: #ffffff;
  border-radius: 50px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.search_box textarea {
  width: 94%;
  height: 55px;
  padding: 15px 15px 15px 50px;
  resize: none;
  min-height: 55px;
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ffffff url('../assets/Images/aipostgenerator/zoe-z-logo.png') no-repeat left 10px center / 30px;
  border-radius: 50px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 0 !important;
}

.search_box textarea:focus {
  outline: none;
}

.search-box-hld {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 134px;
  align-items: flex-start;
  height: calc(100vh - 198px) !important;
}

.box-left.narrow {
  width: 25%;
  margin-right: 15px;
  height: calc(100% - 20px);
}

.box-right.wide {
  width: 75%;
  height: calc(100% - 20px);
}

.box-head {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #333333;
  padding-left: 15px;
  padding-top: 15px;
}

.box-head h4 {
  font-size: 1.4rem;
}

.box-left.narrow .box-inner {
  background: #F6F6F5;
}

.box-inner {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 20px;
  padding: 10px;
  position: relative;
}

.box-inner textarea {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  color: #12023d;
  resize: none
}

.box-inner textarea:focus {
  outline: none;
}

/* --------------------------------- Searchview ---------------------------------- */
.floating-button {
  position: fixed;
  right: 26%;
  bottom: 7px;
  background: #7e0d10;
  padding: 12px 20px;
  border-radius: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.asset-list .v-messages {
  display: none;
}

.asset-list .select-all {
  margin-left: 24px;
}

.post-switch .v-text-field__details {
  display: none;
}

.post-switch .v-input__slot {
  margin: 0;
}

.edit-aset-view {
  width: 800px;
  height: 550px;
}

.text-area-edit {
  height: calc(100% - 32px);
  width: 100%;
}

.text-area-edit textarea {
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  padding: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.18 rem;
  line-height: 2.2 rem;
  border-radius: 15px;
  resize: none;
}

.text-area-edit textarea:active,
.text-area-edit textarea:focus {
  border-color: #7e0d10;
  outline: none;
}

.text-area-edit-property {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.form-control {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.text-area-edit-property div {
  margin-bottom: 15px;
}

.text-area-edit-property label {
  font-size: 16px;
  margin-bottom: 5px;
}

.text-area-edit-property input {
  width: 300px;
  /* Adjust the width as needed */
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 5px;
}

.pagination2 {
  display: flex;
  justify-content: center;
}

.pagination2 ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}

.pagination2 li {
  margin: 0 5px;
}


.pagination2 button {
  background-color: #000000;
  color: white;
  border: 1px solid #000000;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.pagination2 button:hover {
  background-color: #000000;
}

.pagination2 button.active {
  background-color: #e03434;
  border: 1px solid #e03434;
}

.waiting-msg-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 5000ms ease;
}

.slide-fade-leave-active {
  transition: all 5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(5px);
  opacity: 0;
}

.wait_time-Msg h4 {
  font-weight: 400;
  color: #333333;
  font-size: 36px;
}

.gif-area {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.wait_time-Msg p {
  color: #c1c1c1;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  margin: 0;
  position: relative;
  top: 80px;
}

.success-message {
  white-space: nowrap;
}
</style>