<template>
    <!-- HTML template for your component -->
    <div class="wrapper-tutorial-sec">
        <div class="tutorial-hld">
            <div class="ingest-data-hld">
                <h3>Start your EduGPT Conversational Bot Trial</h3>
                <h5>Ingest your Enterprise Data</h5>
                <div class="ingest-file-inputs">
                    <div class="file-area-ingest">
                        <span class="inserted-file"><label>Attach a file</label></span>
                        <input type="file"/>
                        <img src="@/assets/Images/aipostgenerator/insertfile.svg"/>
                    </div>
                    <div class="divider-or">
                        <p>Or</p>
                    </div>
                    <input type="url" name="" value="" placeholder="Website URL" class="url-ingest"/><br/>
                    <v-btn dark color="#4285F4" class="try-bot">Try Your Chatbot</v-btn>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
    methods: {
      
    },
    computed: {
      
    },
    watch: {
    
    },
    created() {
     
    },
    mounted() {
      
    },
    destroyed() {
     
    }
  };
  </script>
  
<style>
 .ingest-data-hld{width: 1084px; height: 631px; background: url(../assets/Images/aipostgenerator/bot-trial-back.jpg); overflow: hidden; border-radius: 20px; padding-top: 50px;}
 .ingest-data-hld h3 {color: #ffffff; font-size: 45px; margin-bottom: 0;}
 .ingest-data-hld h5 {font-size: 30px; color: #ffffff; font-weight: 500; margin-top: 10px; margin-bottom: 0;}
 .ingest-file-inputs {margin-top: 80px;}
 .file-area-ingest {width: 470px; margin: 0 auto; height: 60px; background: #ffff; position: relative; border-radius: 12px; display: flex; justify-content: space-between; align-items: center; padding: 0 25px;}
 .file-area-ingest input[type="file"] {position: absolute; height: 100%; width: 54px; overflow: hidden; right: 0; opacity: 0;cursor: pointer;}
 .inserted-file label {font-family: "Poppins", sans-serif; font-weight: 400; color: #333; margin: 0; font-size: 1rem;}
 .divider-or{margin: 20px 0;}
 .divider-or p{margin: 0; font-family: "Poppins", sans-serif; font-weight: 500; color: #ffffff; font-size: 1.125rem;}
 .url-ingest{width: 470px; margin: 0 auto; font-family: "Poppins", sans-serif; font-weight: 400; color: #000; height: 60px; font-size: 1rem; padding: 0 25px; background: #ffffff url(../assets/Images/aipostgenerator/url.svg) no-repeat right 25px center;border-radius: 12px;}
 button.try-bot {height: 60px !important; padding: 0 35px !important; margin-top: 60px;}
 .try-bot span.v-btn__content {font-family: "Poppins", sans-serif;font-weight: 500; font-size: 1.2rem; text-transform: capitalize;}
</style>