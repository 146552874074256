<template>
  <div class="post-generate-wrap">
    <div v-if="viewMobile" class="post-generate-channels left-panel-tab-hld">
      <v-btn @click="viewMobile = false" fab text class="close-left-buttn"><v-icon size="25px"
          color="#333">mdi-close</v-icon></v-btn>
      <h3 class="mt-0 mt-md-4 mb-0">Select Bot</h3>

      <div class="mt-5 mb-3 product-create">
        <div class="product-create-dropdown">
          <v-select v-model="selectedBot" :items="myBotList" label="Select" outlined item-text="botName"
            item-value="botId" color="#dde1e8" @change="changeSelectedBot(selectedBot)"></v-select>
        </div>
      </div>
      <ul class="add-assets-button">
        <!-- <li @click="$router.push('/upload-file')">
          <v-icon size="20" color="#000" class="mr-3">mdi-plus</v-icon>Add New
          Asset
        </li> -->
        <div class="two-btn">
          <v-btn color="primary" @click="openLoginPop()">Upload Document</v-btn>
          <v-btn color="primary" @click="openLoginPop()">Enter web URL</v-btn>
        </div>
      </ul>
      <div class="search-assets">
        <input type="text" name="" id="" placeholder="Search Assets..." v-model="searchTerm" />
      </div>

      <ul class="asset-list">

        <lable class="d-flex justify-content-start align-items-center select-all"><v-checkbox class="select-asset mt-0"
            v-model="selectAllCheckBox" @click="selectAllAsset()"></v-checkbox>Select All</lable>

        <li v-for="(asset, index) in filteredAssetList" :key="index"
          :class="{ 'active-tab': activeIndex == asset.assetId }"
          class="d-flex justify-content-between align-items-center">

          <!-- <v-tooltip right> -->
          <!-- <template v-slot:activator="{ on, attrs }"> -->
          <span>
            <v-checkbox class="select-asset mt-0" v-model="asset.IsChecked"></v-checkbox>
          </span>
          <!-- </template> -->
          <!-- <span>To edit this selection, click on the top right Back button</span> -->
          <!-- </v-tooltip> -->

          <div class="left-asset-name">
            <v-icon size="22px" color="#b1bac8" class="mr-1">mdi-file-document-outline</v-icon>

            <input type="text" v-model="asset.assetName" :disabled="makeEdit" v-if="asset.assetName"
              :id="'MakeFocus' + index" />
          </div>
          <v-btn title="View Asset" :loading="asset.viewIconLoading" fab x-small elevation="0" class="mr-2"
            @click="fetchAssetContent(asset.assetId, index)"><v-icon size="22px"
              color="#333">mdi-eye-outline</v-icon></v-btn>
          <v-btn title="Edit Asset" :loading="asset.editIconLoading" fab x-small
            @click="editAssets(asset.assetId, index);" elevation="0"><v-icon size="22px"
              color="#333">mdi-pencil</v-icon></v-btn>

          <v-btn title="Edit Asset Properties" :loading="asset.editPropertiesIconLoading" fab x-small
            @click="editAssetProperties(asset.assetId, index);" elevation="0"><v-icon size="22px"
              color="#333">mdi-view-dashboard-edit-outline</v-icon></v-btn>

        </li>
      </ul>

      <div class="pagination d-none">
        <ul>
          <li v-for="pageNumber in totalPages" :key="pageNumber">
            <button @click="goToPage(pageNumber)" :class="{ active: currentPage === pageNumber }">{{ pageNumber
            }}</button>
          </li>
        </ul>
      </div>

      <v-btn @click="viewMobile = false" width="100%" color="#400000" class="apply-asset-btn">Apply</v-btn>

      <!-- <div
        class="channel-blog"
        @click="redrictToBlog()"
        style="cursor: pointer"
      >
        <img src="../assets/Images/aipostgenerator/blog-icon.png" />
        <h4>Blog</h4>
      </div>
      <div class="channel-linkedin active" style="cursor: pointer">
        <img src="../assets/Images/aipostgenerator/linkedin-ico.png" />
        <h4>LinkedIn</h4>
      </div>
      <div
        class="channel-linkedin"
        @click="redrictToTwitter()"
        style="cursor: pointer"
      >
        <img src="../assets/Images/aipostgenerator/twitter-icon.png" />
        <h4>Twitter</h4>
      </div>
      <div
        class="channel-linkedin"
        style="cursor: pointer"
        @click="redrictToSummary()"
      >
        <v-icon color="#333" size="20px" class="mr-0">mdi-arrow-left</v-icon>
        <h4 class="ml-2">Back to Assets</h4>
      </div> -->
      <!-- <div class="channel-linkedin">
        <img src="../assets/Images/aipostgenerator/twitter-icon.png" />
        <h4>Twitter</h4>
      </div>
      <div class="channel-linkedin">
        <img src="../assets/Images/aipostgenerator/add-new-channel-ico.png" />
        <h4>Add New Channel</h4>
      </div> -->
    </div>
    <div class="post-generate-inner position-relative">
      <particles />
      <div class="file-upload-section-header px-3 px-md-0 px-md-8 position-relative d-md-none d-sm-none d-block">

        <!-- <div>
          <h4 v-if="contentType == 'paste'">
            Showing results based on your pasted content
          </h4>
          <h4 v-if="contentType == 'txt'">
            Showing results based on your uploaded document
          </h4>
        </div>
        <v-btn color="#eaebed" @click="redrictToSummary()"
          ><v-icon size="22px" color="#000" class="mr-2"
            >mdi-arrow-left-top</v-icon
          >
          Back to Preview/Transcript</v-btn
        > -->
        <!-- <h2>Chat with Private Enterprise Data</h2> -->
        <div class="answer-tab d-none">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" v-if="faqList && faqList.length > 0">
              <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                aria-selected="true">FAQ</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                aria-controls="profile" aria-selected="false">CHAT</a>
            </li>
          </ul>
        </div>
        <div class="mobile-panel-chat">
          <div class="leftpannel-mobile mr-2 mr-md-0">
            <div class="d-flex d-md-none align-items-center">
              <v-btn @click="OpenLeft()" text small><v-icon color="#333" size="25px">mdi-menu</v-icon>
                Assets</v-btn>
            </div>
          </div>
          <!-- <div class="post-switch">
            <v-select :items="channelList" label="Select Channel" v-model="selectedItem"
              @change="switchChannels(selectedItem)" outlined>
              <template v-slot:item="{ item }">
                <v-icon :style="{ color: item.iconColor }">{{ item.icon }}</v-icon>{{ item.text }}</template>
            </v-select>
          </div> -->
        </div>
      </div>

      <div class="tab-content h-100-tab-content d-block d-md-flex  justify-content-center align-items-center"
        id="myTabContent">
        <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div class="question-box-hld p-4">

            <div class="single-question" v-for="(item, index) in faqList" :key="index">
              <p>
                {{ item.Q }}
              </p>
              <div class="answer-sec">{{ item.A }}</div>
            </div>
          </div>
        </div>

        <div class="tab-pane active position-relative" id="profile" role="tabpanel" aria-labelledby="profile-tab">


          <VueBotUI :options="botOptions" :messages="messageData" :bot-typing="botTyping" :input-disable="inputDisable"
            :is-open="true" @msg-send="msgSend" @destroy="clearMsg" @init="getWelcomeMessage" />




        </div>

      </div>

    </div>

    <div class="email-gen-channels">

      <!-- <h3>Matching Resources</h3> -->
      <div class="blank-email-section" v-if="updateResList && updateResList.length == 0">
        <h4>Start a conversation to view resources.</h4>
      </div>
      <div class="email-list-wrap" v-else>
        <div class="single-email-box" v-for="resource in updateResList" :key="resource"
          @click="viewDetailedResource(resource.title)">
          <div class="email-line-1">
           
          </div>

          <div class="email-line-2">
            <h5 class="email-subject">{{ resource.title }}</h5>
          </div>
          <div class="email-line-3">
            <div class="tag">Score:<span>{{ resource.score }}</span></div>


          </div>
        </div>
      </div>
      <!-- <h4><img src="@/assets/Images/aipostgenerator/sources.svg"/> Sources</h4>
      <div class="external-links-area">
        <div class="single-external">
          <img src="@/assets/Images/aipostgenerator/careers.png"/>
          <p>Graphic Design Courses After 10th - Duration, Eligibility, Top Institutes</p>
        </div>
        <div class="single-external">
          <img src="@/assets/Images/aipostgenerator/aia-connect.png"/>
          <p>Graphic Design Courses After 10th - Duration, Eligibility, Top Institutes</p>
        </div>
        <div class="single-external">
          <img src="@/assets/Images/aipostgenerator/Company.png"/>
          <p>Career in Graphic Designing Course After 10th</p>
        </div>
      </div> -->
    </div>
    <v-dialog v-model="fileViewAssetDialog" width="auto" scrollable>
      <template>
        <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
          <v-card-title>
            <div class="view-asset-head">
              <h4>View Asset</h4>
              <v-btn fab text x-small class="close-btn" @click="fileViewAssetDialog = false"><v-icon color="#000000"
                  size="20px">mdi-close</v-icon></v-btn>
            </div>
          </v-card-title>
          <!-- <div class="" style="width: 100%"> -->
          <v-card-text>
            <div v-html="formattedViewContent"></div>
          </v-card-text>


          <!-- <v-btn @click="fileViewAssetDialog = false" fab small elevation="0"><v-icon size="24px"
                color="#5a646e">mdi-close</v-icon></v-btn> -->
          <!-- </div> -->

        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="fileEditNameDialog" width="auto">
      <template>
        <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column edit-aset-view">
          <div class="" style="width: 100%">
            <div class="view-asset-head">
              <h4>Edit Asset</h4>
              <v-btn fab text x-small class="close-btn" @click="fileEditNameDialog = false"><v-icon color="#000000"
                  size="20px">mdi-close</v-icon></v-btn>
            </div>
          </div>
          <div class="text-area-edit"><textarea v-model="editAssetContent" style="width:100%;height:100%"></textarea>

          </div>
          <v-btn dark color="#400000" class="common-button mt-2" @click="updateAsset()"
            :loading="updateAssetFlg">Update</v-btn>

        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="viewResourceDialog" width="auto" scrollable>
      <template>
        <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column">
          <v-card-title>
            <div class="view-asset-head">
              <h4>View Resource</h4>
              <v-btn fab text x-small class="close-btn" @click="viewResourceDialog = false"><v-icon color="#000000"
                  size="20px">mdi-close</v-icon></v-btn>
            </div>
          </v-card-title>
          <!-- <div class="" style="width: 100%"> -->
          <v-card-text>
            <div v-html="formattedViewResourceContent"></div>
          </v-card-text>


          <!-- <v-btn @click="fileViewAssetDialog = false" fab small elevation="0"><v-icon size="24px"
                color="#5a646e">mdi-close</v-icon></v-btn> -->
          <!-- </div> -->

        </v-card>
      </template>
    </v-dialog>


    <v-dialog v-model="filePropertyEditDialog" width="500" height="500px">

      <v-card class="edit-asset-temp1 p-4 d-flex justify-content-center align-items-center flex-column edit-aset-view">
        <div style="width: 100%">
          <div class="view-asset-head">
            <h4>Edit Asset Property</h4>
            <v-btn fab text x-small class="close-btn" @click="filePropertyEditDialog = false">
              <v-icon color="#000000" size="20px">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="text-area-edit-property mt-4 mb-3">
          <div class="form-group">
            <label for="name" class="mr-2" width="20px">Name</label>
            <input v-model="tempAssetEditProperty.name" id="name" class="form-control" />
          </div>
          <div class="form-group">
            <label for="annotation" class="mr-2" width="20px">Annotation</label>
            <textarea v-model="tempAssetEditProperty.annotation" id="annotation" class="form-control"
              style="width:300px;height:250px"></textarea>
          </div>
        </div>
        <v-btn dark color="#400000" class="common-button mt-2" @click="updateAssetProperty()"
          :loading="updateAssetPropertLoader">
          Update
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loginFromTrial" width="500" height="500px">
      <v-card class="rounded-xl">
        <div class="login-dialog" v-if="!success">
            <v-btn fab text small class="close-pop" @click="loginFromTrial = false"><v-icon color="#1a1d1c" size="25px">mdi-close</v-icon></v-btn>
            <div class="zoe-logo-area">
                <img src="@/assets/Images/aipostgenerator/zoe-svg.svg"/>
            </div>
            <h4>Sign in or Create an Account</h4>
            <p>Sign in or Create an Account Use Pro version AI Chat & Search. Upload files and save your threads</p>
            <input type="email" value="" id="" placeholder="johnson@example.com"/>
            <input type="submit" value="Continue With Email" @click="showSuccessLogin"/>
            <div class="or-divid-hld">
                <p class="or-divid">Or</p>
            </div>
            <p class="terms-text">By continuing, you agree to the updated <u>Terms of Sale</u>, <u>Terms of Service</u> and <u>Privacy Policy</u></p>
            <button class="continue-account"><img src="@/assets/Images/aipostgenerator/google.png" class="mr-2"/> Continue with Google</button>
            <button class="continue-account"><img src="@/assets/Images/aipostgenerator/mcrs.svg" class="mr-2"/> Continue with Microsoft</button>
        </div>
        <div class="success-login" v-if="success">
          <v-btn fab text small class="close-pop" @click="loginFromTrial = false"><v-icon color="#1a1d1c" size="25px">mdi-close</v-icon></v-btn>
          <img src="@/assets/Images/aipostgenerator/success.svg"/>
          <h3>Awesome!</h3>
          <p>Your account has been created successfully.</p>
          <v-btn @click="$router.push('/ingestData')" large color="#4285F4" width="100px" height="55px">Go</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <div class="floating-button" @click="resetCurrentChat()">
      <button>Reset Chat</button>
    </div>

  </div>
</template>
<script>
import Vue from "vue";
import VueBus from "vue-bus";
import axios from "@/axios.js";
import APIs from "@/components/APIInit";
// import BotIcon from "../assets/icons/bot.png";
import { VueBotUI } from "../vue-bot-ui";
import particles from '../components/particles'

Vue.use(VueBus);
export default {
  props: {},
  components: {
    // BotIcon,
    VueBotUI,
    particles
  },
  data() {
    return {
      success: false,
      loginFromTrial: false,
      currentPage: 1,
      itemsPerPage: 100,
      totalPages: 0,
      viewResourceDialog: false,
      updateResList: [],
      resourceList: [],
      updateAssetPropertLoader: false,
      filePropertyEditDialog: false,
      tempAssetEditProperty: {},
      checkbox: false,
      selectAllCheckBox: true,
      editIconLoading: false,
      viewIconLoading: false,
      updateAssetFlg: false,
      fileViewAssetDialog: false,
      fileEditAssetDialog: false,
      viewAssetContent: '',
      editAssetContent: '',
      myBotList: [],
      viewMobile: true,
      inputDisable: false,
      botTyping: false,
      botOptions: {
        botAvatarImg:
          "https://zoebot.ai/sites/all/themes/custom/zoeweb/images/zoe-logo.svg",
        boardContentBg: "#f4f4f4",
        msgBubbleBgBot: "#fff",
        inputPlaceholder: "Type your message here",
        inputDisableBg: "#fff",
        inputDisablePlaceholder: "Hit the buttons above to respond",
        botTitle: "Powered by Zoe 2.0",
      },
      messageData: [],
      selectedIndex: null,
      assetErrorFlg: false,
      tempFileName: null,
      fileEditNameDialog: false,
      tempAssetEditId: null,
      randomText: "",
      makeEdit: true,
      selectedItem: "Chat with Data",
      radios: null,
      channelList: [
        {
          text: "Blog",
          icon: "mdi-post-outline",
          iconColor: "#1eb375",
          isOpen: true,
        },
        {
          text: "LinkedIn",
          icon: "mdi-linkedin",
          iconColor: "#007ebd",
          isOpen: true,
        },
        {
          text: "Twitter",
          icon: "mdi-twitter",
          iconColor: "#00a9ef",
          isOpen: true,
        },
        {
          text: "Chat with Data",
          icon: "mdi-message-outline",
          iconColor: "#00a9ef",
          isOpen: true,
        },
      ],
      searchTerm: "",
      productList1: [{ id: 1, name: "Gecko" }],
      linkedInPost: [],
      linkedInMessage: null,
      linkedInimage: null,
      EditPostBox: false,
      LinkedinPostBox: true,
      fullLinkedinImage: false,
      isLinkedInLoading: false,
      tempLinkedInMsg: null,
      tempLinkedInimage: null,
      tempImgChangeLoader: false,
      companyName: null,
      tempImg: null,
      showMorePostLoader: true,
      contentType: null,
      contentName: null,
      selectedProduct: null,
      selectedProduct1: 1,
      productName: null,
      icon: "mdi-bookmark-outline", // Initial icon
      iconColor: "#333",
      EmailId: null,
      userAssetList: [],
      openLeftPanel: false,
      faqList: [],
      assetList: [],
      selectedBot: null,
      activeIndex: 0,
      editedAssetId: 0,
      sessionID: null,
      viwedResource: null
    };
  },
  computed: {
    filteredResources() {
      console.log("res======", this.resourceList);

      return this.resourceList;
    },
    formattedViewContent() {
      return this.viewAssetContent.replace(/\n/g, '<br/>');
    },
    formattedViewResourceContent() {
      return this.viwedResource ? this.viwedResource.replace(/\n/g, '<br/>') : '';
    },
    filteredAssetList() {
      var flg = false;
      this.assetList.map((element, index) => {
        if (element.IsChecked) {
          console.log("element====");
          flg = true;
        } else {
          console.log("element==2==");
          flg = false;

        }
      });
      // if (!flg) {
      //     console.log("flg====");
      //     if(this.assetList && this.assetList.length > 0){
      //       this.assetList[0].IsChecked = true;
      //     }
      //   }

      var searchTxt = this.searchTerm ? this.searchTerm.toLowerCase() : ''
      return this.assetList.filter((asset) =>
        asset.assetName.toLowerCase().includes(searchTxt)
      );
    },
  },
  created() {
    localStorage.removeItem("linkedInPostList");
    this.$bus.on("blogData", (data) => {
      console.log("blogData==in bus==", data);
      this.linkedInMessage = data.message;
      this.linkedInimage = data.image;
    });
    this.$bus.on("linkedinData", (data) => {
      console.log("linkedinData==in bus==", data);
    });

    this.$bus.on('openDialogEvent', this.openDialog);
  },
  mounted() {
    if (screen.width >= 320 && screen.width <= 767) {
      this.viewMobile = false;
    } else if (screen.width >= 768 && screen.width <= 1200) {
      this.viewMobile = true;
    }
    if (this.$route.query.param1 == "r") {
      this.$router.push({
        path: "/chatwithdata",
        query: {},
      });
      location.reload();
    }
    if (localStorage.getItem("latestAssetId")) {
      this.activeIndex = localStorage.getItem("latestAssetId");
    }
    if (localStorage.getItem("latestBotId")) {
      this.selectedBot = parseInt(localStorage.getItem("latestBotId"));
    }
    // this.sessionID = this.getSessionID()

    // // If no session ID is found, generate a new one and store it in a cookie
    // if (!this.sessionID) {
    //   this.sessionID = this.generateSessionID()
    //   this.setSessionCookie(this.sessionID)
    // }
    //this.getFAQ();

    this.getAssetList();
    this.getMyBots();
  },
  methods: {
    showSuccessLogin() {
      this.success = true;
    },
    openLoginPop(){
      this.loginFromTrial = true;
    },
    openDialog() {
      this.loginFromTrial = true;
    },
    goToPage(pageNumber) {
      console.log("page=====", pageNumber);

      this.currentPage = pageNumber;
      this.getAssetList();
    },
    viewDetailedResource(resource) {
      console.log("view clieked=====");

      this.viwedResource = resource;
      this.viewResourceDialog = true;

    },
    async updateAssetProperty() {
      this.updateAssetPropertLoader = true;
      try {
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          "assetId": this.tempAssetEditProperty.assetId,
          "name": this.tempAssetEditProperty.name,
          "annotation": this.tempAssetEditProperty.annotation
        };
        const response = await axios.post(APIs.updateAssetPropertiesAPI, data, { headers });
        console.log("response= update====", response);

        if (response.data.status == 0) {
          this.updateAssetPropertLoader = false;
          this.filePropertyEditDialog = false;
          this.tempAssetEditProperty = {};
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async getWelcomeMessage() {
      this.botTyping = true;
      try {
        const headers = {
          'accept': 'application/json',
        };
        const data = {
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };
        const response = await axios.post(APIs.getWelcomeMessageAPI, data, { headers });
        console.log("response=====", response);
        if (response.data.status == 0) {
          setTimeout(() => {
            this.botTyping = false;
            this.messageData.push({
              agent: "bot",
              type: "text",
              text: response.data.data.msg,
            });
          }, 1000);
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async getSpecificClipById(id) {
      try {
        const apiUrl = 'https://api.d-id.com/talks/' + id;
        const headers = {
          'accept': 'application/json',
          'authorization': 'Basic YzJGNVlXNHlMbkp2ZVVCM1pXSnpjR2xrWlhKekxtTnZiUTp5cFgwZmlIRVdDbm9Hd0pxbmZCcUc='
        };
        const response = await axios.get(apiUrl, { headers });
        console.log("response=====", response);

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async showVideoMessage(value) {
      var tempData = JSON.stringify({ "script": { "type": "text", "subtitles": false, "provider": { "type": "microsoft", "voice_id": "en-US-JennyNeural" }, "ssml": "false", "input": value }, "config": { "fluent": "false", "pad_audio": "0.0" }, "source_url": "https://i.pinimg.com/474x/e4/c5/9f/e4c59fdbb41ccd0f87dc0be871d91d98.jpg" });
      try {
        const apiUrl = 'https://api.d-id.com/talks';
        const headers = {
          'accept': 'application/json',
          'authorization': 'Basic YzJGNVlXNHlMbkp2ZVVCM1pXSnpjR2xrWlhKekxtTnZiUTp5cFgwZmlIRVdDbm9Hd0pxbmZCcUc=',
          'content-type': 'application/json'
        };

        const response = await axios.post(apiUrl, tempData, { headers });
        console.log("response======", response);
        if (response.status == 201) {
          this.getSpecificClipById(response.data.id);
        }


      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    generateSessionID() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const sessionIdLength = 20 // Adjust the length as needed
      let sessionId = ''

      for (let i = 0; i < sessionIdLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        sessionId += characters.charAt(randomIndex)
      }

      return sessionId
    },
    setSessionCookie(sessionId) {
      document.cookie = `sessionID=${sessionId}; path=/`
    },
    getSessionID() {
      const cookies = document.cookie.split('; ')
      for (const cookie of cookies) {
        const [name, value] = cookie.split('=')
        if (name === 'sessionID') {
          return value
        }
      }
      return null
    },
    async resetCurrentChat() {
      try {
        const apiUrl = APIs.clearContextAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };
        const response = await axios.post(apiUrl, data, { headers });
        console.log("response====", response)

        if (response.data.status == 0) {
          this.clearMsg();
          this.getWelcomeMessage();
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    selectAllAsset() {
      if (this.selectAllCheckBox) {
        this.assetList.map((element, index) => {
          element.IsChecked = true;
        });
      } else {
        const lastItemIndex = this.assetList.length - 1;
        this.assetList.map((element, index) => {
          if (index === lastItemIndex) {
            element.IsChecked = true;
          } else {
            element.IsChecked = false; // Uncheck all other items
          }
        });

        this.assetList.sort((a, b) => a.assetId - b.assetId)
      }

    },
    checkIfCheckBoxExist() {

      var flg = false;
      this.assetList.map((element, index) => {
        if (element.IsChecked) {
          console.log("element====");
          flg = true;
        } else {
          flg = false;

        }
        if (flg) {
          this.assetList[index].viewIconLoading = true;
        }

      });
    },
    async updateAsset() {
      this.updateAssetFlg = true;


      try {
        const apiUrl = APIs.editAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(this.editedAssetId),
          "textContent": this.editAssetContent,
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response=======", response)
        if (response.data.status == 0) {
          this.updateAssetFlg = false;
          this.fileEditNameDialog = false;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    changeSelectedBot(botId) {
      localStorage.setItem("latestBotId", botId);
      this.getMyBots()
      this.getAssetList();
      this.clearMsg();
      this.getWelcomeMessage();
    },
    async getMyBots() {
      //this.myBotList = [];
      try {
        const apiUrl = APIs.getMyBotsAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const response = await axios.get(apiUrl, { headers });
        if (response.data.status == 0) {
          this.myBotList = response.data.bots;
          this.myBotList.sort((a, b) => {
            const dateA = new Date(a.createdOn);
            const dateB = new Date(b.createdOn);

            // Compare in descending order
            return dateB - dateA;
          });
          // if(this.myBotList &&  this.myBotList.length == 1){
          //   localStorage.setItem("latestBotId", this.myBotList[0].botId);
          //   this.$router.push("/chatwithdata")
          // }

        }
      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async fetchAssetContent(assetId, index) {
      this.assetList[index].viewIconLoading = true;

      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(assetId),
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response===view====", response)
        if (response.data.status == 0) {
          if (screen.width >= 320 && screen.width <= 767) {
            this.viewMobile = false
          }
          this.assetList[index].viewIconLoading = false;

          this.viewAssetContent = response.data.data.asset.cleanText;
          this.fileViewAssetDialog = true;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    getAssetList() {
      this.assetList = [];
      //+ '&size=100' + '&page=' + this.currentPage
      axios
        .get(APIs.getAssetAPI + '?botid=' + parseInt(localStorage.getItem("latestBotId")), {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          }
        })
        .then((response) => {
          console.log("response==asset====", response.data);
          if (response.data && response.data.status == 0) {
            response.data.assets.map((element, index) => {
              element.viewIconLoading = false;
              element.editIconLoading = false;
              element.editPropertiesIconLoading = false;
              if (index == 0) {
                element.IsChecked = true;
              }
              this.assetList.push(element)
            });
            const lastItemIndex = this.assetList.length - 1;
            this.assetList.map((element, index) => {
              if (index === lastItemIndex) {
                element.IsChecked = true;
              } else {
                element.IsChecked = true; // Uncheck all other items
              }
            });

            this.assetList.sort((a, b) => a.assetId - b.assetId)
            this.totalPages = response.data.totalPages;

          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    OpenLeft() {
      this.viewMobile = true;
    },
    getFAQ() {
      var self = this;
      var tempArr = [];
      tempArr.push(localStorage.getItem("latestAssetId"));

      var data = { assetIds: tempArr };

      // console.log(JSON.stringify(data))
      var config = {
        method: "post",
        url: APIs.faqAPI,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        },
        data: JSON.stringify(data),
      };
      axios(config).then(function (response) {
        console.log("faq====", response);

        if (response.data.status == 0) {
          self.faqList = response.data.data.FAQ;
        }
      });
    },

    msgSend(value) {
      // Push the user's message to board
      this.messageData.push({
        agent: "user",
        type: "text",
        text: value.text,
      });
      if (value.text.includes("Can you tell me about the factories of Vikram Solar") || value.text.includes("can you tell me about the factories of Vikram Solar") || value.text.includes("factories of Vikram Solar")) {
        this.botTyping = true;
        setTimeout(() => {
          this.botTyping = false;
          this.messageData.push({
            agent: "bot",
            type: "text",
            text: "<p>Vikram Solar has two factories located in India. One is located at Falta SEZ in Kolkata, West Bengal, and the other is located at Oragadam in Chennai, Tamil Nadu. Both of these factories are strategically located with access to ports, rail, and roads, facilitating both domestic and international operations.</p><a id='video1'><img alt='play video' src='https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/play_icon.svg?alt=media'/></a>",
            disableInput: false,
          });
        }, 1000);
      } else if (value.text.includes("Where are factories of VS") || value.text.includes("where are factories of VS") || value.text.includes("factories of VS")) {
        this.botTyping = true;
        setTimeout(() => {
          this.botTyping = false;
          this.messageData.push({
            agent: "bot",
            type: "text",
            text: "<p>Vikram Solar has two factories located in India. One is located at Falta SEZ in Kolkata, West Bengal, and the other is located at Oragadam in Chennai, Tamil Nadu. Both of these factories are strategically located with access to ports, rail, and roads, facilitating both domestic and international operations.</p><a id='video2'><img alt='play video' src='https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/play_icon.svg?alt=media'/></a>",
            disableInput: false,
          });
        }, 1000);
      }
      else if (value.text.includes("Where are your factories located") || value.text.includes("where are your factories located") || value.text.includes("factories located")) {
        this.botTyping = true;
        setTimeout(() => {
          this.botTyping = false;
          this.messageData.push({
            agent: "bot",
            type: "text",
            text: "<p>Vikram Solar has two factories located in India. One is located at Falta SEZ in Kolkata, West Bengal, and the other is located at Oragadam in Chennai, Tamil Nadu. Both of these factories are strategically located with access to ports, rail, and roads, facilitating both domestic and international operations.</p><a id='video3'><img alt='play video' src='https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/play_icon.svg?alt=media'/></a>",
            disableInput: false,
          });
        }, 1000);
      }
      else {
        this.getResponse(value);
      }



    },
    clearMsg() {
      this.messageData = [];
    },
    getResponse(value) {
      var self = this;
      // Loading
      this.botTyping = true;
      var tempArr = [];
      tempArr.push(localStorage.getItem("latestAssetId"));
      console.log("check===", this.assetList);
      var selectedAssets = [];
      this.assetList.map((element) => {
        if (element.IsChecked) {
          console.log("check ed===", element.assetId);
          selectedAssets.push(parseInt(element.assetId))
        }
      });
      console.log("selectedAssets===", selectedAssets);

      var data = { assetIds: selectedAssets, userQuery: value.text, botId: parseInt(localStorage.getItem("latestBotId")) };

      // console.log(JSON.stringify(data))
      var config = {
        method: "post",
        url: APIs.zoeChat,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        },
        data: JSON.stringify(data),
      };
      axios(config).then(function (response) {

        console.log("chat ====", response.data)
        if (response.data.status == 0) {
          if (response.data.data.vectors) {
            // self.resourceList = response.data.data.vectors;
            self.getVectorChunck(response.data.data.vectors);
          }
          self.messageData.push({
            agent: "bot",
            type: "text",
            text: response.data.data.reply,
            disableInput: false,
          });
          self.botTyping = false;
          //self.showVideoMessage(response.data.data.reply)
        }
      });
    },
    async getVectorChunck(vectors) {
      console.log("vectors=====", vectors);
      if (vectors && vectors.length > 0) {

        const ids = vectors.map(item => item.id);
        const scores = vectors.map(item => item.score);
        console.log("scores=====", scores);
        let roundedNumbers;
        if (scores && scores.length > 0) {
          roundedNumbers = scores.map(number => number.toFixed(5));

        }

        console.log("roundedNumbers=====", roundedNumbers);


        try {
          const apiUrl = APIs.getVectorChunckAPI;
          const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          };
          const data = {
            "vectorIds": ids,
          };

          const response = await axios.post(apiUrl, data, { headers });

          console.log("vector response=======", response.data.data)

          if (response.data.status == 0) {
            this.resourcelist = response.data.data;
          }
          let keyValuePairs = roundedNumbers.map((score, index) => {
            return {
              score: score,
              title: this.resourcelist[index]
            };
          });
          this.updateResList = keyValuePairs;
          console.log("vector resourcelist=======", this.resourcelist)

        } catch (error) {
          console.error('Error fetching asset content:', error);
        }
      }

    },
    getAssetListByProduct(productId) {
      console.log("pl===", productId);
      const apiUrl = APIs.getAssetAPI;
      const email = localStorage.getItem("emailId");
      var productid = productId;

      axios
        .get(apiUrl, {
          params: {
            email: email,
            productid: productid,
          },
        })
        .then((response) => {
          console.log("asset list======", response.data);
          this.userAssetList = [];

          if (response.data.length > 0) {
            if (this.selectedIndex) {
              this.activeIndex = this.selectedIndex;
            } else {
              this.activeIndex = response.data.length - 2;
            }
            // this.activeIndex = response.data.length - 2;
            this.userAssetList = response.data;
            this.userAssetList.sort((a, b) => b.id - a.id);

            this.userAssetList.map((element) => {
              if (localStorage.getItem("multiAssetIds")) {
                if (
                  localStorage.getItem("multiAssetIds").includes(element.id)
                ) {
                  element.IsChecked = true;
                } else {
                  element.IsChecked = false;
                }
              } else {
                element.IsChecked = false;
              }
            });
            // this.activeIndex = 0;
            //this.generatedMessage = response.data[0].summarygenerated;
            this.fileName = response.data[0].filename;
            this.fileSize = response.data[0].fileSize;
            localStorage.setItem("selectedFileName", this.fileName);
            localStorage.setItem("selectedFileType", response.data[0].filetype);
          } else {
            // this.openAssetPopup();
            // this.generatedMessage = null;
          }
        })
        .catch((error) => {
          console.error(error);

          // Handle any errors that occurred during the API call
        });
    },
    async editAssetProperties(assetId, index) {

      this.assetList[index].editPropertiesIconLoading = true;
      try {
        const apiUrl = APIs.getAssetPropertiesAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(assetId),
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("asset edit response===view====", response)

        if (response.data.status == 0) {
          this.tempAssetEditProperty.assetId = assetId;
          this.tempAssetEditProperty.annotation = response.data.data.annotation;
          this.tempAssetEditProperty.name = response.data.data.name;
        }
        this.assetList[index].editPropertiesIconLoading = false;
        this.filePropertyEditDialog = true;

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
    async editAssets(assetId, index) {
      this.editIconLoading = true;
      this.assetList[index].editIconLoading = true;

      try {
        const apiUrl = APIs.viewAssetAPI;
        const headers = {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const data = {
          assetId: parseInt(assetId),
          "botId": parseInt(localStorage.getItem("latestBotId")),
        };

        const response = await axios.post(apiUrl, data, { headers });

        console.log("response===view====", response)
        if (response.data.status == 0) {
          if (screen.width >= 320 && screen.width <= 767) {
            this.viewMobile = false
          }
          this.editIconLoading = false;
          this.assetList[index].editIconLoading = false;
          this.assetList[index].editPropertiesIconLoading = false;
          this.editedAssetId = assetId;
          this.editAssetContent = response.data.data.asset.cleanText;
          this.fileEditNameDialog = true;
        }

      } catch (error) {
        console.error('Error fetching asset content:', error);
      }
    },
  },
  watch: {
  },
};
</script>
<style>
.floating-button {
    position: fixed;
    right: 20px !important;
    bottom: 7px;
    background: #7e0d10;
    padding: 12px 20px;
    border-radius: 50px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
}

.asset-list .v-messages {
  display: none;
}

.asset-list .select-all {
  margin-left: 24px;
}

.post-switch .v-text-field__details {
  display: none;
}

.post-switch .v-input__slot {
  margin: 0;
}

.edit-aset-view {
  width: 800px;
  height: 550px;
}

.text-area-edit {
  height: calc(100% - 32px);
  width: 100%;
}

.text-area-edit textarea {
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  padding: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.18 rem;
  line-height: 2.2 rem;
  border-radius: 15px;
  resize: none;
}

.text-area-edit textarea:active,
.text-area-edit textarea:focus {
  border-color: #7e0d10;
  outline: none;
}

.text-area-edit-property {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.form-control {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.text-area-edit-property div {
  margin-bottom: 15px;
}

.text-area-edit-property label {
  font-size: 16px;
  margin-bottom: 5px;
}

.text-area-edit-property input {
  width: 300px;
  /* Adjust the width as needed */
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.pagination li {
  margin: 0 5px;
}

.pagination button {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:hover {
  background-color: #45a049;
}

.pagination button.active {
  background-color: #45a049;
}
</style>
